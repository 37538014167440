import {
  Select,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Stack,
  useColorModeValue,
  FormErrorMessage,
  Text,
} from '@chakra-ui/react';
import * as React from 'react';
import dayjs from 'dayjs';
import { FormikProps } from 'formik';

const provinces = [
  'Buenos Aires',
  'CABA',
  'Catamarca',
  'Chaco',
  'Chubut',
  'Córdoba',
  'Corrientes',
  'Entre Ríos',
  'Formosa',
  'Jujuy',
  'La Pampa',
  'La Rioja',
  'Mendoza',
  'Misiones',
  'Neuquén',
  'Río Negro',
  'Salta',
  'San Juan',
  'San Luis',
  'Santa Cruz',
  'Santa Fe',
  'Santiago del Estero',
  'Tierra del Fuego',
  'Tucumán',
];
interface ShippingInformationFormValues {
  email: string;
  name: string;
  phone: string;
  street: string;
  street_number: string;
  depto: string;
  city: string;
  zip: string;
  state: string;
  dni: string;
}

export const ShippingInformation = (
  props: FormikProps<ShippingInformationFormValues> & any
) => {
  const { touched, errors } = props;
  const currentDay = dayjs();
  const todayPlus3 = currentDay.add(3, 'day').format('D/M');
  const todayPlus10 = currentDay.add(10, 'day').format('D/M');

  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <FormControl
        id="email"
        isRequired
        isInvalid={!!errors.email && touched.email}
      >
        <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
          Email
        </FormLabel>
        <Input
          name="email"
          placeholder=""
          variant="flushed"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          value={props.values.email}
          focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
        />
        <FormErrorMessage>{errors.email}</FormErrorMessage>
      </FormControl>

      <Stack spacing={{ base: '6', md: '8' }}>
        <Heading size="sm">Entrega</Heading>

        <FormControl id="shipping_method">
          <FormLabel fontWeight="bold" fontSize="md">Andreani - Envío a domicilio</FormLabel>
          <Text noOfLines={1} color="gray.500">
            Llega entre el {todayPlus3} y el {todayPlus10}
          </Text>
        </FormControl>
      </Stack>
      <Stack spacing={{ base: '6', md: '8' }}>
        <Heading size="sm">Datos del Destinatario</Heading>
        <FormControl
          id="name"
          isRequired
          isInvalid={!!errors.name && touched.name}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Nombre y Apellido
          </FormLabel>
          <Input
            name="name"
            placeholder=""
            variant="flushed"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.name}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.name}</FormErrorMessage>
        </FormControl>
        <FormControl
          isRequired
          id="phone"
          isInvalid={!!errors.phone && touched.phone}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Telefono
          </FormLabel>
          <Input
            name="phone"
            placeholder=""
            variant="flushed"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.phone}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.phone}</FormErrorMessage>
        </FormControl>
        <Heading size="sm">Dirección del Destinatario</Heading>
        <FormControl
          isRequired
          id="street"
          isInvalid={!!errors.street && touched.street}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Dirección
          </FormLabel>
          <Input
            name="street"
            variant="flushed"
            placeholder=""
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.street}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.street}</FormErrorMessage>
        </FormControl>
        <HStack spacing="6">
          <FormControl
            isRequired
            id="street_number"
            isInvalid={!!errors.street_number && touched.street_number}
          >
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              Numero
            </FormLabel>
            <Input
              name="street_number"
              variant="flushed"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.street_number}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.street_number}</FormErrorMessage>
          </FormControl>
          <FormControl id="depto" isInvalid={!!errors.depto && touched.depto}>
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              Departamento
            </FormLabel>
            <Input
              name="depto"
              variant="flushed"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.depto}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.depto}</FormErrorMessage>
          </FormControl>
        </HStack>
        <HStack spacing="6">
          <FormControl
            id="city"
            isRequired
            isInvalid={!!errors.city && touched.city}
          >
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              Ciudad
            </FormLabel>
            <Input
              name="city"
              variant="flushed"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.city}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.city}</FormErrorMessage>
          </FormControl>
          <FormControl
            isRequired
            id="zip"
            isInvalid={!!errors.zip && touched.zip}
          >
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              Código Postal
            </FormLabel>
            <Input
              name="zip"
              variant="flushed"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.zip}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.zip}</FormErrorMessage>
          </FormControl>
        </HStack>
        <FormControl
          isRequired
          id="state"
          isInvalid={!!errors.state && touched.state}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Provincia
          </FormLabel>
          <Select
            name="state"
            variant="flushed"
            placeholder="Selecciona una provincia"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.state}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          >
            {provinces.map((province) => (
              <option key={province} value={province}>
                {province}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.state}</FormErrorMessage>
        </FormControl>
        <Heading size="sm">Datos de Facturación</Heading>
        <FormControl
          isRequired
          id="dni"
          isInvalid={!!errors.dni && touched.dni}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            DNI o CUIL
          </FormLabel>
          <Input
            name="dni"
            variant="flushed"
            placeholder=""
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.dni}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.dni}</FormErrorMessage>
        </FormControl>
      </Stack>
    </Stack>
  );
};
