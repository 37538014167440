import { ChangeEvent, useState } from 'react';
import { FieldArray, Form, useFormikContext } from 'formik';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from '@chakra-ui/accordion';
import { Button, IconButton } from '@chakra-ui/button';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@chakra-ui/form-control';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { Icon } from '@chakra-ui/icon';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { TbCornerDownLeft, TbTrash } from 'react-icons/tb';

import { IProductDataSchema } from './validationSchema';
import {
  ATTRIBUTES_DISPLAY,
  DEFAULT_COLORS,
  DEFAULT_SIZES,
} from '../constants';
import { removeAttributeValue } from '../../../utils/products';

interface AttributeDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

const AttributeDrawer = ({ isOpen, onClose }: AttributeDrawerProps) => {
  // TODO: add more specific type for the options state
  const [options, setOptions] = useState<Record<string, string[]>>({
    color: DEFAULT_COLORS,
    talle: DEFAULT_SIZES,
  });
  const [newValue, setNewValue] = useState('');
  const [showNewInput, setShowNewInput] = useState(false);
  const [error, setError] = useState('');

  const {
    handleChange,
    values: { attributes },
    setFieldValue,
  } = useFormikContext<IProductDataSchema>();

  const handleNewValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setNewValue(newValue);
    setError('');
  };

  const handleAddNewValueClick = (attributeName: string) => {
    if (!options[attributeName]?.includes(newValue)) {
      setOptions({
        ...options,
        [attributeName]: [...options[attributeName], newValue],
      });
      setNewValue('');
      setError('');
      setShowNewInput(false);
    } else {
      setError('Este valor ya existe');
    }
  };

  const handleNewValueInputKeyUp = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    const { key, target } = event;
    if (key === 'Enter') {
      const inputTarget = target as HTMLInputElement;
      if (!options[inputTarget.name]?.includes(newValue)) {
        setOptions({
          ...options,
          [inputTarget.name]: [...options[inputTarget.name], newValue],
        });
        setNewValue('');
        setError('');
        setShowNewInput(false);
      } else {
        setError('Este valor ya existe');
      }
    } else if (key === 'Escape') {
      setNewValue('');
      setShowNewInput(false);
      setError('');
    }
  };

  const handleNewValueInputBlur = () => {
    setNewValue('');
    setShowNewInput(false);
  };

  const handleAttributeDelete = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { id } = event.currentTarget;
    const parts = id.split('-');
    const attribute = parts[0];
    const value = parts[1];
    const newOptions = {
      ...options,
      [attribute]: options[attribute].filter((v) => v !== value),
    };

    setFieldValue(
      'attributes',
      removeAttributeValue(attributes, {
        attributeName: attribute,
        attributeValue: value,
      })
    );
    setOptions(newOptions);
  };

  const handleAttributeChange = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const { id, checked } = event.target;
    const attribute = attributes.find((attribute) => attribute.name === id);

    if (!attribute) {
      await setFieldValue('attributes', [
        ...attributes,
        { name: id, values: [] },
      ]);
    }

    handleChange(event);

    // Remove the attribute if the user unchecks the only value
    if (checked === false && attribute?.values.length === 1) {
      setFieldValue('attributes', [...attributes.filter((a) => a.name !== id)]);
    }
  };

  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent overflowY="scroll">
        <DrawerCloseButton />
        <DrawerHeader>Agregar variantes</DrawerHeader>
        <Flex direction="column" height="full">
          <DrawerBody>
            <Form>
              <FieldArray name="attributes">
                {() => (
                  <Accordion allowToggle>
                    {Object.keys(options).map((attributeName, index) => {
                      let attributeIndex = attributes.findIndex(
                        (attribute) => attribute.name === attributeName
                      );
                      if (attributeIndex === -1) {
                        attributeIndex = attributes.length;
                      }
                      const values = attributes
                        ? attributes[attributeIndex]?.values
                        : [];
                      const { label, title, message } =
                        ATTRIBUTES_DISPLAY[attributeName];
                      return (
                        <AccordionItem key={attributeName}>
                          <AccordionButton>
                            <Box as="span" flex="1" textAlign="left">
                              {label}
                            </Box>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel>
                            <Box py={3}>
                              <Heading as="h3" fontSize="md" fontWeight="light">
                                {title}
                              </Heading>
                              <Text as="small" color="ui.copy" size="sm" pb={3}>
                                {message}
                              </Text>

                              {showNewInput ? (
                                <FormControl
                                  isInvalid={!!error}
                                  onBlur={handleNewValueInputBlur}
                                >
                                  <Flex alignItems="center" gap={2}>
                                    <Input
                                      type="text"
                                      name={attributeName}
                                      size="sm"
                                      value={newValue}
                                      onChange={handleNewValueChange}
                                      onKeyUp={handleNewValueInputKeyUp}
                                    />
                                    <IconButton
                                      icon={<Icon as={TbCornerDownLeft} />}
                                      aria-label="Guardar"
                                      variant="icon"
                                      size="sm"
                                      onClick={() => {
                                        return handleAddNewValueClick(
                                          attributeName
                                        );
                                      }}
                                    />
                                  </Flex>
                                  <FormErrorMessage>{error}</FormErrorMessage>
                                </FormControl>
                              ) : (
                                <Text
                                  color="ui.link"
                                  cursor="pointer"
                                  onClick={() => setShowNewInput(true)}
                                >
                                  Agregar {attributeName} personalizado
                                </Text>
                              )}
                            </Box>

                            <FormControl as="fieldset">
                              <FormLabel as="legend">{label} basicos</FormLabel>
                              <CheckboxGroup defaultValue={values as string[]}>
                                <Flex direction="column" gap={4} pb={2}>
                                  {options[attributeName].map((option) => (
                                    <Flex
                                      key={option}
                                      alignItems="center"
                                      justify="space-between"
                                    >
                                      <Checkbox
                                        value={option}
                                        name={`attributes.${attributeIndex}.values`}
                                        onChange={handleAttributeChange}
                                        id={attributeName}
                                      >
                                        {option}
                                      </Checkbox>
                                      <IconButton
                                        aria-label="Borrar atributo"
                                        id={`${attributeName}-${option}`}
                                        icon={
                                          <Icon
                                            as={TbTrash}
                                            id={`${attributeName}-${option}`}
                                            boxSize={4}
                                          />
                                        }
                                        variant="icon"
                                        onClick={handleAttributeDelete}
                                      />
                                    </Flex>
                                  ))}
                                </Flex>
                              </CheckboxGroup>
                            </FormControl>
                          </AccordionPanel>
                        </AccordionItem>
                      );
                    })}
                  </Accordion>
                )}
              </FieldArray>
            </Form>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="primary" onClick={onClose}>
              Agregar
            </Button>
          </DrawerFooter>
        </Flex>
      </DrawerContent>
      );
    </Drawer>
  );
};

export default AttributeDrawer;
