import * as yup from 'yup';

const companyDataSchema = yup.object().shape({
  name: yup.string().trim().required('Debe ingresar un nombre'),
  description: yup.string().trim().required('Debe ingresar una descripción'),
  businessName: yup.string().trim().required('Debe ingresar una razón social'),
  image: yup.string().required(),
  logo: yup.string().required(),
  cuit: yup
    .string()
    .trim()
    .matches(/^(\d{2}-)?\d{8}-\d$/, 'Debe ingresar un número de CUIT válido'),
  vatCondition: yup
    .string()
    .trim()
    .required('Debe seleccionar una condición de IVA'),
  address: yup.object().shape({
    street: yup.string().trim().required('Debe ingresar una calle'),
    number: yup.string().trim().required('Debe ingresar un número'),
    city: yup.string().trim().required('Debe ingresar una ciudad'),
    zipCode: yup.string().trim().required('Debe ingresar un código postal'),
    country: yup.string().trim().required('Debe ingresar un país'),
    province: yup.string().trim().required('Debe ingresar una provincia'),
    isTaxAddress: yup
      .boolean()
      .default(false)
      .required('Debe especificar si es una dirección fiscal'),
  }),
  allowPickups: yup.boolean().default(false),
});

interface ICompanyDataSchema extends yup.InferType<typeof companyDataSchema> {}

export { companyDataSchema, type ICompanyDataSchema };
