import {
  useMutation,
  UseMutationResult,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { Order } from '../types/order';
import { ClientParams, client } from '../utils/api-client';
import { snakeKeysToCamel } from '../utils/transformations';

const COMPANY_ORDER_URL = 'company/:companyId/orders/:orderId/';

function useOrder(
  companyId: number,
  orderId: number,
  options?: ClientParams
): UseQueryResult<Order> {
  return useQuery({
    queryKey: ['order', orderId],
    queryFn: () =>
      client(
        COMPANY_ORDER_URL.replace(':companyId', String(companyId)).replace(
          ':orderId',
          String(orderId)
        ),
        {
          ...options,
        }
      )
        .then((data) => {
          const result = snakeKeysToCamel(data);
          return result;
        })
        .catch((error) => {
          console.error('Error: ', error);
        }),
  });
}

const COMPANY_ORDERS_URL = 'company/:companyId/orders/';

function useOrders(
  companyId: number,
  options?: ClientParams
): UseQueryResult<Order[]> {
  return useQuery({
    queryKey: ['orders'],
    queryFn: () =>
      client(COMPANY_ORDERS_URL.replace(':companyId', String(companyId)), {
        ...options,
      })
        .then((data) => {
          const result = data.results.map(snakeKeysToCamel);
          return result;
        })
        .catch((error) => {
          console.error('Error: ', error);
        }),
  });
}

const SHOW_ORDERS_URL = 'shows/:showId/orders/';

function useShowOrders(showId: number, options?: ClientParams) {
  return useQuery({
    queryKey: ['orders', showId],
    queryFn: () =>
      client(SHOW_ORDERS_URL.replace(':showId', String(showId)), {
        ...options,
      })
        .then((data) => {
          const result = data.results.map(snakeKeysToCamel);
          return result;
        })
        .catch((error) => {
          console.error('Error: ', error);
        }),
  });
}

function updateOrderStatus(companyId: number, orderId: number, status: string) {
  return client(COMPANY_ORDER_URL.replace(':companyId', String(companyId)).replace(':orderId', String(orderId)), {
    method: 'PATCH',
    data: {
      status,
    },
  });
}

function useUpdateOrderStatus(): UseMutationResult<
  Order,
  unknown,
  { companyId: number; orderId: number; status: string }
> {
  const queryClient = useQueryClient();
  return useMutation((args) => updateOrderStatus(args.companyId, args.orderId, args.status), {
    onSuccess: (data) => {
      queryClient.setQueryData(['order', data.id], snakeKeysToCamel(data));
    },
    onSettled: () => {
      queryClient.invalidateQueries(['orders']);
    },
  });
}

export { useOrder, useOrders, useUpdateOrderStatus, useShowOrders };