import { Text } from '@chakra-ui/react';
import React from 'react';

interface ProductStatusProps {
  label: string;
  variant?: 'success' | 'danger' | 'alert';
}

const ProductStatus: React.FC<ProductStatusProps> = ({
  label,
  variant = 'copy',
}): React.ReactElement => {
  return (
    <Text color={`ui.${variant}`} fontSize="sm" fontWeight="semibold">
      {label}
    </Text>
  );
};

export default ProductStatus;
