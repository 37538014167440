import { Button } from '@chakra-ui/button';
import {
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
} from '@chakra-ui/modal';

interface DeleteConfirmationModalProps {
  cancelRef: any;
  title: string;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  title,
  cancelRef,
  onDelete,
  onClose,
}) => {
  return (
    <>
      <AlertDialogHeader fontSize="lg" fontWeight="bold">
        {title}
      </AlertDialogHeader>

      <AlertDialogBody>
        Estás seguro que quieres eliminarlo? No puedes volver atrás esta acción.
      </AlertDialogBody>

      <AlertDialogFooter>
        <Button variant="secondary" ref={cancelRef} onClick={onClose}>
          Conservar
        </Button>
        <Button colorScheme="red" onClick={onDelete} ml={3}>
          Eliminar
        </Button>
      </AlertDialogFooter>
    </>
  );
};

export default DeleteConfirmationModal;
