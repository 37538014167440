function capitalizeString(inputString: string) {
  // Check if the inputString is empty or null
  if (!inputString) {
    return inputString;
  }

  // Capitalize the first letter and concatenate it with the rest of the string
  return inputString.charAt(0).toUpperCase() + inputString.slice(1);
}

/**
 * Convert a camelCase string to snake_case.
 * @param camelCaseStr - The input string in camelCase format.
 * @returns The string converted to snake_case format.
 */
function camelStringToSnakeCase(camelCaseStr: string): string {
  // Use a regular expression to replace uppercase letters with underscores
  return camelCaseStr.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`);
}

/**
 * Converts all keys in a given object from camelCase to snake_case recursively.
 * @param obj - The object to transform.
 * @returns A new object with all keys in snake_case.
 */
function camelKeysToSnake(obj: Record<string, any>): Record<string, any> {
  if (obj === null || typeof obj !== 'object' || obj instanceof File) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(camelKeysToSnake);
  }

  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    const snakeKey = camelStringToSnakeCase(key);
    acc[snakeKey] = camelKeysToSnake(obj[key]);
    return acc;
  }, {});
}

function snakeKeysToCamel(obj: Record<string, any>): Record<string, any> {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(snakeKeysToCamel);
  }

  return Object.keys(obj).reduce((acc: Record<string, any>, key) => {
    const camelKey = key.replace(/_([a-z])/g, (match, group) =>
      group.toUpperCase()
    );
    acc[camelKey] = snakeKeysToCamel(obj[key]);
    return acc;
  }, {});
}

/**
 * Formats a CUIT (Clave Única de Identificación Tributaria) value by adding dashes.
 * @param value - The CUIT value to format.
 * @returns The formatted CUIT value.
 */
function formatCuit(value: string) {
  const digitsOnly = value.replace(/\D/g, '');

  const formattedCuit = `${digitsOnly.slice(0, 2)}-${digitsOnly.slice(
    2,
    10
  )}-${digitsOnly.slice(10)}`;

  return formattedCuit;
}

export {
  capitalizeString,
  camelKeysToSnake,
  camelStringToSnakeCase,
  formatCuit,
  snakeKeysToCamel,
};
