import { Fragment } from 'react';
import { Icon } from '@chakra-ui/icon';
import { Box } from '@chakra-ui/layout';
import {
  Table as ChakraTable,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/table';
import { flexRender, Table as TTable } from '@tanstack/react-table';
import { TbArrowDown, TbArrowUp } from 'react-icons/tb';

import ProductOrderTable from '../../../pages/Orders/components/ProductOrderTable';
import { Order } from '../../../types/order';
import EmptyState from './EmptyState';
interface TableProps<T> {
  table: TTable<T>;
}

const Table = <T,>({ table }: TableProps<T>) => {
  return (
    <TableContainer>
      <ChakraTable>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id} bg="brand.white">
              {headerGroup.headers.map((header) => {
                return (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <Box
                        as="span"
                        cursor={
                          header.column.getCanSort() ? 'pointer' : 'default'
                        }
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: <Icon as={TbArrowUp} />,
                          desc: <Icon as={TbArrowDown} />,
                        }[header.column.getIsSorted() as string] ?? null}
                      </Box>
                    )}
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <Fragment key={row.id}>
                <Tr>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <Td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Td>
                    );
                  })}
                </Tr>
                {row.getIsExpanded() && (
                  <Tr>
                    <Td colSpan={7}>
                      <ProductOrderTable
                        products={(row.original as Order).items}
                      />
                    </Td>
                  </Tr>
                )}
              </Fragment>
            );
          })}
          {table.getRowModel().rows.length === 0 && (
            <Tr height="full">
              <Td colSpan={table.getAllColumns().length}>
                <EmptyState />
              </Td>
            </Tr>
          )}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};

export default Table;
