/**
 * Formats a date string into a human-readable date format without the time.
 * @param inputDateStr - The input date string to be formatted. This should be a string that can be parsed into a valid Date object.
 * @param overrides - An optional object that specifies overrides for the date formatting options. This object can have the following properties:
 *   - day: The representation of the day. Possible values are "numeric", "2-digit".
 *   - month: The representation of the month. Possible values are "numeric", "2-digit", "long", "short", "narrow".
 *   - year: The representation of the year. Possible values are "numeric", "2-digit".
 * @returns A formatted date string without the time. If no overrides are specified, the format will be "DD Mon YYYY" (e.g., "30 Sep 2023").
 */
function formatDate(
  inputDateStr: string,
  overrides: Intl.DateTimeFormatOptions = {}
): string {
  const { day, month, year } = overrides;
  const options = {
    day: day || '2-digit',
    month: month || 'short',
    year: year || 'numeric',
  };
  const inputDate = new Date(inputDateStr);
  return inputDate.toLocaleDateString('es-AR', options);
}

/**
 * Formats a date string into a human-readable date format.
 * @param inputDateStr - The input date string to be formatted. This should be a string that can be parsed into a valid Date object.
 * @param overrides - An optional object that specifies overrides for the date and time formatting options. This object can have the following properties:
 *   - day: The representation of the day. Possible values are "numeric", "2-digit".
 *   - month: The representation of the month. Possible values are "numeric", "2-digit", "long", "short", "narrow".
 *   - year: The representation of the year. Possible values are "numeric", "2-digit".
 *   - hour: The representation of the hour. Possible values are "numeric", "2-digit". If undefined, the hour will not be included in the formatted string.
 *   - minute: The representation of the minute. Possible values are "numeric", "2-digit". If undefined, the minute will not be included in the formatted string.
 *   - hour12: Whether to use 12-hour time (as opposed to 24-hour time). Possible values are true or false.
 * @returns A formatted date string. If no overrides are specified, the format will be "DD Mon YYYY HH:mm" (e.g., "30 Sep 2023 19:00"). If the hour or minute is set to undefined in the overrides, they will not be included in the formatted string.
 */
function formatDateTime(
  inputDateStr: string,
  overrides: Intl.DateTimeFormatOptions = {}
): string {
  const { day, month, year, hour, minute, hour12 } = overrides;
  const options = {
    day: day || '2-digit',
    month: month || 'short',
    year: year || 'numeric',
    hour: hour || '2-digit',
    minute: minute || '2-digit',
    hour12: hour12 || false,
    timeZone: 'UTC',
  };
  const inputDate = new Date(inputDateStr);
  if (isNaN(inputDate.getTime())) {
    throw new Error('Invalid Date');
  }
  return inputDate.toLocaleString(undefined, options);
}

/**
 * Gets the current date and time as a formatted string in "YYYY-MM-DD HH:mm:ss" format.
 * @returns A string representing the current date and time in "YYYY-MM-DD HH:mm:ss" format.
 */
function getCurrentDateTime(): string {
  const now: Date = new Date();
  const year: number = now.getFullYear();
  const month: number = now.getMonth() + 1; // Month is zero-based
  const day: number = now.getDate();
  const hours: number = now.getHours();
  const minutes: number = now.getMinutes();
  const seconds: number = now.getSeconds();

  // Format the date and time as "YYYY-MM-DD HH:mm:ss"
  const formattedDateTime: string = `${year}-${String(month).padStart(
    2,
    '0'
  )}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  return formattedDateTime;
}

/**
 * Gets the current date as a formatted string in "YYYY-MM-DD" format.
 * @returns A string representing the current date in "YYYY-MM-DD" format.
 */
function getCurrentDate(): string {
  const now: Date = new Date();
  const year: number = now.getFullYear();
  const month: number = now.getMonth() + 1; // Month is zero-based
  const day: number = now.getDate();

  // Format the date as "YYYY-MM-DD"
  const formattedDate: string = `${year}-${String(month).padStart(
    2,
    '0'
  )}-${String(day).padStart(2, '0')}`;

  return formattedDate;
}

/**
 * Gets the current time as a formatted string.
 * @returns A string representing the current time in the format "HH:mm:ss" (or "HH:mm" in 24-hour format).
 */
function getCurrentTime(): string {
  const now: Date = new Date();
  const hours: number = now.getHours();
  const minutes: number = now.getMinutes();

  // Format the time as HH:mm or HH:mm for 24-hour format
  const formattedTime: string = `${String(hours).padStart(2, '0')}:${String(
    minutes
  ).padStart(2, '0')}`;

  return formattedTime;
}

/**
 * Calculates the difference in days between two dates.
 * @param date1 - The first date.
 * @param date2 - The second date.
 * @returns The number of days between the two dates.
 */
function getDaysDifference(date1: Date, date2: Date): number {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const diffDays = Math.round(
    Math.abs((date1.getTime() - date2.getTime()) / oneDay)
  );
  return diffDays;
}

/**
 * Converts a date string to a local ISO time string.
 *
 * @param dateString - The date string to convert. This should be a string that can be parsed into a valid Date object.
 * @returns The local ISO time string.
 */
function toLocalISOTimeString(dateString: string): string {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    // The date is not valid
    console.error('Invalid date');
    return '';
  }

  const timezoneOffset = date.getTimezoneOffset() * 60000;
  const localISOTime = new Date(date.getTime() - timezoneOffset)
    .toISOString()
    .slice(0, -8);
  return localISOTime;
}

export {
  formatDate,
  formatDateTime,
  getCurrentDate,
  getCurrentDateTime,
  getCurrentTime,
  getDaysDifference,
  toLocalISOTimeString,
};
