import {
  Button,
  Divider,
  HStack,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { HiOutlineChat, HiOutlineMail, HiOutlinePhone } from 'react-icons/hi';
import { formatPrice } from '../../ui/ProductMeta/PriceTag';

interface IOrderSummaryProps {
  shippingPrice: number;
  cartTotalPrice: number;
  orderTotalPrice: number;
}
export const OrderSummary = ({
  shippingPrice,
  cartTotalPrice,
  orderTotalPrice,
}: IOrderSummaryProps) => {
  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Stack spacing="8">
        <Stack spacing="6">
          <Stack spacing="3">
            <Stack direction="row" justify="space-between">
              <Text color={useColorModeValue('gray.600', 'gray.300')}>
                Subtotal
              </Text>
              <Text color={useColorModeValue('black', 'white')}>
                {formatPrice(cartTotalPrice)}
              </Text>
            </Stack>
            <Stack direction="row" justify="space-between">
              <Text color={useColorModeValue('gray.600', 'gray.300')}>
                Costo envío
              </Text>
              <Text color={useColorModeValue('black', 'white')}>
                {formatPrice(shippingPrice)}
              </Text>
            </Stack>
          </Stack>
          <Divider />
          <Stack direction="row" justify="space-between">
            <Text
              fontSize="lg"
              fontWeight="semibold"
              color={useColorModeValue('gray.700', 'gray.200')}
            >
              Total
            </Text>
            <Text
              fontSize="xl"
              fontWeight="semibold"
              color={useColorModeValue('black', 'white')}
            >
              {formatPrice(orderTotalPrice)}
            </Text>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
