import {
  Box,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
} from '@chakra-ui/react';

interface IProductListDrawerProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose?: () => void;
}

export const ShopDrawer = ({
  children,
  isOpen,
  onClose,
}: IProductListDrawerProps) => (
  <Drawer
    isOpen={isOpen}
    onClose={onClose ? onClose : () => null}
    placement="bottom"
  >
    <DrawerOverlay />
    <DrawerContent maxH="80vh" overflowY="auto">
      <DrawerCloseButton
        size="lg"
        right={{ base: '4', md: '8' }}
        top="4"
        bg="inherit"
      />
      {children}
    </DrawerContent>
  </Drawer>
);
