import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Flex, Heading } from '@chakra-ui/layout';

import { useAuth } from '../../context/AuthContext';
import {
  useCategories,
  useProduct,
  useUpdateProduct,
} from '../../queries/products';
import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useAlert } from '../../context/AlertContext';
import { IProduct } from '../../types/product';
import ProductForm from './components/ProductForm';

const EditProduct = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openAlert } = useAlert();
  const { data: product, isLoading: isProductLoading } = useProduct(
    Number(productId),
    user!.companyId
  );
  const { data: categories, isLoading: isCategoryLoading } = useCategories();
  const { mutate: updateProduct } = useUpdateProduct(user!.companyId);

  const handleSubmit = async (values: IProduct) => {
    try {
      await updateProduct(values);
      navigate('/dashboard/products');
    } catch (error) {
      console.error(error);
      openAlert({
        status: 'error',
        variant: 'left-accent',
        description: 'Error al crear el producto. Inténtalo de nuevo.',
      });
    }
  };

  if (isCategoryLoading || isProductLoading) {
    return <CenteredSpinner />;
  }

  return (
    categories &&
    product && (
      <Flex
        direction="column"
        gap={{ base: 1, md: 3 }}
        w={{ base: '100%', md: '80%', xl: '60%' }}
      >
        <Heading as="h2" fontSize="3xl">
          Editar producto
        </Heading>
        <Divider />
        <ProductForm
          categories={categories}
          onSubmit={handleSubmit}
          initialValues={product}
        />
      </Flex>
    )
  );
};

export default EditProduct;
