import {
  TbEye,
  TbMessages,
  TbShoppingBag,
  TbVideo,
  TbWallet,
} from 'react-icons/tb';

import { CompanyMetrics } from '../types/company';
import { Metric } from '../types/metric';

function getMetricsData(metricsData: CompanyMetrics): Metric[] {
  return [
    {
      label: 'Shows realizados',
      value: metricsData.performedShows,
      icon: TbVideo,
    },
    {
      label: 'Audiencia única',
      value: metricsData.uniqueAudience,
      icon: TbEye,
    },
    {
      label: 'Interacciones',
      value: metricsData.interactions,
      icon: TbMessages,
    },
    {
      label: 'Ordenes recibidas',
      value: metricsData.ordersCount,
      icon: TbShoppingBag,
    },
    {
      label: 'Ventas totales',
      value: `$${metricsData.salesAmount}`,
      icon: TbWallet,
    },
  ];
}

export { getMetricsData };
