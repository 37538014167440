import { Box, Flex, Heading } from '@chakra-ui/layout';
import { TbPackage, TbStack2, TbVideo } from 'react-icons/tb';
import StepCard from './components/StepCard';

const HomePage = () => {
  return (
    <Box
      bg="white"
      borderRadius="lg"
      px="5"
      py={{ base: 0, md: 3 }}
      height="full"
    >
      <Flex justifyContent="space-between" py="4" mb={{ base: 0, md: 10 }}>
        <Heading as="h2" color="brand.green" fontWeight="semibold">
          Empieza a vender con ShotShop!
        </Heading>
      </Flex>
      <Flex direction={{ base: 'column', md: 'row' }} gap={{ base: 6, md: 3 }}>
        <StepCard
          ctaLink="products"
          ctaLabel="Agregar productos"
          icon={TbStack2}
          message="1. Carga los productos que quieres publicitar en tus shows"
        />
        <StepCard
          ctaLabel="Crea un show"
          ctaLink="shows"
          icon={TbVideo}
          message="2. Pograma tus shows y transmite en vivos para interactuar con tu audiencia"
        />
        <StepCard
          ctaLabel="Revisa tus ordenes"
          ctaLink="orders"
          icon={TbPackage}
          message="3. Visualiza y gestiona todas las órdenes de compra que recibes"
        />
      </Flex>
    </Box>
  );
};

export default HomePage;
