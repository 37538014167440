import QueryString from 'qs';

import { camelKeysToSnake } from './transformations';
import { USER_LOCAL_STORAGE_KEY } from '../constants/localStorage';

interface CustomHeaders {
  [key: string]: string;
}

interface RequestData {
  [key: string]: any;
}

const API_URL = '/api'; // Maybe we can store this on a env variable const apiURL = process.env.REACT_APP_API_URL

type ClientParams = {
  data?: RequestData;
  queryParams?: Record<string, any>;
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'PUT';
  token?: string | null;
  signal?: AbortSignal;
  headers?: CustomHeaders;
};

async function client(
  endpoint: string,
  {
    data,
    queryParams,
    signal,
    method = 'GET',
    token,
    headers: customHeaders,
    ...customConfig
  }: ClientParams = {}
) {
  const headers = new Headers();
  if (token) {
    headers.append('Authorization', `Bearer ${token}`);
  }

  const config: RequestInit = {
    method,
    headers,
    signal,
    ...customConfig,
  };

  if (data instanceof FormData) {
    config.body = data;
  } else if (data) {
    headers.append('Content-Type', 'application/json');
    config.body = JSON.stringify(data);
  }

  for (const headerKey in customHeaders) {
    if (customHeaders.hasOwnProperty(headerKey)) {
      headers.append(headerKey, customHeaders[headerKey]);
    }
  }
  let url = `${API_URL}/${endpoint}`;
  if (queryParams) {
    const searchParams = QueryString.stringify(camelKeysToSnake(queryParams));
    url = `${API_URL}/${endpoint}?${searchParams.toString()}`;
  }

  return window.fetch(url, config).then(async (response) => {
    if (response.status === 401) {
      // await logoutUser(token as string);
      localStorage.removeItem(USER_LOCAL_STORAGE_KEY);

      // refresh the page for them
      window.location.assign(window.location.href);
      return Promise.reject<Error>({ message: 'Please re-authenticate.' });
    }
    if (response.status === 204) {
      return null;
    }
    const contentType = response.headers.get('content-type');
    let data = null;
    if (contentType && contentType.includes('application/json')) {
      data = await response.json();
    }
    if (response.ok) {
      return data;
    } else {
      return Promise.reject<Error>(data);
    }
  });
}

/**
 * Creates a FormData object from the given data object.
 *
 * @param data - The data object to convert to FormData.
 * @returns A FormData object containing the converted data.
 */
function createFormData(data: RequestData): FormData {
  const formData = new FormData();
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (value) {
        if (Array.isArray(value) && value[0] instanceof File) {
          // Handle array of images
          value.forEach((image, index) => {
            formData.append(`${key}[${index}]`, image);
          });
        } else if (
          typeof value === 'string' ||
          value instanceof Blob ||
          value instanceof File
        ) {
          formData.append(key, value);
        } else {
          formData.append(key, JSON.stringify(value));
        }
      }
    }
  }
  return formData;
}

export { client, createFormData, type ClientParams };
