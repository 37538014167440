import { Box, Heading, Stack } from '@chakra-ui/react';

import { IShow } from '../../../types/show';
import { IProductStock } from '../../../types/product';
import { ProductItem } from './ProductItem';

interface ProductListProps {
  show: IShow;
  productStockList: IProductStock[];
  onProductSelected?: (product: IProductStock) => void;
}

export const ProductList = ({
  show,
  productStockList,
  onProductSelected: onSelectProduct,
}: ProductListProps): JSX.Element => {
  return (
    <Box
      maxW={{ base: '3xl', lg: '7xl' }}
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
    >
      <Stack spacing={{ base: '8', md: '10' }} flex="2">
        <Heading fontSize="2xl" fontWeight="extrabold">
          Productos en el vivo
        </Heading>

        <Stack spacing="6">
          {productStockList.map((productStock: IProductStock) => (
            <ProductItem
              key={productStock.product.id}
              productStock={productStock}
              onClick={
                onSelectProduct
                  ? () => onSelectProduct(productStock)
                  : undefined
              }
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};
