import { useMemo, useState } from 'react';
import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button } from '@chakra-ui/button';
import { Flex } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';

import { Customer, Order, ProductItem } from '../../../types/order';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import Table from '../../../components/ui/Table/Table';

interface ShowOrdersTableProps {
  orders: Order[];
}

const ShowOrdersTable: React.FC<ShowOrdersTableProps> = ({ orders }) => {
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const columns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'Orden',
        cell: (info) => `#${info.getValue()}`,
      },
      {
        accessorKey: 'items',
        header: 'Productos',
        enableSorting: false,
        cell: ({ row, getValue }) => {
          const products = getValue() as ProductItem[];
          return (
            <>
              <Flex gap={2}>
                <Button
                  variant="link"
                  color="ui.link"
                  onClick={() => {
                    row.toggleExpanded();
                  }}
                >
                  <Flex gap={2} align="center">
                    {products.length}
                    {row.getIsExpanded() ? (
                      <Icon as={TbChevronUp} />
                    ) : (
                      <Icon as={TbChevronDown} />
                    )}
                  </Flex>
                </Button>
              </Flex>
            </>
          );
        },
      },
      {
        accessorKey: 'total',
        header: 'Total',
        cell: (info) => formatPrice(info.getValue() as number),
      },
      {
        accessorKey: 'customer',
        header: 'Comprador',
        cell: (info) => {
          const customer = info.getValue() as Customer;
          return `${customer.firstName} ${customer.lastName}`;
        },
      },
    ],
    []
  );

  const table = useReactTable({
    data: orders,
    columns,
    state: {
      expanded,
    },
    autoResetPageIndex: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableExpanding: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    onExpandedChange: setExpanded,
  });

  return <Table table={table} />;
};

export default ShowOrdersTable;
