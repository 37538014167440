import {
  MutationOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { client } from '../utils/api-client';
import { camelKeysToSnake, snakeKeysToCamel } from '../utils/transformations';
import { CompanyMetrics, ICompany } from '../types/company';

const COMPANY_URL = 'company/:companyId/';

function fetchCompany(companyId: number) {
  return client(COMPANY_URL.replace(':companyId', String(companyId))).then(
    (data: ICompany) => snakeKeysToCamel(data)
  );
}

function useCompany(companyId: number): UseQueryResult<ICompany> {
  return useQuery({
    queryKey: ['company', companyId],
    queryFn: () => fetchCompany(companyId),
  });
}

const COMPANY_METRICS_URL = 'company/:companyId/shows/aggregations/';

function fetchCompanyMetrics(companyId: number) {
  return client(
    COMPANY_METRICS_URL.replace(':companyId', String(companyId))
  ).then((data: ICompany) => snakeKeysToCamel(data));
}

function useCompanyMetrics(companyId: number): UseQueryResult<CompanyMetrics> {
  return useQuery({
    queryKey: ['company', companyId, 'metrics'],
    queryFn: () => fetchCompanyMetrics(companyId),
  });
}

const COMPANY_PENDING_SHIPPING_ORDERS_COUNT_URL =
  'company/:companyId/orders/pending_shipping_count';

function fetchCompanyPendingShippingOrdersCount(companyId: number) {
  return client(
    COMPANY_PENDING_SHIPPING_ORDERS_COUNT_URL.replace(
      ':companyId',
      String(companyId)
    )
  );
}

function usePendingShippingOrdersCount(
  companyId: number
): UseQueryResult<number> {
  return useQuery({
    queryKey: ['company', companyId, 'pending_shipping_orders_count'],
    queryFn: () =>
      fetchCompanyPendingShippingOrdersCount(companyId).then(
        (data) => data.count
      ),
  });
}

function updateCompany(companyData: ICompany): Promise<ICompany> {
  return client(COMPANY_URL.replace(':companyId', String(companyData.id)), {
    method: 'PATCH',
    data: camelKeysToSnake(companyData),
  });
}

function useUpdateCompany(
  options?: MutationOptions<ICompany, unknown, ICompany>
): UseMutationResult<ICompany, unknown, ICompany> {
  const queryClient = useQueryClient();

  return useMutation(updateCompany, {
    onSuccess: (data) => queryClient.invalidateQueries(['company', data.id]),
  });
}

export {
  fetchCompanyPendingShippingOrdersCount,
  useCompany,
  useCompanyMetrics,
  useUpdateCompany,
  usePendingShippingOrdersCount,
};
