import { Button, HStack, Input, useNumberInput } from '@chakra-ui/react';

interface IQuantityStepperProps {
  quantity: number;
  onChange?: (quantity: number) => void;
  min?: number;
  max?: number;
}

export const QuantityStepper = ({
  quantity,
  onChange,
  min,
  max,
}: IQuantityStepperProps) => {
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } =
    useNumberInput({
      step: 1,
      defaultValue: quantity,
      min: min || 1,
      max: max || 1000,
      onChange: (valueAsString: string, valueAsNumber: number) => {
        if (typeof onChange === 'function') {
          onChange(valueAsNumber);
        }
      },
    });

  const inc = getIncrementButtonProps();
  const dec = getDecrementButtonProps();
  const input = getInputProps();

  return (
    <HStack maxW="220px">
      <Button {...dec}>-</Button>
      <Input {...input} isReadOnly={true} maxW="65px" />
      <Button {...inc}>+</Button>
    </HStack>
  );
};
