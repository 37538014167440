import { Divider, Flex, Heading } from '@chakra-ui/layout';

import ProductForm from './components/ProductForm';
import { IProductDataSchema } from './components/validationSchema';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useCategories, useCreateProduct } from '../../queries/products';
import { useAlert } from '../../context/AlertContext';

const AddProduct = () => {
  const navigate = useNavigate();
  const { data: categories } = useCategories();
  const { user } = useAuth();
  const { openAlert } = useAlert();

  const { mutate: createProduct } = useCreateProduct(user!.companyId);

  const handleSubmit = async (values: IProductDataSchema) => {
    try {
      await createProduct(values);
      navigate('/dashboard/products');
    } catch (error) {
      console.error(error);
      openAlert({
        status: 'error',
        variant: 'left-accent',
        description: 'Error al crear el producto. Inténtalo de nuevo.',
      });
    }
  };

  const initialValues: IProductDataSchema = {
    name: '',
    description: '',
    categoryId: -1,
    brand: '',
    weight: 0,
    status: 'active',
    variants: [
      {
        price: 0,
        stock: 0,
        title: 'Variante unica',
        attributes: [],
      },
    ],
    attributes: [],
  };

  return (
    categories && (
      <Flex
        direction="column"
        gap={{ base: 1, md: 3 }}
        w={{ base: '100%', md: '80%', xl: '60%' }}
      >
        <Heading as="h2" fontSize="3xl">
          Nuevo producto
        </Heading>
        <Divider />
        <ProductForm
          ctaLabel="Agregar producto"
          categories={categories}
          onSubmit={handleSubmit}
          initialValues={initialValues}
        />
      </Flex>
    )
  );
};

export default AddProduct;
