import { client } from './api-client';

const COMPANY_PAYMENT_METHODS_URL =
  'company/:companyId/payment-processors/mercadopago/oauth/';

const linkMercadoPagoAccount = async (code: string, companyId: number) => {
  return client(
    COMPANY_PAYMENT_METHODS_URL.replace(':companyId', String(companyId)),
    {
      data: { code: code },
      method: 'POST',
    }
  );
};

const unlinkMercadoPagoAccount = async (companyId: number) => {
  return client(
    COMPANY_PAYMENT_METHODS_URL.replace(':companyId', String(companyId)),
    {
      method: 'DELETE',
    }
  );
};

export {
  COMPANY_PAYMENT_METHODS_URL,
  linkMercadoPagoAccount,
  unlinkMercadoPagoAccount,
};
