import React, { useState } from 'react';
import {
  ColumnDef,
  RowSelectionState,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import { Flex } from '@chakra-ui/layout';
import { Checkbox } from '@chakra-ui/checkbox';

import Table from '../../../components/ui/Table/Table';
import { IProduct } from '../../../types/product';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import ProductDescription from '../../../components/ui/Table/ProductDescription';
import VariantTag from '../../Products/components/VariantTag';
import { IShowDataSchema } from './validationSchema';

interface Props {
  products: IProduct[];
  initialProductSelection?: Record<string, boolean>;
}

const ShowsProductsTable: React.FC<Props> = ({
  products,
  initialProductSelection = {},
}) => {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    initialProductSelection
  );
  const [sorting, setSorting] = useState<SortingState>([]);

  const { values, setFieldValue } = useFormikContext<IShowDataSchema>();

  const columns: ColumnDef<IProduct>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          isChecked={table.getIsAllRowsSelected()}
          isIndeterminate={table.getIsSomeRowsSelected()}
          onChange={(e) => {
            table.getToggleAllRowsSelectedHandler()(e);

            if (table.getIsAllPageRowsSelected()) {
              setFieldValue('products', []);
            } else {
              setFieldValue(
                'products',
                products.map((product) => ({
                  productId: product.id,
                  visible: true,
                }))
              );
            }
          }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          isChecked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={(e) => {
            row.getToggleSelectedHandler()(e);

            if (row.getIsSelected()) {
              setFieldValue(
                'products',
                values.products.filter(
                  (product) => product.productId !== row.original.id
                )
              );
            } else {
              setFieldValue('products', [
                ...values.products,
                {
                  productId: row.original.id,
                  visible: true,
                },
              ]);
            }
          }}
        />
      ),
    },
    {
      header: 'Producto',
      accessorKey: 'name',
      cell: (info) => {
        return (
          <ProductDescription
            name={info.getValue() as string}
            image={info.row.original.images[0]}
            url={`/dashboard/products/${info.row.original.id}/edit`}
          />
        );
      },
    },
    {
      header: 'Variantes',
      accessorKey: 'attributes',
      cell: (info) => {
        const attributes = info.getValue() as IProduct['attributes'];
        return (
          <Flex direction="column" gap={2}>
            {attributes.map((attribute) => (
              <Flex key={attribute.name} alignItems="center" gap={2}>
                {attribute.values.map((value) => (
                  <VariantTag key={value} label={value} />
                ))}
              </Flex>
            ))}
          </Flex>
        );
      },
      enableSorting: false,
    },
    {
      header: 'Stock',
      accessorFn: (row) =>
        row.variants.reduce((acc, variant) => {
          return acc + variant.stock;
        }, 0),
    },
    {
      accessorFn: (row) => row.variants[0]?.price,
      header: 'Precio',
      cell: (info) => formatPrice(info.getValue() as number),
    },
  ];

  const table = useReactTable({
    data: products,
    columns,
    state: {
      rowSelection,
      sorting,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
  });
  return <Table table={table} />;
};

export default ShowsProductsTable;
