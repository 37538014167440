import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import ReactDOM from 'react-dom';
import { Flex } from '@chakra-ui/layout';

import Alert from '../components/ui/Alert/Alert';

type AlertContextData = {
  openAlert: (alertData: AlertConfig) => void;
};

const AlertContext = createContext<AlertContextData | null>(null);

function useAlert() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within a AlertProvider.');
  }

  return context;
}

interface AlertProviderProps {
  children: ReactNode;
}

interface AlertConfig {
  status?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  description: string;
  onClose?: () => void;
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent';
  autoDismiss?: boolean;
  dismissTime?: number;
}

const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<(AlertConfig & { id: string })[]>([]);

  const timeouts = useRef<NodeJS.Timeout[]>([]);

  const openAlert = useCallback((alertData: AlertConfig) => {
    const id = uuidv4();
    setAlerts((prevAlerts) => [...prevAlerts, { ...alertData, id }]);
    if (alertData.autoDismiss) {
      const timeoutId = setTimeout(() => {
        setAlerts((prevAlerts) =>
          prevAlerts.filter((alert) => alert.id !== id)
        );
      }, alertData.dismissTime || 5000);

      timeouts.current.push(timeoutId);
    }
  }, []);

  useEffect(() => {
    const currentTimeouts = timeouts.current;
    return () => {
      currentTimeouts.forEach(clearTimeout);
    };
  }, []);

  const closeAlert = useCallback((id: string) => {
    setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert.id !== id));
  }, []);

  const toastRoot = document.getElementById('toast-root');

  return (
    <AlertContext.Provider value={{ openAlert }}>
      {children}
      {toastRoot?.parentNode &&
        ReactDOM.createPortal(
          <Flex direction="column" gap={3} mx="20%">
            {alerts.map((alert) => (
              <Alert
                status={alert.status}
                key={alert.id}
                description={alert.description}
                onClose={() => closeAlert(alert.id)}
              />
            ))}
          </Flex>,
          toastRoot
        )}
    </AlertContext.Provider>
  );
};

export { AlertProvider, useAlert };
