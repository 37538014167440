import {
  Box,
  Flex,
  Heading,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode,
} from '@chakra-ui/react';

import { CartItem } from './CartItem';
import { CartOrderSummary } from './CartOrderSummary';
import { IShow } from '../../../types/show';
import { ICartItem } from '../../../types/cart';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { IProductStock } from '../../../types/product';
import { useConsolidatedCartItemsState } from '../../../hooks/useConsolidatedCartItemsState';

interface ShoppingCartProps {
  show: IShow;
  productStockList: IProductStock[];
  onKeepShopping?: () => void;
  onInitCheckout?: () => void;
}

interface ICartItemProduct {
  cartItem: ICartItem;
  productStock: IProductStock;
}



export const ShoppingCart = ({
  show,
  productStockList,
  onKeepShopping,
  onInitCheckout,
}: ShoppingCartProps): JSX.Element => {
  const { getCartItems, updateCartQuantity, removeFromCart } =
    useShoppingCart();
  const cartItems = getCartItems(show.id);
  const {cartItemsProducts, totalPrice} =  useConsolidatedCartItemsState({cartItems, productStockList});


  const handleChangeQuantity = (productId: number, quantity: number) => {
    updateCartQuantity(show.id, productId, quantity);
  };

  const handleClickDelete = (productId: number) => {
    removeFromCart(show.id, productId);
  };

  return (
    <Box
      maxW={{ base: '3xl', lg: '7xl' }}
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        align={{ lg: 'flex-start' }}
        spacing={{ base: '8', md: '16' }}
      >
        <Stack spacing={{ base: '8', md: '10' }} flex="2">
          <Heading fontSize="2xl" fontWeight="extrabold">
            Carrito ({cartItems.length} items)
          </Heading>

          <Stack spacing="6">
            {cartItemsProducts.map(
              ({ cartItem, productStock }: ICartItemProduct) => (
                <CartItem
                  cartItem={cartItem}
                  productStock={productStock}
                  key={cartItem.productId}
                  onChangeQuantity={(quantity: number) =>
                    handleChangeQuantity(cartItem.productId, quantity)
                  }
                  onClickDelete={() => handleClickDelete(cartItem.productId)}
                />
              )
            )}
          </Stack>
        </Stack>

        <Flex direction="column" align="center" flex="1">
          <CartOrderSummary totalPrice={totalPrice} totalItems={cartItems.length} onInitCheckout={onInitCheckout}/>
          <HStack mt="6" fontWeight="semibold">
            <p>&oacute;</p>
            <Link onClick={onKeepShopping} color={mode('blue.500', 'blue.200')}>Seguir Comprando</Link>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );
};
