import { useState } from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { ProductItem } from '../../../types/order';
import { IProduct } from '../../../types/product';
import Table from '../../../components/ui/Table/Table';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import ProductDescription from '../../../components/ui/Table/ProductDescription';

interface Props {
  products: ProductItem[];
}

const ProductOrderTable: React.FC<Props> = ({ products }) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns: ColumnDef<ProductItem>[] = [
    {
      header: 'Producto',
      accessorKey: 'product',
      cell: (info) => {
        const productItem = info.getValue() as IProduct;
        return (
          <ProductDescription
            name={productItem.name}
            image={productItem.images[0]}
          />
        );
      },
    },
    {
      header: 'Variante',
      accessorKey: 'variantId',
      cell: (info) => {
        const variantId = info.getValue() as number;
        const productItem = info.row.original.product as IProduct;
        const variant = productItem.variants.find(
          (variant) => variant.id === variantId
        );
        return variant?.title ?? '-';
      },
    },
    {
      header: 'Cantidad',
      accessorKey: 'quantity',
    },
    {
      header: 'Precio',
      accessorKey: 'price',
      cell: (info) => formatPrice(info.getValue() as number),
    },
    {
      id: 'total',
      header: 'Total',
      cell: (info) =>
        formatPrice(
          info.row.original.quantity * Number(info.row.original.price)
        ),
    },
  ];

  const table = useReactTable({
    data: products,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });

  return <Table table={table} />;
};

export default ProductOrderTable;
