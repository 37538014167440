import { Link } from 'react-router-dom';
import { IconButton } from '@chakra-ui/button';
import { Card, CardBody } from '@chakra-ui/card';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import {
  Badge,
  Box,
  Flex,
  Heading,
  Text,
  LinkBox,
  LinkOverlay,
} from '@chakra-ui/layout';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import {
  TbCalendar,
  TbDotsVertical,
  TbEdit,
  TbStack2,
  TbTrash,
  TbUser,
} from 'react-icons/tb';

import { toLocalISOTimeString } from '../../../utils/dates';
import { IShow } from '../../../types/show';

type UpcomingShowCardProps = {
  badge?: string;
  onDelete?: () => void;
  show: IShow;
};

const UpcomingShowCard: React.FC<UpcomingShowCardProps> = ({
  badge,
  onDelete,
  show,
}) => {
  const { host, id, image, name, slugName, startDateTime } = show;

  return (
    <LinkBox as="article" maxW={{ base: '100%', sm: '480px' }}>
      <Card
        variant="outline"
        direction={{ base: 'column', sm: 'row' }}
        gap={3}
        bg="brand.white"
        p={5}
        overflow="hidden"
        border="1px solid #E4EAF2"
      >
        <Image
          objectFit="cover"
          boxSize={{ base: '100%', sm: '120px' }}
          src={image}
          fallbackSrc="https://via.placeholder.com/120"
          alt={name}
          borderRadius="lg"
        />

        <CardBody p={0}>
          <Flex justify="space-between" align="flex-start">
            <Box>
              {badge && (
                <Badge
                  bg="brand.primary.500"
                  color="white"
                  borderRadius="full"
                  paddingX="8px"
                  paddingY="2px"
                  mb={2}
                >
                  {badge}
                </Badge>
              )}
              <Flex direction="column" gap={2}>
                <LinkOverlay href={`shows/${slugName}`}>
                  <Heading size="sm" noOfLines={1}>
                    {name}
                  </Heading>
                </LinkOverlay>
                <Flex alignItems="center">
                  <Icon as={TbCalendar} boxSize={5} color="ui.medium-gray" />
                  <Text fontSize="xs" color="ui.medium-gray">
                    Fecha y hora: {toLocalISOTimeString(startDateTime)}
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <Icon as={TbUser} boxSize={5} color="ui.medium-gray" />
                  <Text fontSize="xs" color="ui.medium-gray">
                    Presentador: {host || 'No asignado'}
                  </Text>
                </Flex>
                <Flex alignItems="center">
                  <Icon as={TbStack2} boxSize={5} color="ui.medium-gray" />
                  <Text fontSize="xs" color="ui.medium-gray">
                    Productos asociados: {show.products?.length || 0}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label="Opciones"
                icon={
                  <Icon
                    as={TbDotsVertical}
                    boxSize={5}
                    color="ui.medium-gray"
                  />
                }
                variant="ghost"
              />
              <MenuList minW="150px">
                <MenuItem
                  as={Link}
                  to={`${id}/edit`}
                  icon={<Icon as={TbEdit} />}
                >
                  Editar
                </MenuItem>
                <MenuItem onClick={onDelete} icon={<Icon as={TbTrash} />}>
                  Borrar
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </CardBody>
      </Card>
    </LinkBox>
  );
};

export default UpcomingShowCard;
