import { useMemo } from 'react';
import { Column } from '@tanstack/react-table';
import { Icon } from '@chakra-ui/icon';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Flex } from '@chakra-ui/layout';
import { TbSearch } from 'react-icons/tb';
import { DebounceInput } from 'react-debounce-input';

interface FilterProps {
  column: Column<any, unknown>;
  placeholder?: string;
  type: 'number' | 'text';
}

const Filter = ({ column, placeholder, type }: FilterProps) => {
  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      type === 'text'
        ? Array.from(column.getFacetedUniqueValues().keys()).sort()
        : [],
    [column, type]
  );

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    column.setFilterValue(event.target.value);
  };

  return type === 'number' ? (
    <Flex gap={2}>
      <DebounceInput
        debounceTimeout={400}
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        type="number"
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(value) =>
          column.setFilterValue((old: [number, number]) => [value, old?.[1]])
        }
        placeholder={`Min ${
          column.getFacetedMinMaxValues()?.[0]
            ? `(${column.getFacetedMinMaxValues()?.[0]})`
            : ''
        }`}
      />
      <DebounceInput
        debounceTimeout={400}
        min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
        max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
        type="number"
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(value) =>
          column.setFilterValue((old: [number, number]) => [old?.[0], value])
        }
        placeholder={`Max ${
          column.getFacetedMinMaxValues()?.[1]
            ? `(${column.getFacetedMinMaxValues()?.[1]})`
            : ''
        }`}
      />
    </Flex>
  ) : (
    <>
      <datalist id={column.id + 'list'}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <InputGroup>
        <InputRightElement>
          <Icon as={TbSearch} />
        </InputRightElement>
        <Input
          as={DebounceInput}
          debounceTimeout={400}
          borderRadius="full"
          placeholder={placeholder}
          type="text"
          value={(columnFilterValue ?? '') as string}
          onChange={handleFilterChange}
          list={column.id + 'list'}
        />
      </InputGroup>
    </>
  );
};

export default Filter;
