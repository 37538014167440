import { IShow } from '../types/show';
import { getDaysDifference } from './dates';

/**
 * Returns the badge message based on the start date of a show.
 * @param showStartDate - The start date of the show in string format.
 * @returns The badge message indicating when the show starts.
 */
function getBadgeMessage(showStartDate: string) {
  const daysUntilShow = getDaysDifference(new Date(), new Date(showStartDate));

  if (daysUntilShow === 0) {
    return `Comienza hoy`;
  } else if (daysUntilShow === 1) {
    return 'Comienza mañana';
  } else if (daysUntilShow <= 7) {
    return `Comienza en ${daysUntilShow} días`;
  } else {
    return '';
  }
}

/**
 * Selects past shows from an array of shows.
 * @param shows - An array of shows to filter past shows from.
 * @returns An array of past shows sorted by start date in descending order.
 */
function selectPastShows(shows: IShow[]) {
  const now = new Date();
  const pastShows = shows.filter((show) => new Date(show.startDateTime) < now);
  pastShows.sort(
    (a, b) =>
      new Date(b.startDateTime).getTime() - new Date(a.startDateTime).getTime()
  );
  return pastShows;
}

/**
 * Selects upcoming shows from an array of shows.
 * @param shows - An array of shows to filter.
 * @returns An array of upcoming shows sorted by start date.
 */
function selectUpcomingShows(shows: IShow[]) {
  const now = new Date();
  const upcomingShows = shows.filter(
    (show) => new Date(show.startDateTime) > now
  );
  upcomingShows.sort(
    (a, b) =>
      new Date(a.startDateTime).getTime() - new Date(b.startDateTime).getTime()
  );
  return upcomingShows;
}

export { getBadgeMessage, selectPastShows, selectUpcomingShows };
