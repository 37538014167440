import React from 'react';
import { Icon } from '@chakra-ui/icon';
import { Box, Center, Divider, Flex, HStack, Text } from '@chakra-ui/layout';
import { FaRegChartBar, FaShoppingBag, FaRegEye } from 'react-icons/fa';

interface ShowMetricsProps {
  data: {
    currentAudience: number;
    totalAudience: number;
    messages: number;
    likes: number;
    totalOrders: number;
    totalSales: string;
  };
}
const ShowMetrics: React.FC<ShowMetricsProps> = ({ data }): JSX.Element => {
  const {
    currentAudience,
    totalAudience,
    messages,
    likes,
    totalOrders,
    totalSales,
  } = data;
  return (
    <Box position="relative" borderRadius="lg">
      <Flex
        as="section"
        bg="rgba(251, 251, 255, 0.70)"
        align="flex-start"
        fontFamily="Rubik, sans-serif"
        padding={6}
        gap={5}
        borderRadius="lg"
      >
        <Box flexGrow={1}>
          <HStack>
            <Flex borderRadius="lg" bg="gray.400" p={1}>
              <Icon as={FaRegEye} />
            </Flex>
            <Text fontSize="sm">Audiencia</Text>
          </HStack>
          <HStack>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {currentAudience}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Audiencia actual
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {totalAudience}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Audiencia total
              </Text>
            </Box>
          </HStack>
        </Box>
        <Divider orientation="vertical" h={20} />
        <Box flexGrow={1}>
          <HStack>
            <Flex borderRadius="lg" bg="gray.400" p={1}>
              <Icon as={FaRegChartBar} />
            </Flex>
            <Text fontSize="sm">Actividad</Text>
          </HStack>
          <HStack>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {messages}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Mensajes
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {likes}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Likes
              </Text>
            </Box>
          </HStack>
        </Box>
        <Divider orientation="vertical" h={20} />
        <Box flexGrow={1}>
          <HStack>
            <Flex borderRadius="lg" bg="gray.400" p={1}>
              <Icon as={FaShoppingBag} />
            </Flex>
            <Text fontSize="sm">Ventas</Text>
          </HStack>
          <HStack>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {totalOrders}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Pedidos totales
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="semibold">
                {totalSales}
              </Text>
              <Text color="ui.medium-gray" fontSize="xs">
                Ventas totales
              </Text>
            </Box>
          </HStack>
        </Box>
      </Flex>
      <Center
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bg="rgba(255, 255, 255, 0.7)"
        backdropFilter="blur(3px)"
        borderRadius="lg"
      >
        <Text fontSize="2xl" fontWeight="bold">
          Proximamente
        </Text>
      </Center>
    </Box>
  );
};

export default ShowMetrics;
