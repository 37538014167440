import { Button } from '@chakra-ui/button';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/modal';
import { Text } from '@chakra-ui/layout';

interface StopLiveModalProps {
  onCancel: () => void;
  onStopClick: () => void;
}

const StopLiveModal: React.FC<StopLiveModalProps> = ({
  onCancel,
  onStopClick,
}): JSX.Element => {
  return (
    <>
      <ModalHeader>Detener Streaming</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>Estas seguro? no puedes volver atras esta acción.</Text>
      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button colorScheme="red" onClick={onStopClick} ml={3}>
          Detener
        </Button>
      </ModalFooter>
    </>
  );
};

export default StopLiveModal;
