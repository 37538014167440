import { useState } from 'react';
import {
  ColumnDef,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Flex } from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';

import { IProduct } from '../../../types/product';
import { ShowProduct } from '../../../types/show';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import Table from '../../../components/ui/Table/Table';
import ProductDescription from '../../../components/ui/Table/ProductDescription';
import VariantTag from '../../Products/components/VariantTag';

interface LiveShowProductsTableProps {
  isSwitchDisabled: boolean;
  onVisibilityChange: (productId: number, visible: boolean) => void;
  products: (IProduct & ShowProduct)[];
}

const LiveShowProductsTable: React.FC<LiveShowProductsTableProps> = ({
  isSwitchDisabled,
  onVisibilityChange,
  products,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);

  const columns: ColumnDef<IProduct>[] = [
    {
      accessorKey: 'visible',
      header: 'Visible',
      cell: (info) => (
        <Switch
          colorScheme="teal"
          onChange={() =>
            onVisibilityChange(info.row.original.id, !info.getValue())
          }
          isChecked={info.getValue() as boolean}
          isDisabled={isSwitchDisabled}
        />
      ),
      enableSorting: false,
    },
    {
      header: 'Producto',
      accessorKey: 'name',
      cell: (info) => {
        return (
          <ProductDescription
            name={info.getValue() as string}
            image={info.row.original.images[0]}
          />
        );
      },
    },
    {
      header: 'Variantes',
      accessorKey: 'attributes',
      cell: (info) => {
        const attributes = info.getValue() as IProduct['attributes'];
        return (
          <Flex direction="column" gap={2}>
            {attributes.map((attribute) => (
              <Flex key={attribute.name} alignItems="center" gap={2}>
                {attribute.values.map((value) => (
                  <VariantTag key={value} label={value} />
                ))}
              </Flex>
            ))}
          </Flex>
        );
      },
      enableSorting: false,
    },
    {
      header: 'Stock',
      accessorFn: (row) =>
        row.variants.reduce((acc, variant) => {
          return acc + variant.stock;
        }, 0),
    },
    {
      accessorFn: (row) => row.variants[0]?.price,
      header: 'Precio',
      cell: (info) => formatPrice(info.getValue() as number),
    },
  ];

  const table = useReactTable({
    data: products,
    columns,
    state: {
      sorting,
    },
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
  });
  return <Table table={table} />;
};

export default LiveShowProductsTable;
