import * as yup from 'yup';
import { findPaymentMethodForCardNumber } from './paymentMethodUtils';

export const checkoutDataSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email('Email inválido')
    .required('El email es obligatorio'),
  name: yup.string().trim().required('El nombre es obligatorio'),
  phone: yup.string().trim().required('El teléfono es obligatorio'),
  street: yup.string().trim().required('La dirección es obligatoria'),
  street_number: yup
    .string()
    .trim()
    .required('El número de calle es obligatorio'),
  depto: yup.string().trim(),
  city: yup.string().trim().required('La ciudad es obligatoria'),
  zip: yup.string().trim().required('El código postal es obligatorio'),
  state: yup.string().trim().required('La provincia es obligatoria'),
  dni: yup.string().trim().required('El DNI o CUIL es obligatorio'),
  card_number: yup
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .test('credit-card', 'Número de tarjeta inválido', function (value) {
      if (!value) return false;
      // Luhn algorithm for credit card number validation
      let sum = 0;
      let shouldDouble = false;
      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i), 10);
        if (shouldDouble) {
          if ((digit *= 2) > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }
      return sum % 10 === 0;
    })
    .test('payment-method', '¡Ups! Parece que no podemos procesar este tipo de tarjeta', function (value) {
      const paymentMethod = findPaymentMethodForCardNumber(value || '');
      return !!paymentMethod;
    })
    .required('El número de tarjeta es obligatorio'),
  card_name: yup
    .string()
    .required('El nombre del titular de la tarjeta es obligatorio'),
  card_expiry: yup
    .string()
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      'Fecha de vencimiento inválida (MM/YY)'
    )
    .required('Este campo es obligatorio')
    .test('expiration-date', 'La tarjeta está vencida', function (value) {
      if (!value) return false;
      const [month, year] = value.split('/');
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;
      if (parseInt(year) < currentYear) return false;
      if (parseInt(year) === currentYear && parseInt(month) < currentMonth)
        return false;
      return true;
    }),
  card_cvc: yup
    .string()
    .matches(/^\d{3,4}$/, 'El código CVC debe tener 3 o 4 números')
    .required('Este campo es obligatorio'),
});

export interface ICheckoutDataSchema extends yup.InferType<typeof checkoutDataSchema> {
  // using interface instead of type generally gives nicer editor feedback
}
