import { ReactElement } from 'react';
import { Flex, Heading, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/image';

interface ErrorStateProps {
  title: string;
  image: string | React.ReactNode;
  message: string;
}

export default function ErrorState({
  title,
  image,
  message,
}: ErrorStateProps): ReactElement {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap={5}
      textAlign="center"
    >
      {typeof image === 'string' ? (
        <Image src={image} alt={title} boxSize="200px" mb={4} />
      ) : (
        image
      )}
      <Heading as="h2" size="lg" mb={4}>
        {title}
      </Heading>
      <Text fontSize="lg">{message}</Text>
    </Flex>
  );
}
