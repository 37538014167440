import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Editable, EditablePreview, EditableInput } from '@chakra-ui/editable';
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { useFormikContext, FormikErrors } from 'formik';

import { NewVariant, PropertyChange, Variant } from '../../../types/product';
import Table from '../../../components/ui/Table/Table';
import { PRODUCT_STATUS } from '../constants';
import { IProductDataSchema } from './validationSchema';

interface VariantsTableProps {
  variants: NewVariant[];
  onSubmit: (properties: PropertyChange) => void;
}

const VariantsTable: React.FC<VariantsTableProps> = ({
  variants,
  onSubmit,
}) => {
  const { errors, touched, values, handleBlur, handleChange } =
    useFormikContext<IProductDataSchema>();

  const columns: ColumnDef<NewVariant>[] = [
    {
      accessorKey: 'title',
      header: 'Variante',
    },
    {
      accessorKey: 'stock',
      header: 'Stock',
      cell: (info) => {
        const value = info.getValue() as number;
        const rowIndex = parseInt(info.row.id, 10);
        return (
          <FormControl
            isRequired
            isInvalid={
              touched.variants?.[rowIndex]?.stock &&
              !!(errors.variants?.[rowIndex] as FormikErrors<Variant>)?.stock
            }
          >
            <Editable
              defaultValue={value.toString()}
              onChange={handleChange}
              onSubmit={(value) => {
                onSubmit({
                  index: rowIndex,
                  property: 'stock',
                  value: value,
                });
              }}
            >
              <EditablePreview />
              <Input
                as={EditableInput}
                name={`variants[${rowIndex}].stock`}
                type="number"
                min={0}
                value={values.variants[rowIndex].stock}
                onBlur={handleBlur}
                width={20}
              />
              <FormErrorMessage>
                {(errors.variants?.[rowIndex] as FormikErrors<Variant>)?.stock}
              </FormErrorMessage>
            </Editable>
          </FormControl>
        );
      },
    },
    {
      accessorKey: 'price',
      header: 'Precio',
      cell: (info) => {
        const value = info.getValue() as number;
        const rowIndex = parseInt(info.row.id, 10);
        return (
          <FormControl
            isRequired
            isInvalid={
              touched.variants?.[rowIndex]?.price &&
              !!(errors.variants?.[rowIndex] as FormikErrors<Variant>)?.price
            }
          >
            <Editable
              defaultValue={value.toString()}
              onChange={handleChange}
              onSubmit={(value) => {
                onSubmit({
                  index: rowIndex,
                  property: 'price',
                  value: value,
                });
              }}
            >
              <EditablePreview />
              <Input
                as={EditableInput}
                name={`variants[${rowIndex}].price`}
                type="number"
                min={0}
                value={values.variants[rowIndex].price}
                onBlur={handleBlur}
                width={20}
              />
              <FormErrorMessage>
                {(errors.variants?.[rowIndex] as FormikErrors<Variant>)?.price}
              </FormErrorMessage>
            </Editable>
          </FormControl>
        );
      },
    },
    {
      accessorKey: 'status',
      header: 'Estado',
      cell: (info) => {
        const value = info.getValue() as keyof typeof PRODUCT_STATUS;
        return PRODUCT_STATUS[value];
      },
    },
  ];

  const table = useReactTable({
    data: variants,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <Table table={table} />;
};

export default VariantsTable;
