import { Link as RouterLink } from 'react-router-dom';
import { Flex, Text, Link } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { IconType } from 'react-icons';

interface StepCardProps {
  ctaLabel: string;
  ctaLink: string;
  icon: IconType;
  message: string;
}

const StepCard: React.FC<StepCardProps> = ({
  ctaLabel,
  ctaLink,
  message,
  icon,
}) => {
  return (
    <Flex direction="column" alignItems="center" gap={{ base: 3, md: 5 }}>
      <Icon
        as={icon}
        boxSize="7rem"
        strokeWidth="1px"
        color="ui.copy"
        padding={3}
        bg="ui.light-gray"
        rounded="full"
      />
      <Text textAlign="center">{message}</Text>
      <Link as={RouterLink} to={ctaLink} variant="button">
        {ctaLabel}
      </Link>
    </Flex>
  );
};

export default StepCard;
