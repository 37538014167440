import React from 'react';
import {
  Avatar,
  Button,
  Box,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';

interface SellerInfoProps {
  seller: {
    name: string;
    showName: string;
    logo: string;
  };
}

const SellerInfo: React.FC<SellerInfoProps> = ({ seller }) => {
  const { showName, name, logo } = seller;
  return (
    <Grid
      templateRows="repeat(2, 1fr)"
      templateColumns="repeat(5, 1fr)"
      flexGrow="none"
    >
      <GridItem rowSpan={2} marginY="auto" pr="2">
        <Avatar name={logo ? showName : ''} src={logo} />
      </GridItem>
      <GridItem colSpan={4}>
        <Text color="white" noOfLines={1} fontWeight="semibold">
          {showName}
        </Text>
      </GridItem>
      <GridItem colSpan={4}>
        <Flex alignItems="center">
          <Box pr="4px">
            <Text color="white" fontSize="sm" noOfLines={1}>
              {name}
            </Text>
          </Box>
          <Box>
            <Button variant="tertiary" size="xs">
              Seguir
            </Button>
          </Box>
        </Flex>
      </GridItem>
    </Grid>
  );
};

export default SellerInfo;
