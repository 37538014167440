import { Skeleton } from '@chakra-ui/skeleton';

const CardSkeleton: React.FC = () => {
  return (
    <Skeleton
      height="160px"
      maxW={{ base: '100%', sm: '480px' }}
      borderRadius="md"
    />
  );
};

export default CardSkeleton;
