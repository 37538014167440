import { Container, Flex, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { TbExclamationCircle } from 'react-icons/tb';

const EmptyState: React.FC = () => {
  return (
    <Container>
      <Flex align="center" direction="column">
        <Icon as={TbExclamationCircle} boxSize={12} />
        <Text color="brand.green" fontSize="lg" fontWeight="bold" mt={8}>
          No se encontraron resultados
        </Text>
        <Text color="ui.copy" mt={4}>
          Prueba ajustando los filtros para encontrar lo que buscas.
        </Text>
      </Flex>
    </Container>
  );
};

export default EmptyState;
