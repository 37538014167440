import { useEffect, useState } from 'react';
import { IShow, IShowToken } from '../types/show';
import { IFetchResult, IPaginatedResult, fetchData } from './fetcher';
import { IProductStock } from '../types/product';

const LIST_SHOWS_URL = '/api/shows/';
const RETRIEVE_SHOW_AUDIENCE_TOKEN_URL = '/api/shows/:showId/audience-tokens/';
const RETRIEVE_SHOW_HOST_TOKEN_URL = '/api/shows/:showId/host-tokens/';
const RETRIEVE_SHOW_PRODUCTS = '/api/shows/:showId/products/';

/* {
id: 1,
name: "test-show-1",
description: "",
start_date: "2023-07-02T15:37:05Z",
company: 1,
host: 2,
}    */

export const useFetchShowAPI = (
  slugName: string
): IFetchResult<IPaginatedResult<IShow[]>> => {
  const [ignore, setIgnore] = useState(false);
  const [data, setData] = useState<IPaginatedResult<IShow[]>>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!slugName) {
      return;
    }
    setIsLoading(true);
    fetchData(LIST_SHOWS_URL, setData, setError, ignore, {
      slug_name: slugName,
      ordering: 'id',
    });
    setIsLoading(false);
    return () => {
      setIgnore(true);
    };
  }, [slugName, ignore]);

  return { data, error, isLoading };
};

export const useFetchShowAudienceTokenRemote = (
  showId: string | number
): IFetchResult<IShowToken> => {
  const [ignore, setIgnore] = useState(false);
  const [data, setData] = useState<IShowToken>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!showId) {
      return;
    }
    setIsLoading(true);
    fetchData(
      RETRIEVE_SHOW_AUDIENCE_TOKEN_URL.replace(':showId', String(showId)),
      setData,
      setError,

      ignore
    );
    setIsLoading(false);
    return () => {
      setIgnore(true);
    };
  }, [showId, ignore]);

  return { data, error, isLoading };
};

export const useFetchShowHostTokenAPI = (
  showId: string | number
): IFetchResult<IShowToken> => {
  const [ignore, setIgnore] = useState(false);
  const [data, setData] = useState<IShowToken>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!showId) {
      return;
    }
    setIsLoading(true);
    fetchData(
      RETRIEVE_SHOW_HOST_TOKEN_URL.replace(':showId', String(showId)),
      setData,
      setError,

      ignore
    );
    setIsLoading(false);
    return () => {
      setIgnore(true);
    };
  }, [showId, ignore]);

  return { data, error, isLoading };
};

export const useFetchShowProductStockAPI = (
  showId: string | number
): IFetchResult<IPaginatedResult<IProductStock[]>> => {
  const [ignore, setIgnore] = useState(false);
  const [data, setData] = useState<IPaginatedResult<IProductStock[]>>({
    previous: null,
    next: null,
    results: [],
  } as IPaginatedResult<IProductStock[]>);
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!showId) {
      return;
    }
    setIsLoading(true);
    fetchData(
      RETRIEVE_SHOW_PRODUCTS.replace(':showId', String(showId)),
      setData,
      setError,

      ignore,
      { is_visible: true }
    );
    return () => {
      setIgnore(true);
    };
  }, [ignore, showId]);

  return { data, error, isLoading };
};
