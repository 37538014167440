import { useMemo, useState } from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  RowSelectionState,
  SortingState,
  getCoreRowModel,
  getExpandedRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Icon } from '@chakra-ui/icon';
import { Box, Flex, Link, Text } from '@chakra-ui/layout';
import { TbChevronDown, TbChevronUp } from 'react-icons/tb';

import Table from '../../../components/ui/Table/Table';
import Filter from '../../../components/ui/Form/Filter';
import {
  Customer,
  Order,
  OrderStatus,
  ProductItem,
} from '../../../types/order';
import { formatDate } from '../../../utils/dates';
import { getStatusLabel, getStatusTagColor } from '../../../utils/orders';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import OrderStatusTag from './OrderStatusTag';
import { useUpdateOrderStatus } from '../../../queries/orders';
import { useAuth } from '../../../context/AuthContext';

interface OrdersTableProps {
  orders: Order[];
}



const OrdersTable: React.FC<OrdersTableProps> = ({ orders }) => {
  const { mutate: updateOrderStatus } = useUpdateOrderStatus();
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  const { user } = useAuth();

  const columns: ColumnDef<Order>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        accessorKey: 'id',
        header: 'Orden',
        cell: (info) => (
          <Link
            as={RouterLink}
            to={`${info.row.original.id}`}
            fontWeight="semibold"
          >
            #{info.getValue() as string}
          </Link>
        ),
      },
      {
        accessorKey: 'datetime',
        header: 'Fecha de pedido',
        cell: (info) =>
          formatDate(info.getValue() as string, {
            month: '2-digit',
          }),
      },
      {
        accessorKey: 'items',
        header: 'Productos',
        enableSorting: false,
        cell: ({ row, getValue }) => {
          const products = getValue() as ProductItem[];
          return (
            <>
              <Flex gap={2}>
                <Button
                  variant="link"
                  color="ui.link"
                  onClick={() => {
                    row.toggleExpanded();
                  }}
                >
                  <Flex gap={2} align="center">
                    {products.length}
                    {row.getIsExpanded() ? (
                      <Icon as={TbChevronUp} />
                    ) : (
                      <Icon as={TbChevronDown} />
                    )}
                  </Flex>
                </Button>
              </Flex>
            </>
          );
        },
      },
      {
        accessorKey: 'subtotal',
        header: 'Total',
        cell: (info) => formatPrice(info.getValue() as number),
      },
      {
        accessorKey: 'customer',
        header: 'Comprador',
        cell: (info) => {
          const customer = info.getValue() as Customer;
          return `${customer.firstName} ${customer.lastName}`;
        },
      },
      {
        accessorKey: 'status',
        header: 'Estado',
        cell: (info) => {
          const status = info.getValue() as OrderStatus;
          return (
            <OrderStatusTag
              status={getStatusLabel(status)}
              variant={getStatusTagColor(status)}
            />
          );
        },
      },
      {
        id: 'actions',
        header: 'Acciones',
        cell: ({ row }) => {
          if (row.original.status === 'pending_pickup') {
            return (
              <Button
                as={RouterLink}
                to={`${row.original.id}`}
                size="sm"
                variant="secondary"
                onClick={() => {
                  updateOrderStatus({
                    companyId: user!.companyId,
                    orderId: row.original.id,
                    status: 'delivered',
                  });
                }}
              >
                Marcar como entregado
              </Button>
            );
          }
        },
      },
    ],
    [updateOrderStatus]
  );

  const table = useReactTable({
    data: orders,
    columns,
    state: {
      columnFilters,
      expanded,
      rowSelection,
      sorting,
    },
    autoResetPageIndex: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    enableExpanding: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onExpandedChange: setExpanded,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    debugTable: true,
  });

  return (
    <Box>
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap-reverse"
        gap={2}
        py={5}
      >
        <Text fontSize="xl">
          Mostrando {table.getFilteredRowModel().rows.length} de{' '}
          {table.getPrePaginationRowModel().rows.length} ordenes
        </Text>
        <Flex w={{ base: 'full', md: 'auto' }}>
          <Filter
            column={table.getColumn('id')!}
            type="text"
            placeholder="Buscar orden"
          />
        </Flex>
      </Flex>
      <Table table={table} />
    </Box>
  );
};

export default OrdersTable;
