import {
  MutationOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ClientParams, client } from '../utils/api-client';
import { Category, IProduct } from '../types/product';
import { camelKeysToSnake, snakeKeysToCamel } from '../utils/transformations';
import { IProductDataSchema } from '../pages/Products/components/validationSchema';

const CATEGORIES_URL = 'categories/';

function useCategories(options?: ClientParams): UseQueryResult<Category[]> {
  return useQuery({
    queryKey: ['categories'],
    queryFn: () =>
      client(CATEGORIES_URL, {
        ...options,
      }).then((data) => data.results.map(snakeKeysToCamel)),
  });
}

const COMPANY_PRODUCT_URL = 'company/:companyId/products/:productId/';

function createProduct(
  productData: IProductDataSchema,
  companyId: number
): Promise<IProduct> {
  return client(COMPANY_PRODUCTS_URL.replace(':companyId', String(companyId)), {
    data: camelKeysToSnake(productData),
    method: 'POST',
  });
}

function useCreateProduct(
  companyId: number,
  options?: MutationOptions<IProductDataSchema, unknown, IProductDataSchema>
): UseMutationResult<IProductDataSchema, unknown, IProductDataSchema> {
  const queryClient = useQueryClient();
  return useMutation(
    (productData: IProductDataSchema) => createProduct(productData, companyId),
    { ...options, onSuccess: () => queryClient.invalidateQueries(['products']) }
  );
}

function deleteProduct(productId: number, companyId: number) {
  return client(
    COMPANY_PRODUCT_URL.replace(':companyId', String(companyId)).replace(
      ':productId',
      String(productId)
    ),
    {
      method: 'DELETE',
    }
  );
}

function useDeleteProduct(
  companyId: number,
  options?: MutationOptions<void, unknown, number>
): UseMutationResult<void, unknown, number> {
  const queryClient = useQueryClient();
  return useMutation(
    (productId: number) => deleteProduct(productId, companyId),
    { ...options, onSuccess: () => queryClient.invalidateQueries(['products']) }
  );
}

function updateProduct(
  productId: number,
  companyId: number,
  productData: IProductDataSchema
): Promise<IProduct> {
  return client(
    COMPANY_PRODUCT_URL.replace(':companyId', String(companyId)).replace(
      ':productId',
      String(productId)
    ),
    {
      data: camelKeysToSnake(productData),
      method: 'PATCH',
    }
  );
}

function useUpdateProduct(
  companyId: number,
  options?: MutationOptions<IProduct, unknown, IProduct>
): UseMutationResult<IProduct, unknown, IProduct> {
  const queryClient = useQueryClient();
  return useMutation(
    (productData: IProduct) =>
      updateProduct(productData.id, companyId, productData),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(['product', data.id], snakeKeysToCamel(data));
      },
      onSettled: (data) => {
        queryClient.invalidateQueries(['products']);
      },
      ...options,
    }
  );
}

function useProduct(
  productId: number,
  companyId: number,
  select?: (data: IProduct[]) => any,
  options?: ClientParams
): UseQueryResult<IProduct> {
  return useQuery({
    // @ts-ignore
    queryKey: ['product', productId],
    queryFn: () =>
      client(
        COMPANY_PRODUCT_URL.replace(':companyId', String(companyId)).replace(
          ':productId',
          String(productId)
        ),
        {
          ...options,
        }
      ).then((data: IProduct) => snakeKeysToCamel(data)),
    select,
  });
}

const COMPANY_PRODUCTS_URL = 'company/:companyId/products/';

function useProducts(
  companyId: number,
  select?: (data: IProduct[]) => any,
  options?: ClientParams
): UseQueryResult<IProduct[]> {
  return useQuery({
    queryKey: ['products', companyId],
    queryFn: () =>
      client(COMPANY_PRODUCTS_URL.replace(':companyId', String(companyId)), {
        ...options,
      }).then((data) => data.results.map(snakeKeysToCamel)),
    select,
  });
}

export {
  useCategories,
  useCreateProduct,
  useDeleteProduct,
  useUpdateProduct,
  useProduct,
  useProducts,
};
