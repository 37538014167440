import { useEffect } from 'react';
import { Box, AbsoluteCenter } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/react';

export default function RedirectStore() {
  const checkAppInstalled = () => {
    setTimeout(function () {
      // If the user is still on the same page, the app is not installed
      redirectToAppStore();
    }, 500);
  };

  const redirectToAppStore = () => {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      window.location.href = "https://play.google.com/store/apps/details?id=com.shotshop.live";
      return;
    } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
      window.location.href = "https://apps.apple.com/ar/app/shotshop/id6477988244";
      return;
    }

    window.location.href = "https://www.shotshop.live/";
  }

  useEffect(() => {
    checkAppInstalled();
  }, []);

  return (
    <Box h={'100vh'} background={"ui.bg"}>
      <AbsoluteCenter>
        <Spinner size="xl" color={"brand.primary.500"} />
      </AbsoluteCenter>
    </Box>
  );
}
