import { IconButton } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Box, Flex } from '@chakra-ui/layout';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { TbMenu2 } from 'react-icons/tb';
import NavLinks from './NavLinks';

const Sidebar: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box>
      <IconButton
        icon={<Icon as={TbMenu2} />}
        aria-label="Menu"
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
      />

      <Box display={{ base: 'none', md: 'block' }}>
        <Flex alignItems="center" h="6rem">
          <Image
            src="https://res.cloudinary.com/hdwylajhd/image/upload/v1707360606/assets/brand-logo1x.svg"
            alt="ShotShop logo"
            width="150px"
            objectFit="contain"
          />
        </Flex>
        <NavLinks />
      </Box>

      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              <Flex alignItems="center" h="6rem">
                <Image
                  src="https://res.cloudinary.com/hdwylajhd/image/upload/v1707360606/assets/brand-logo1x.svg"
                  alt="ShotShop logo"
                  width="150px"
                  objectFit="contain"
                />
              </Flex>
            </DrawerHeader>

            <DrawerBody>
              <NavLinks />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
