import React, {
  createContext,
  ReactNode,
  useContext,
  useRef,
  useState,
} from 'react';
import AlertDialog from '../components/ui/AlertDialog/AlertDialog';

type AlertDialogContextData = {
  isOpen: boolean;
  openAlertDialog: (alertDialogContent: ReactNode) => void;
  closeAlertDialog: () => void;
  cancelRef: any;
};

const AlertDialogContext = createContext<AlertDialogContextData | null>(null);

function useAlertDialog() {
  const context = useContext(AlertDialogContext);
  if (!context) {
    throw new Error(
      'useAlertDialog must be used within a AlertDialogProvider.'
    );
  }

  return context;
}

interface AlertDialogProviderProps {
  children: ReactNode;
}

const AlertDialogProvider: React.FC<AlertDialogProviderProps> = ({
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [alertDialogContent, setAlertDialogContent] =
    useState<ReactNode | null>(null);

  const cancelRef = useRef();

  const openAlertDialog = (alertDialogContent: ReactNode) => {
    setIsOpen(true);
    setAlertDialogContent(alertDialogContent);
  };
  const closeAlertDialog = () => {
    setIsOpen(false);
    setAlertDialogContent(null);
  };

  return (
    <AlertDialogContext.Provider
      value={{
        isOpen,
        openAlertDialog,
        closeAlertDialog,
        cancelRef,
      }}
      {...props}
    >
      <AlertDialog
        isOpen={isOpen}
        content={alertDialogContent}
        onClose={closeAlertDialog}
        cancelRef={cancelRef}
      />
      {children}
    </AlertDialogContext.Provider>
  );
};

export { AlertDialogProvider, useAlertDialog };
