import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Divider } from '@chakra-ui/layout';

import { useAlert } from '../../context/AlertContext';
import { useAuth } from '../../context/AuthContext';
import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useCompany, useUpdateCompany } from '../../queries/company';
import CompanyForm from './components/CompanyForm';
import { ICompany } from '../../types/company';

const EditProfile = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openAlert } = useAlert();
  const {
    data: company,
    isLoading,
    isError,
    error,
  } = useCompany(user!.companyId);
  const { mutate: updateCompany } = useUpdateCompany();

  const handleSubmit = async (values: ICompany) => {
    try {
      await updateCompany(values);
      navigate('/dashboard/profile');
    } catch (error) {
      console.error(error);
      openAlert({
        status: 'error',
        variant: 'left-accent',
        description: 'Error al editar el perfil. Inténtalo de nuevo.',
      });
    }
  };

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Flex
      direction="column"
      gap={{ base: 1, md: 3 }}
      w={{ base: '100%', md: '80%', xl: '60%' }}
    >
      <Heading as="h2" fontSize="3xl">
        Editar perfil
      </Heading>
      <Divider />
      {company && (
        <CompanyForm
          initialValues={company}
          onSubmit={handleSubmit}
        />
      )}
    </Flex>
  );
};

export default EditProfile;
