import { createContext, useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverridableTokenClientConfig } from '@react-oauth/google';

import { USER_LOCAL_STORAGE_KEY } from '../constants/localStorage';
import { useLocalStorage } from '../hooks/useLocalStorage';
import {
  getTokenClient,
  getUserData,
  getUserToken,
  logoutUser,
} from '../utils/auth';
import { UserData } from '../types/user';

const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

interface AuthContextProps {
  user: UserData | null;
  loginWithGoogle: (
    overrideConfig?: OverridableTokenClientConfig | undefined
  ) => void;
  loginWithCredentials: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage<UserData | null>(
    USER_LOCAL_STORAGE_KEY,
    null
  );
  const [tokenClient, setTokenClient] = useState<TokenClient | undefined>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const onSuccessLogin = async (response: GoogleAuthResponseData) => {
      const tokenData = await getTokenClient(response.access_token);

      const user = await getUserData({
        accessToken: tokenData.access_token,
        refreshToken: tokenData.refresh_token,
      });

      setUser(user as UserData);

      const origin = location.state?.from?.pathname || '/dashboard';
      navigate(origin);
    };

    if (!tokenClient && window.google?.accounts?.oauth2) {
      setTokenClient(
        window.google.accounts.oauth2.initTokenClient({
          client_id: googleClientId,
          scope: 'email profile openid',
          callback: onSuccessLogin,
        })
      );
    }
  }, [location.state?.from?.pathname, navigate, setUser, tokenClient]);

  const loginWithGoogle = (
    overrideConfig?: OverridableTokenClientConfig | undefined
  ) => {
    tokenClient?.requestAccessToken();
  };

  const loginWithCredentials = async (email: string, password: string) => {
    try {
      const tokenData = await getUserToken({ email, password });
      const user = await getUserData({
        accessToken: tokenData.access,
        refreshToken: tokenData.refresh,
      });
      setUser(user as UserData);
      navigate('/dashboard');
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;
    }
  };

  const logout = async () => {
    if (user) {
      try {
        await logoutUser(user?.token.accessToken);
        setUser(null);
        navigate('/login', { replace: true });
      } catch (error) {
        console.error('Error logging out', error);
        throw error;
      }
    }
  };

  return (
    <AuthContext.Provider
      value={{ user, loginWithGoogle, loginWithCredentials, logout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export { AuthContext, AuthProvider, useAuth };
