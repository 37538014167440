import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Button } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/layout';
import { initMercadoPago } from '@mercadopago/sdk-react';
import { createCardToken } from '@mercadopago/sdk-react/coreMethods';
import { CardTokenParams } from '@mercadopago/sdk-react/coreMethods/cardToken/types';

import CenteredSpinner from '../../components/ui/CenteredSpinner';
import {
  checkoutMobileSchema,
  ICheckoutMobileSchema,
} from './validationSchema';

const MP_TOKEN_TEST = 'TEST-cbfe243c-45a3-42f6-bd9d-a34bcf7df7f8';

// initMercadoPago("APP_USR-daac50d7-f72a-4262-89d5-e87d07cd8185");

export const CheckoutMobileScreen = () => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('mp_token_test') === 'true') {
      initMercadoPago(MP_TOKEN_TEST);
    } else {
      initMercadoPago(
        process.env.REACT_APP_MERCADOPAGO_PUBLIC_KEY ?? MP_TOKEN_TEST
      );
    }
  }, [searchParams]);

  const onTokenPress = async (values: ICheckoutMobileSchema) => {
    try {
      setLoading(true);
      let dniParam = searchParams.get('dni');
      const createTokenParams = {
        cardNumber: values.cardNumber?.trim()?.replace(/\s+/g, ''),
        cardExpirationMonth: values.cardExpiration?.slice(0, 2),
        cardExpirationYear: values.cardExpiration?.slice(3, 5),
      } as CardTokenParams;
      if (dniParam && createTokenParams) {
        createTokenParams.identificationType = 'DNI';
        createTokenParams.identificationNumber = dniParam;
      }
      const cardToken = await createCardToken({
        ...values,
        ...createTokenParams,
      });

      if (window['ReactNativeWebView' as any])
        window['ReactNativeWebView' as any].postMessage(
          JSON.stringify({ cardToken: cardToken })
        );
    } catch (error) {
      console.error('error', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <Formik
      initialValues={{
        cardNumber: '',
        cardholderName: '',
        securityCode: '',
        cardExpiration: '',
      }}
      onSubmit={onTokenPress}
      validationSchema={checkoutMobileSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
      }: FormikProps<ICheckoutMobileSchema>) => {
        const handleCardExpirationChange = (
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          let { value } = event.target;
          if (value.length === 2 && !value.includes('/')) {
            value += '/';
          }
          setFieldValue('cardExpiration', value);
        };

        return (
          <Box padding={5}>
            <Box py={4}>
              <Text fontWeight="bold" color="brand.green">
                Tarjeta de crédito y débito
              </Text>
            </Box>
            <Form>
              <Grid
                templateColumns="repeat(4, 1fr)"
                bgColor="ui.bg"
                p={5}
                rowGap={5}
                borderRadius={8}
              >
                <GridItem colSpan={4}>
                  <FormControl
                    isRequired={true}
                    isInvalid={!!errors.cardNumber && touched.cardNumber}
                  >
                    <FormLabel>N&uacute;mero de tarjeta</FormLabel>
                    <Input
                      name="cardNumber"
                      value={values.cardNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Número tarjeta"
                      variant="flushed"
                    />
                    <FormErrorMessage>{errors.cardNumber}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <FormControl
                    isRequired={true}
                    isInvalid={
                      !!errors.cardholderName && touched.cardholderName
                    }
                  >
                    <FormLabel>Nombre del titular</FormLabel>
                    <Input
                      name="cardholderName"
                      value={values.cardholderName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Nombre del titular"
                      variant="flushed"
                    />
                    <FormErrorMessage>{errors.cardholderName}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl
                    isRequired={true}
                    isInvalid={
                      !!errors.cardExpiration && touched.cardExpiration
                    }
                  >
                    <FormLabel>V&aacute;lida hasta</FormLabel>
                    <Input
                      name="cardExpiration"
                      value={values.cardExpiration}
                      onChange={handleCardExpirationChange}
                      onBlur={handleBlur}
                      placeholder="MM/AA"
                      variant="flushed"
                    />
                    <FormErrorMessage>{errors.cardExpiration}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl
                    isRequired={true}
                    isInvalid={!!errors.securityCode && touched.securityCode}
                  >
                    <FormLabel>CCV</FormLabel>
                    <Input
                      name="securityCode"
                      value={values.securityCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="CCV"
                      variant="flushed"
                    />
                    <FormErrorMessage>{errors.securityCode}</FormErrorMessage>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={4}>
                  <Flex>
                    <Button flexGrow={1} type="submit">
                      Continuar
                    </Button>
                  </Flex>
                </GridItem>
              </Grid>
            </Form>
          </Box>
        );
      }}
    </Formik>
  );
};
