import React, { createContext, ReactNode, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Flex } from '@chakra-ui/layout';
import { useTheme } from '@chakra-ui/system';

import Toast, { ToastProps } from '../components/ui/Toast/Toast';

type TToast = ToastProps & { id: number };

type ToastData = {
  open: (toastProps: OpenToastParams) => void;
  close: (id: number) => void;
};

const ToastContext = createContext<ToastData | null>(null);

function useToast() {
  const context = useContext(ToastContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider.');
  }
  return context;
}

interface ToastProviderProps {
  children: ReactNode;
}
type OpenToastParams = ToastProps & { duration?: number };

const ToastProvider: React.FC<ToastProviderProps> = ({
  children,
  ...props
}) => {
  const [toasts, setToasts] = useState<TToast[]>([]);
  const theme = useTheme();
  const open = (toastProps: OpenToastParams) => {
    const id = Date.now();
    setToasts((toasts) => [...toasts, { id, ...toastProps }]);
    setTimeout(() => close(id), toastProps.duration || 5000);
  };

  const close = (id: number) => {
    setToasts((toasts) => toasts.filter((toast) => toast.id !== id));
  };

  const toastRoot = document.getElementById('toast-root');

  return (
    <ToastContext.Provider value={{ open, close }} {...props}>
      {children}
      {toastRoot?.parentNode
        ? ReactDOM.createPortal(
            toasts.length ? (
              <Flex position="fixed" direction="column" zIndex={2} bottom={4} right={4} gap={2}>
                {toasts.map(({ id, ...toastProps }) => (
                  <Box
                    as="output"
                    key={id}
                    animation={`${theme.keyframes.slideUp} 0.3s ease-in-out`}
                  >
                    <Toast {...toastProps} onClose={() => close(id)} />
                  </Box>
                ))}
              </Flex>
            ) : null,
            toastRoot
          )
        : null}
    </ToastContext.Provider>
  );
};

export { useToast, ToastProvider };
