import { useEffect, useState } from 'react';
import { BuilderComponent, builder, useIsPreviewing } from '@builder.io/react';
import '@builder.io/widgets';

builder.init(process.env.REACT_APP_BUILDER_API_KEY);

export default function StaticPages() {
  const isPreviewingInBuilder = useIsPreviewing();
  const [notFound, setNotFound] = useState(false);
  const [content, setContent] = useState(null);

  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('shot-shop', { url: window.location.pathname })
        .promise();

      setContent(content);
      setNotFound(!content);

      if (content?.data.title) {
        document.title = content.data.title;
      }
    }
    fetchContent();
  }, []);

  if (notFound && !isPreviewingInBuilder) {
    return <div>Not Found</div>;
  }

  return (
    <>
      <BuilderComponent model="shot-shop" content={content} />
    </>
  );
}
