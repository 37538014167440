import { AspectRatio, Flex, Image, Text } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

interface ProductDescriptionProps {
  image: string;
  name: string;
  url?: string;
}

const ProductDescription: React.FC<ProductDescriptionProps> = ({
  image,
  name,
  url,
}) => {
  return (
    <Flex alignItems="center" gap="2">
      <AspectRatio ratio={1} w={24}>
        <Image
          src={image}
          alt={name}
          width="70px"
          borderRadius="lg"
          fallbackSrc="https://via.placeholder.com/70"
        />
      </AspectRatio>
      {url ? <Link to={url}>{name}</Link> : <Text>{name}</Text>}
    </Flex>
  );
};

export default ProductDescription;
