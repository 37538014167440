import {
  Box,
  Flex,
  Stack,
  Text,
  useColorModeValue,
  Heading,
} from '@chakra-ui/react';
import * as React from 'react';

import { IconChecklist } from '@tabler/icons-react';


export const OrderConfirmation = () => {
  return (
    <Box
      bgGradient={useColorModeValue(
        'linear(to-l, gray.50 50%, white 50%)',
        'linear(to-l, gray.700 50%, gray.800 50%)'
      )}
    >
      <Flex
        align="center"
        justify={{ base: 'center', md: 'space-around', xl: 'space-between' }}
        direction={{ base: 'column-reverse', md: 'row' }}
        wrap="nowrap"
        minH="70vh"
        px={8}
        mb={16}
      >
        <Stack
          spacing={4}
          w={{ base: '80%', md: '40%' }}
          align={['center', 'center', 'flex-start', 'flex-start']}
        >
          <IconChecklist size="60"/>
          <Heading
            as="h1"
            size="lg"
            fontWeight="bold"
            color="brand.red"
            textAlign={['center', 'center', 'left', 'left']}
          >
            ¡Compra confirmada!
          </Heading>
          <Text
            as="h2"
            size="sm"
            opacity="0.8"
            fontWeight="normal"
            lineHeight={1.5}
            textAlign={['center', 'center', 'left', 'left']}
          >
            Recibirás la información de la compra en un email para rastrear el
            envío.
          </Text>
        </Stack>
      </Flex>
    </Box>
  );
};
