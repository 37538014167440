import { Box, Flex } from '@chakra-ui/layout';

const Contact = () => {
  return (
    <Flex direction="column" as="section" h="full">
      <Box fontWeight="bold">Proximamente</Box>
    </Flex>
  );
}
export default Contact;
