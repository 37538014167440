import { Link } from 'react-router-dom';
import { Avatar, AvatarBadge } from '@chakra-ui/avatar';
import { Icon } from '@chakra-ui/icon';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { Flex, Link as ChakraLink } from '@chakra-ui/layout';
import { TbLogout, TbSettings, TbUser } from 'react-icons/tb';

import { useAuth } from '../../../context/AuthContext';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { user, logout } = useAuth();

  return (
    <Flex align="center" justify="flex-end" h="full" bg="ui.bg">
      {user ? (
        <Menu>
          <MenuButton aria-label="Opciones">
            <Avatar name={`${user.firstName} ${user.lastName}`} size="md">
              <AvatarBadge
                boxSize="0.65em"
                bg="brand.primary.500"
                top={0}
                border="none"
              />
            </Avatar>
          </MenuButton>
          <MenuList>
            <MenuItem
              as={Link}
              to="profile"
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Icon as={TbUser} />
              Perfil de usuario
            </MenuItem>
            <MenuItem
              as={Link}
              to="settings"
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Icon as={TbSettings} />
              Configuraci&oacute;n
            </MenuItem>
            <MenuItem
              onClick={logout}
              display="flex"
              alignItems="center"
              gap={2}
            >
              <Icon as={TbLogout} />
              Cerrar sesión
            </MenuItem>
          </MenuList>
        </Menu>
      ) : (
        <ChakraLink as={Link} to="/login">
          Ingresar
        </ChakraLink>
      )}
    </Flex>
  );
};

export default Header;
