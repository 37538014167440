import React from 'react';
import { Avatar, Box, Flex, Text } from '@chakra-ui/react';

interface ViewerMessageProps {
  author: string;
  avatar: string;
  highlighted: boolean;
  message: string;
}

const ViewerMessage: React.FC<ViewerMessageProps> = ({
  author,
  avatar,
  highlighted,
  message,
}) => {
  return (
    <Flex alignItems="center">
      <Avatar
        name={author}
        border={highlighted ? '2px solid #C1121F' : 'current'}
        src=""
        size="sm"
      />
      <Box padding="8px">
        <Text color="white" textShadow="0px 2px 4px rgba(0, 0, 0, 0.5)">
          {author}
        </Text>
        <Text color="white" textShadow="0px 2px 4px rgba(0, 0, 0, 0.5)">
          {message}
        </Text>
      </Box>
    </Flex>
  );
};

export default ViewerMessage;
