import { getCSRFToken } from '../cookie';

export interface IFetchResult<T> {
  data: T | undefined;
  error: any;
  isLoading: boolean;
}

export interface IPaginatedResult<T> {
  previous: string | null;
  next: string | null;
  results: T;
}

export const fetchData = async (
  url: string,
  setData: any,
  setError: any,
  ignore: boolean,
  data?: Record<string, any> | null
) => {
  const body = new URLSearchParams();
  if (data) {
    Object.keys(data).forEach((key) => {
      body.append(key, data[key]);
    });
    url = `${url}?${body.toString()}`;
  }
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();
    if (response.ok && !ignore) {
      setData(json);
    } else if (!ignore) {
      setError(json);
    }
  } catch (err) {
    if (err instanceof Error) {
      // ✅ TypeScript knows err is Error
      setError(err.message);
    } else {
      console.log('Unexpected error', err);
    }
  }
};

export const pushData = async (
  url: string,
  data: any,
  setData: any,
  setError: any,
  setLoading: any,
  ignore: boolean,
  onSuccess: (result: any) => void,
  onError: (errors: any) => void
) => {
  const token = getCSRFToken() || '';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': token,
      },
      body: JSON.stringify(data),
    });
    const json = await response.json();
    if (response.ok && !ignore) {
      setData(json);
      onSuccess(json);
    } else if (!ignore) {
      setError(json);
      onError(json);
    }
  } catch (err) {
    if (err instanceof Error) {
      // ✅ TypeScript knows err is Error
      setError(err.message);
    } else {
      console.log('Unexpected error', err);
    }
  } finally {
    setLoading(false);
  }
};
