import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, GridItem } from '@chakra-ui/layout';

import { useAuth } from '../../context/AuthContext';
import { useAlert } from '../../context/AlertContext';
import { usePendingShippingOrdersCount } from '../../queries/company';
import Sidebar from '../ui/Sidebar/Sidebar';
import Header from '../ui/Header/Header';

const DashboardLayout = () => {
  const { user } = useAuth();
  const { openAlert } = useAlert();
  const { data: pendingShippingOrdersCount } = usePendingShippingOrdersCount(
    user!.companyId
  );

  useEffect(() => {
    if (pendingShippingOrdersCount) {
      openAlert({
        status: 'warning',
        description: `Tienes ${pendingShippingOrdersCount} ${
          pendingShippingOrdersCount > 1
            ? 'órdenes pendientes'
            : 'orden pendiente'
        } de despachar. Imprime las etiquetas para despacharlas cuanto antes!`,
      });
    }
  }, [openAlert, pendingShippingOrdersCount]);

  return (
    <Grid
      bg="ui.bg"
      templateAreas={{
        base: `
      "sidebar header"
      "main main"
    `,
        md: `
    "sidebar header"
    "sidebar main"
  `,
      }}
      templateRows={{ base: '60px 1fr', md: '6rem 1fr' }}
      templateColumns={{ base: '50px 1fr', md: '13rem 1fr' }}
      minHeight="100vh"
      px={{ base: 3, md: 8 }}
      pb={{ base: 3, md: 8 }}
    >
      <GridItem area="header" as="header">
        <Header />
      </GridItem>
      <GridItem
        area="sidebar"
        as="aside"
        display={{ base: 'flex', md: 'block' }}
        alignItems={{ base: 'center', md: 'flex-start' }}
      >
        <Sidebar />
      </GridItem>
      <GridItem area="main" as="main" py={{ base: 3, md: 0 }}>
        <Outlet />
      </GridItem>
    </Grid>
  );
};

export default DashboardLayout;
