import { useAuth } from '../../../context/AuthContext';
import { useProducts } from '../../../queries/products';
import { useUpdateProductVisibility } from '../../../queries/shows';
import { IProduct } from '../../../types/product';
import { IShow, ShowProduct } from '../../../types/show';
import LiveShowProductsTable from './LiveShowProductsTable';

interface ShowProductsSectionProps {
  show: IShow;
  showProducts: ShowProduct[];
}

const ShowProductsSection: React.FC<ShowProductsSectionProps> = ({
  show,
  showProducts,
}) => {
  const { user } = useAuth();
  const { data: products } = useProducts(user!.companyId);
  const { mutate: updateProductVisibility, isLoading } =
    useUpdateProductVisibility(show);

  const combinedProducts: (IProduct & ShowProduct)[] = [];

  showProducts.forEach((product: ShowProduct) => {
    const productData: IProduct | undefined = products?.find(
      (p: IProduct) => p.id === product.productId
    );

    if (productData) {
      combinedProducts.push({
        ...product,
        ...productData,
      });
    }
  });

  const handleVisibilityChange = (productId: number, visible: boolean) => {
    return updateProductVisibility({ productId, visible });
  };

  return (
    <LiveShowProductsTable
      isSwitchDisabled={isLoading || show.status === 'ended'}
      products={combinedProducts}
      onVisibilityChange={handleVisibilityChange}
    />
  );
};

export default ShowProductsSection;
