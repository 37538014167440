import { IProductStock } from '../../../types/product';
import { IShow } from '../../../types/show';
import {
  Box,
  Button,
  Image,
  HStack,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { PriceTag } from '../../ui/ProductMeta/PriceTag';
import { QuantityStepper } from '../../ui/QuantityStepper/QuantityStepper';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { useAvailableProductStock } from '../../../hooks/useAvailbleProductStock';

interface IProductDetailProps {
  show: IShow;
  productStock: IProductStock;
  onAddedToCart?: (productId: number, quantity: number) => void;
}

export const ProductDetail = ({
  show,
  productStock,
  onAddedToCart,
}: IProductDetailProps) => {
  const { getCartItems, increaseCartQuantity } = useShoppingCart();
  const cartItems = getCartItems(show.id);
  const { productAvailableStock } = useAvailableProductStock({
    productStock,
    cartItems,
  });
  console.log(productAvailableStock);

  const [quantity, setQuantity] = React.useState(
    Math.min(1, productAvailableStock)
  );

  const handleAddToCart = () => {
    increaseCartQuantity(show.id, productStock.product.id, quantity);
    if (typeof onAddedToCart === 'function') {
      onAddedToCart(productStock.id, quantity);
    }
  };

  const {
    product: { name, description, images },
    price,
  } = productStock;
  return (
    <Box
      maxW="7xl"
      mx="auto"
      px={{ base: '4', md: '8', lg: '12' }}
      py={{ base: '6', md: '8', lg: '12' }}
    >
      <Stack
        direction={{ base: 'column-reverse', lg: 'row' }}
        spacing={{ base: '6', lg: '12', xl: '16' }}
      >
        <Stack
          spacing={{ base: '6', lg: '8' }}
          maxW={{ lg: 'sm' }}
          justify="center"
        >
          <Stack spacing={{ base: '3', md: '4' }}>
            <HStack>
              <Text fontWeight="medium">{name}</Text>
              <PriceTag
                price={price}
                currency="ARS"
                rootProps={{ fontSize: 'xl' }}
              />
            </HStack>
            <Text color={useColorModeValue('gray.600', 'gray.400')}>
              {description}
            </Text>
          </Stack>
          <HStack
            spacing={{ base: '4', md: '8' }}
            align="flex-end"
            justify="space-evenly"
          >
            <Box flex="1">
              {productAvailableStock > 0 ? (
                <QuantityStepper
                  quantity={quantity}
                  max={productAvailableStock}
                  onChange={(quantity) => setQuantity(quantity)}
                />
              ) : (
                <Text color="red.500">Sin stock</Text>
              )}
            </Box>
          </HStack>
          <Button
            colorScheme="blue"
            size="lg"
            isDisabled={productAvailableStock <= 0}
            onClick={handleAddToCart}
          >
            Agregar al carrito
          </Button>
        </Stack>
        <Image
          rounded="lg"
          boxSize="100px"
          minWidth="100px"
          minHeight="100px"
          fit="contain"
          src={images[0]}
          alt={name}
          draggable="false"
          loading="lazy"
        />
      </Stack>
    </Box>
  );
};
