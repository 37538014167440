import { Attribute, NewVariant, ProductStatus } from '../types/product';

/**
 * Generates an array of product variants based on the given attributes and options.
 * @param attributes An array of attributes to generate variants for.
 * @param options An optional object containing default values for price, stock, and status.
 * @returns An array of new variants.
 */
function generateVariants(
  attributes: Attribute[],
  options: {
    defaultPrice?: number;
    defaultStock?: number;
  } = {}
): NewVariant[] {
  const { defaultPrice, defaultStock } = options;

  if (attributes.length === 0) {
    return [
      {
        price: defaultPrice || 0,
        stock: defaultStock || 0,
        title: 'Variante unica',
        attributes: [],
      },
    ];
  }

  const attributeNames = attributes.map((attr) => attr.name);

  const combinations: string[][] = attributes.reduce(
    (acc: string[][], attribute) => {
      const newCombinations: string[][] = [];

      if (acc.length === 0) {
        return attribute.values.map((value) => [value]);
      }

      acc.forEach((combination) => {
        attribute.values.forEach((value) => {
          newCombinations.push([...combination, value]);
        });
      });

      return newCombinations;
    },
    []
  );

  const variants = combinations.map((combination) => {
    const title = combination.join('-');
    return {
      price: defaultPrice || 0,
      stock: defaultStock || 0,
      title,
      attributes: attributeNames.map((name, index) => ({
        name,
        value: combination[index],
      })),
    };
  });

  return variants;
}

/**
 * Returns the label corresponding to the given product status.
 *
 * @param status - The status of the product.
 * @returns The label corresponding to the product status.
 */
function getProductStatusLabel(status: ProductStatus): string {
  switch (status) {
    case 'active':
      return 'Activo';
    case 'paused':
      return 'Pausado';
    case 'discontinued':
      return 'Discontinuado';
    default:
      return 'Desconocido';
  }
}

/**
 * Returns the color associated with a given product status.
 * @param status - The status of the product.
 * @returns The color associated with the product status.
 */
function getProductStatusTagColor(
  status: ProductStatus
): 'success' | 'alert' | 'danger' {
  switch (status) {
    case 'active':
      return 'success';
    case 'paused':
      return 'alert';
    case 'discontinued':
      return 'danger';
    default:
      return 'alert';
  }
}

/**
 * Removes an attribute value from an array of attributes.
 * @param attributes - The array of attributes to remove the value from.
 * @param attributeName - The name of the attribute to remove the value from.
 * @param attributeValue - The value to remove from the attribute.
 * @returns A new array of attributes with the specified value removed.
 */
function removeAttributeValue(
  attributes: Attribute[],
  {
    attributeName,
    attributeValue,
  }: { attributeName: string; attributeValue: string }
): Attribute[] {
  return attributes
    .map((attr) => {
      if (attr.name === attributeName) {
        return {
          ...attr,
          values: attr.values.filter((value) => value !== attributeValue),
        };
      }
      return attr;
    })
    .filter((attr) => attr.values.length > 0);
}

export {
  getProductStatusLabel,
  getProductStatusTagColor,
  generateVariants,
  removeAttributeValue,
};
