import React from 'react';
import { IAgoraRTCRemoteUser, RemoteVideoTrack } from 'agora-rtc-react';
import { AspectRatio } from '@chakra-ui/react';

const VideoPlayer = (props: { user: IAgoraRTCRemoteUser }) => {
  const { user } = props;

  return (
    user &&
    user.videoTrack && (
      <AspectRatio height="100%" display="flex" ratio={9 / 16}>
        <RemoteVideoTrack track={user.videoTrack} key={user.uid} play />
      </AspectRatio>
    )
  );
};

export default VideoPlayer;
