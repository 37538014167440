import React, { useEffect, useRef } from 'react';
import {
  Box,
  Flex,
  Link,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FaArrowUp } from 'react-icons/fa';
import ViewerMessage from './ViewerMessage';

import { IAgoraMessage } from '../../../hooks/useAgoraRtm';

interface ViewerChatProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  message: string;
  messages: IAgoraMessage[];
  username: string;
}

const ViewerChat: React.FC<ViewerChatProps> = ({
  onChange,
  onSubmit,
  message,
  messages,
  username,
}): JSX.Element => {
  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLHeadingElement>(null);
    useEffect(() => elementRef?.current?.scrollIntoView());
    return elementRef !== undefined && <div ref={elementRef} />;
  };
  return (
    <Flex direction="column">
      <Box maxH="33vh" overflowY="scroll">
        {messages.map(({ message, user }: IAgoraMessage, index: any) => (
          <ViewerMessage
            key={index}
            highlighted={user.name === username ? true : false}
            // TODO: replace with user avatar
            avatar=""
            author={user.name}
            message={message}
          />
        ))}
        <AlwaysScrollToBottom />
      </Box>
      <form onSubmit={onSubmit}>
        <Flex>
          <InputGroup>
            <Input
              bg="rgba(53,57,64, 0.55)"
              borderRadius="18px"
              color="white" // TODO: replace with proper color from the palette
              onChange={onChange}
              placeholder="Agrega un comentario"
              _placeholder={{ opacity: 1, color: 'white' }}
              value={message}
            />
            <InputRightElement>
              <IconButton
                aria-label="Enviar"
                bg="rgba(53,57,64, 0.25)"
                borderRadius="50%"
                color="white"
                icon={<FaArrowUp />}
                type="submit"
              />
            </InputRightElement>
          </InputGroup>
        </Flex>
      </form>
    </Flex>
  );
};

export default ViewerChat;
