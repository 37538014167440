import { Button } from '@chakra-ui/button';
import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/modal';
import { Text } from '@chakra-ui/layout';

interface StartLiveModalProps {
  onClose: () => void;
  onStartClick: () => void;
}

const StartLiveModal: React.FC<StartLiveModalProps> = ({
  onClose,
  onStartClick,
}): JSX.Element => {
  return (
    <>
      <ModalHeader>Iniciar show</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>
          ¿Quieres iniciar el show? Esto notificará a todos los usuarios.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button variant="secondary" onClick={onClose}>
          No, solo quiero probar
        </Button>
        <Button colorScheme="red" onClick={onStartClick} ml={3}>
          Si, iniciar!
        </Button>
      </ModalFooter>
    </>
  );
};

export default StartLiveModal;
