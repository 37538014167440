import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  // getPaginationRowModel,
  getSortedRowModel,
  // PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { IconButton } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { TbEdit, TbTrash } from 'react-icons/tb';

import { useDeleteProduct } from '../../../queries/products';
import { useAuth } from '../../../context/AuthContext';
import { useAlertDialog } from '../../../context/AlertDialogContext';
import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal';
import Filter from '../../../components/ui/Form/Filter';
import Table from '../../../components/ui/Table/Table';
import { formatPrice } from '../../../components/ui/ProductMeta/PriceTag';
import ProductDescription from '../../../components/ui/Table/ProductDescription';
import ProductStatus from './ProductStatus';
import { IProduct, ProductStatus as TProductStatus } from '../../../types/product';
import VariantTag from './VariantTag';
import { getProductStatusLabel, getProductStatusTagColor } from '../../../utils/products';
// import Pagination from '../../../components/ui/Table/Pagination';

interface Props {
  products: IProduct[];
}

const ProductsTable: React.FC<Props> = ({ products }) => {
  const { user } = useAuth();
  const { mutate: deleteProduct } = useDeleteProduct(user!.companyId);
  const { closeAlertDialog, openAlertDialog, cancelRef } = useAlertDialog();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [sorting, setSorting] = useState<SortingState>([]);
  // const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
  //   pageIndex: 0,
  //   pageSize: 10,
  // });

  // const pagination = React.useMemo(
  //   () => ({
  //     pageIndex,
  //     pageSize,
  //   }),
  //   [pageIndex, pageSize]
  // );
  const handleDeleteProduct = async (productId: number) => {
    await deleteProduct(productId);
    closeAlertDialog();
  };

  const handleCancel = () => {
    closeAlertDialog();
  };

  const columns: ColumnDef<IProduct>[] = useMemo(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            isChecked={row.getIsSelected()}
            isDisabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
          />
        ),
      },
      {
        header: 'Producto',
        accessorKey: 'name',
        cell: (info) => {
          return (
            <ProductDescription
              name={info.getValue() as string}
              image={info.row.original.images[0]}
              url={`/dashboard/products/${info.row.original.id}/edit`}
            />
          );
        },
      },
      {
        header: 'Variantes',
        accessorKey: 'attributes',
        cell: (info) => {
          const attributes = info.getValue() as IProduct['attributes'];
          return (
            <Flex direction="column" gap={2}>
              {attributes.map((attribute) => (
                <Flex key={attribute.name} alignItems="center" gap={2}>
                  {attribute.values.map((value) => (
                    <VariantTag key={value} label={value} />
                  ))}
                </Flex>
              ))}
            </Flex>
          );
        },
      },
      {
        header: 'Stock',
        accessorFn: (row) =>
          row.variants.reduce((acc, variant) => {
            return acc + variant.stock;
          }, 0),
      },
      {
        header: 'Precio',
        accessorFn: (row) => row.variants[0]?.price,
        cell: (info) => formatPrice(info.getValue() as number),
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        cell: (info) => {
          const status = info.getValue() as TProductStatus;
          return <ProductStatus label={getProductStatusLabel(status)} variant={getProductStatusTagColor(status)} />;
        },
      },
      {
        id: 'actions',
        cell: (info) => {
          const productId = info.row.original.id;
          return (
            <Flex gap="2">
              <Link to={`${productId}/edit`}>
                <IconButton
                  aria-label="Editar producto"
                  icon={<TbEdit />}
                  size="sm"
                  bgColor="transparent"
                  color="ui.light-gray"
                  _hover={{
                    backgroundColor: 'ui.medium-gray',
                    color: 'white',
                  }}
                />
              </Link>
              <IconButton
                aria-label="Eliminar producto"
                icon={<TbTrash />}
                size="sm"
                bgColor="transparent"
                color="ui.light-gray"
                _hover={{
                  backgroundColor: 'ui.medium-gray',
                  color: 'white',
                }}
                onClick={() =>
                  openAlertDialog(
                    <DeleteConfirmationModal
                      title="Eliminar producto"
                      onDelete={() => handleDeleteProduct(productId)}
                      onClose={handleCancel}
                      cancelRef={cancelRef}
                    />
                  )
                }
              />
            </Flex>
          );
        },
      },
    ],
    [deleteProduct]
  );

  const table = useReactTable({
    data: products,
    columns,
    state: {
      columnFilters,
      rowSelection,
      // pagination,
      sorting,
    },
    autoResetPageIndex: true,
    enableRowSelection: true,
    enableMultiRowSelection: true,
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    // getFacetedMinMaxValues: getFacetedMinMaxValues(),
    getFilteredRowModel: getFilteredRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // manualPagination: true,
    // onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    // pageCount:  // add pageCount based on API response
  });

  return (
    <Box>
      <Flex
        justify="space-between"
        align="center"
        wrap="wrap-reverse"
        gap={3}
        py={5}
      >
        <Text fontSize="xl">
          Mostrando {table.getRowModel().rows.length} productos
        </Text>
        <Flex w={{base: 'full', md: 'auto'}}>
          <Filter
            column={table.getColumn('name')!}
            type="text"
            placeholder="Buscar producto"
          />
        </Flex>
      </Flex>
      <Table table={table} />
      {/* <Pagination
      canPreviousPage={table.getCanPreviousPage()}
      canNextPage={table.getCanNextPage()}
      currentPage={table.getState().pagination.pageIndex + 1}
      totalPages={table.getPageCount()}
      onNextPage={table.nextPage}
      onPreviousPage={table.previousPage}
      onPageChange={table.setPageIndex}
      onPageSizeChange={table.setPageSize}
      pageSize={table.getState().pagination.pageSize}
    /> */}
    </Box>
  );
};

export default ProductsTable;
