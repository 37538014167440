import {
  MutationOptions,
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { ClientParams, client } from '../utils/api-client';
import { IShow } from '../types/show';
import { camelKeysToSnake, snakeKeysToCamel } from '../utils/transformations';
import { IShowDataSchema } from '../pages/Shows/components/validationSchema';
import { useAlert } from '../context/AlertContext';

const COMPANY_SHOWS_URL = 'company/:companyId/shows/';

function createShow(
  companyId: number,
  showData: IShowDataSchema
): Promise<IShow> {
  return client(COMPANY_SHOWS_URL.replace(':companyId', String(companyId)), {
    data: camelKeysToSnake(showData),
    method: 'POST',
  });
}

function useCreateShow(
  companyId: number,
  options?: MutationOptions<IShow, unknown, IShowDataSchema>
): UseMutationResult<IShow, unknown, IShowDataSchema> {
  const queryClient = useQueryClient();
  return useMutation((showData) => createShow(companyId, showData), {
    ...options,
    onSuccess: () => queryClient.invalidateQueries(['shows']),
  });
}

type SearchParams = {
  page?: number;
  pageSize?: number;
  search?: string;
  status?: string;
  slugName?: string;
};

function fetchShows(
  companyId: number,
  searchParams?: SearchParams
): Promise<IShow[]> {
  return client(COMPANY_SHOWS_URL.replace(':companyId', String(companyId)), {
    queryParams: searchParams,
  }).then((data) => {
    return data?.results?.map((show: IShow) => snakeKeysToCamel(show));
  });
}

function useShows(
  companyId: number,
  options?: ClientParams,
  select?: (data: IShow[]) => any
): UseQueryResult<IShow[]> {
  return useQuery({
    queryKey: ['shows'],
    queryFn: () => fetchShows(companyId, options?.queryParams),
    select,
  });
}

function useShowBySlugname(companyId: number, slugName: string) {
  return useQuery({
    queryKey: ['showBySlug', slugName],
    queryFn: async () => {
      const shows = await fetchShows(companyId, { slugName: slugName });
      if (!shows || shows.length === 0) {
        throw new Error(`No se encontró un show con slugname: ${slugName}`);
      }
      return shows[0];
    },
  });
}

const COMPANY_SHOW_URL = 'company/:companyId/shows/:showId/';

function getShowById(companyId: number, showId: number) {
  return client(
    COMPANY_SHOW_URL.replace(':companyId', String(companyId)).replace(
      ':showId',
      String(showId)
    )
  ).then((data) => snakeKeysToCamel(data));
}

function useShowById(companyId: number, showId: number): UseQueryResult<IShow> {
  return useQuery({
    queryKey: ['show', showId],
    queryFn: () => getShowById(companyId, showId),
  });
}

function deleteShow(showId: number, companyId: number) {
  return client(
    COMPANY_SHOW_URL.replace(':companyId', String(companyId)).replace(
      ':showId',
      String(showId)
    ),
    {
      method: 'DELETE',
    }
  );
}

function useDeleteShow(
  companyId: number,
  options?: MutationOptions<IShow, unknown, number>
): UseMutationResult<IShow, unknown, number> {
  const queryClient = useQueryClient();
  return useMutation((showId) => deleteShow(showId, companyId), {
    ...options,
    onSuccess: () => queryClient.invalidateQueries(['shows']),
  });
}

function updateShow(
  showId: number,
  companyId: number,
  showData: IShow
): Promise<IShow> {
  return client(
    COMPANY_SHOW_URL.replace(':companyId', String(companyId)).replace(
      ':showId',
      String(showId)
    ),
    {
      data: camelKeysToSnake(showData),
      method: 'PATCH',
    }
  );
}

function useUpdateShow(
  companyId: number,
  options?: MutationOptions<IShow, unknown, IShow>
): UseMutationResult<IShow, unknown, IShow> {
  const queryClient = useQueryClient();
  return useMutation(
    (showData) => updateShow(showData.id, companyId, showData),
    {
      onSettled: async (data) => {
        queryClient.invalidateQueries(['shows']);
      },
      onSuccess: async (data) => {
        queryClient.setQueryData(['show', data.id], snakeKeysToCamel(data));
      },
      ...options,
    }
  );
}

const SHOW_PRODUCT_URL = 'shows/:showId/products/:productId/';

function updateProductVisibility(
  showId: number,
  productId: number,
  visible: boolean
) {
  return client(
    SHOW_PRODUCT_URL.replace(':showId', String(showId)).replace(
      ':productId',
      String(productId)
    ),
    {
      method: 'PATCH',
      data: { visible },
    }
  );
}

function useUpdateProductVisibility(
  show: IShow
): UseMutationResult<IShow, unknown, { productId: number; visible: boolean }> {
  const queryClient = useQueryClient();
  return useMutation(
    ({ productId, visible }) =>
      updateProductVisibility(show.id, productId, visible),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['show', show.id]);
        queryClient.invalidateQueries(['showBySlug', show.slugName]);
      },
    }
  );
}

function startShow(showId: number) {
  return client(`shows/${showId}/start/`, {
    method: 'POST',
  });
}

function useStartShow(
  show: IShow,
  options?: MutationOptions<IShow, unknown, number>
): UseMutationResult<IShow, unknown, number> {
  const queryClient = useQueryClient();
  const { openAlert } = useAlert();
  return useMutation(() => startShow(show.id), {
    onSuccess: () => {
      queryClient.setQueryData(['showBySlug', show.slugName], {
        ...show,
        status: 'started',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['show', show.id]);
      queryClient.invalidateQueries(['shows']);
    },
    onError: (error) => {
      if (Array.isArray(error) && error.includes('Invalid start time.')) {
        openAlert({
          title: 'Inicio fuera de programación',
          description:
            'No puedes iniciar un vivo fuera del horario programado.',
          status: 'error',
        });
      } else if (
        Array.isArray(error) &&
        error.includes('Company info not configured.')
      ) {
        openAlert({
          title: 'Domicilio no ingresado',
          description:
            'No puedes iniciar un vivo sin antes haber completado los datos de tu domicilio.',
          status: 'error',
        });
      } else {
        openAlert({
          title: 'Error al iniciar el vivo',
          description: 'No se pudo iniciar el vivo, intenta más tarde.',
          status: 'error',
        });
      }
    },
    ...options,
  });
}

function stopShow(showId: number) {
  return client(`shows/${showId}/stop/`, {
    method: 'POST',
  });
}

function useStopShow(
  show: IShow,
  options?: MutationOptions<IShow, unknown, number>
): UseMutationResult<IShow, unknown, number> {
  const queryClient = useQueryClient();
  return useMutation(() => stopShow(show.id), {
    onSuccess: () => {
      queryClient.setQueryData(['showBySlug', show.slugName], {
        ...show,
        status: 'ended',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(['show', show.id]);
      queryClient.invalidateQueries(['shows']);
    },
    ...options,
  });
}

export {
  useCreateShow,
  useDeleteShow,
  useUpdateShow,
  useShowById,
  useShowBySlugname,
  useShows,
  useStartShow,
  useStopShow,
  useUpdateProductVisibility,
};
