import { Link, useSearchParams } from 'react-router-dom';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import {
  Tab,
  Tabs,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
} from '@chakra-ui/tabs';
import { TbCirclePlus } from 'react-icons/tb';

import { useAuth } from '../../context/AuthContext';
import CompanyMetrics from './components/CompanyMetrics';
import UpcomingShowsSection from './components/UpcomingShowsSection';
import PastShowsSection from './components/PastShowsSection';
import { useCompanyMetrics } from '../../queries/company';
import { getMetricsData } from '../../utils/company';

const ShowsView = () => {
  const { user } = useAuth();
  let [searchParams, setSearchParams] = useSearchParams();
  const statusFilter = searchParams.get('status');
  const { data: metricsData } = useCompanyMetrics(user!.companyId);

  const updateSearchParams = (tabIndex: number) => {
    let tab = '';
    if (tabIndex === 0) {
      tab = 'upcoming';
    } else if (tabIndex === 1) {
      tab = 'past';
    }
    setSearchParams({
      status: tab,
    });
  };

  return (
    <Flex direction="column" gap={{ base: 3, md: 5 }} h="full">
      {metricsData && (
        <Box bg="white" borderRadius="md">
          <CompanyMetrics metrics={getMetricsData(metricsData)} />
        </Box>
      )}
      <Box
        bg="white"
        borderRadius="lg"
        px={{ base: 3, md: 5 }}
        py="3"
        height="full"
      >
        <Flex justifyContent="space-between" pb={{ base: 3, md: 4 }}>
          <Heading as="h2" color="brand.green" fontWeight="semibold">
            Shows
          </Heading>
          <Flex gap="3">
            <Link to="create">
              <Button variant="primary">
                <Icon mr="2" as={TbCirclePlus} />
                Programar show
              </Button>
            </Link>
          </Flex>
        </Flex>
        <Tabs onChange={updateSearchParams}>
          <TabList>
            <Tab
              noOfLines={1}
              fontSize="sm"
              fontWeight="bold"
              color="ui.medium-gray"
              _selected={{ color: 'ui.copy' }}
            >
              Proximos shows
            </Tab>
            <Tab
              noOfLines={1}
              fontSize="sm"
              fontWeight="bold"
              color="ui.medium-gray"
              _selected={{ color: 'ui.copy' }}
            >
              Shows realizados
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="brand.primary.500"
            borderRadius="1px"
          />

          <TabPanels>
            <TabPanel>
              {statusFilter === 'upcoming' && (
                <UpcomingShowsSection companyId={user!.companyId} />
              )}
            </TabPanel>
            <TabPanel>
              {statusFilter === 'past' && (
                <PastShowsSection companyId={user!.companyId} />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default ShowsView;
