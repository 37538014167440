import {
  Alert as ChakraAlert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from '@chakra-ui/alert';
import { useTheme } from '@chakra-ui/system';
import { CloseButton } from '@chakra-ui/close-button';

interface AlertProps {
  status?: 'success' | 'error' | 'warning' | 'info';
  title?: string;
  description: string;
  onClose?: () => void;
  variant?: 'subtle' | 'solid' | 'left-accent' | 'top-accent';
}

const Alert: React.FC<AlertProps> = ({
  status = 'info',
  title,
  description,
  onClose,
  variant = 'subtle',
}) => {
  const theme = useTheme();

  return (
    <ChakraAlert
      status={status}
      variant={variant}
      borderRadius="lg"
      animation={`${theme.keyframes.slideDown} 0.3s ease-in-out`}
      boxShadow="0 2px 4px rgba(0, 0, 0, 0.1)"
    >
      <AlertIcon />
      {title && (
        <AlertTitle mt={4} mb={1} fontSize="lg" fontWeight="bold">
          {title}
        </AlertTitle>
      )}
      <AlertDescription maxWidth="sm">{description}</AlertDescription>
      {onClose && (
        <CloseButton position="absolute" right={2} top={2} onClick={onClose} />
      )}
    </ChakraAlert>
  );
};

export default Alert;
