import { LinkBox, LinkOverlay } from '@chakra-ui/react';
import { ProductMeta } from '../../ui/ProductMeta/ProductMeta';
import { IProductStock } from '../../../types/product';

type ProductItemProps = {
  productStock: IProductStock;
  onClick?: () => void;
};

export const ProductItem = ({
  productStock: {
    id,
    product: { name, description, images },
    price,
  },
  onClick,
}: ProductItemProps) => {
  return (
    <LinkBox key={id} as="article">
      <LinkOverlay key={id} href="#" onClick={onClick}>
        <ProductMeta
          name={name}
          image={images[0]}
          description={description}
          price={price}
        />
      </LinkOverlay>
    </LinkBox>
  );
};
