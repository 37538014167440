import {
  IPaymentMethod,
  IPaymentMethodSetting,
} from '../../../types/paymentMethods';
import { paymentMethods } from './paymentMethods';

export const findPaymentMethodIdForCardNumber = (
  cardNumber: string
): string => {
  const paymentMethod = findPaymentMethodForCardNumber(cardNumber);
  if (!paymentMethod) {
    throw new Error('Payment method not found');
  }
  return paymentMethod.id;
};

export const findPaymentMethodForCardNumber = (
  cardNumber: string
): IPaymentMethod | undefined => {
  return paymentMethods.find((paymentMethod: IPaymentMethod) => {
    const creditCardPatternMatch = paymentMethod.settings.find(
      (setting: IPaymentMethodSetting) => {
        const pattern = setting.bin.pattern;
        const regex = new RegExp(pattern);
        return regex.test(String(cardNumber));
      }
    );
    if (creditCardPatternMatch) {
      return paymentMethod;
    }
  });
};
