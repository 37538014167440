import ReactDOM from 'react-dom';
import {
  AlertDialog as ChakraAlertDialog,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/modal';

interface AlertDialogProps {
  cancelRef: any;
  content: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const AlertDialog: React.FC<AlertDialogProps> = ({
  cancelRef,
  content,
  isOpen,
  onClose,
}) => {
  const alertDialogRoot = document.getElementById('modal-root');

  return alertDialogRoot?.parentNode
    ? ReactDOM.createPortal(
        isOpen ? (
          <ChakraAlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent mt="15%">{content}</AlertDialogContent>
            </AlertDialogOverlay>
          </ChakraAlertDialog>
        ) : null,
        alertDialogRoot
      )
    : null;
};

export default AlertDialog;
