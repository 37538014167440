import { useEffect, useState } from 'react';
import { ICartItem, ICartItemProduct } from '../types/cart';
import { IProductStock } from '../types/product';

interface IConsolidatedCartItemsState {
  cartItems: ICartItem[];
  productStockList: IProductStock[];
}
const mapCartItemsWithProducts = (
  cartItems: ICartItem[],
  productStockList: IProductStock[]
): ICartItemProduct[] => {
  return cartItems
    .map((cartItem: ICartItem) => {
      const productStock = productStockList.find(
        (productStock: IProductStock) =>
          productStock.product.id === cartItem.productId
      );
      if (productStock) {
        return {
          cartItem,
          productStock,
        };
      }
      return null; // Exclude the combination if the product is not found
    })
    .filter(Boolean) as ICartItemProduct[]; // Remove any null values from the resulting array
};

const calculateTotalPrice = (cartItemsProducts: ICartItemProduct[]) =>
  cartItemsProducts.reduce(
    (total: number, { cartItem, productStock }: ICartItemProduct) => {
      return total + cartItem.quantity * productStock.price;
    },
    0
  );

export const useConsolidatedCartItemsState = ({
  cartItems,
  productStockList,
}: IConsolidatedCartItemsState) => {
  const [cartItemsProducts, setCartItemsProducts] = useState<
    ICartItemProduct[]
  >([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  mapCartItemsWithProducts(cartItems, productStockList);
  useEffect(() => {
    const mappedCartItemsProducts = mapCartItemsWithProducts(
      cartItems,
      productStockList
    );
    const calculatedTotalPrice = calculateTotalPrice(mappedCartItemsProducts);
    setCartItemsProducts(mappedCartItemsProducts);
    setTotalPrice(calculatedTotalPrice);
  }, [cartItems, productStockList]);

  return {cartItemsProducts, totalPrice} 
};
