import { NavLink as RouterLink } from 'react-router-dom';
import { Flex, Text } from '@chakra-ui/layout';

type NavLinkProps = {
  icon: React.ReactNode;
  label: string;
  url: string;
};

const NavLink: React.FC<NavLinkProps> = ({ icon, label, url }) => {
  return (
    <RouterLink
      to={url}
      end={url === '/dashboard'}
      className={({ isActive, isPending }) =>
        isPending ? 'pending' : isActive ? 'active' : 'navbar-link'
      }
    >
      <Flex
        align="center"
        gap={1}
        p="0.5rem 1rem"
        _hover={{
          bg: 'brand.green',
          borderRadius: 'full',
          color: 'white',
          padding: '0.5rem 1rem',
        }}
      >
        {icon}
        <Text fontWeight="bold">{label}</Text>
      </Flex>
    </RouterLink>
  );
};

export default NavLink;
