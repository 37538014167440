import { Form, Formik, FormikProps } from 'formik';
import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from '@chakra-ui/form-control';
import { Input } from '@chakra-ui/input';
import { Box, Flex } from '@chakra-ui/layout';

import {
  SAVE_EMAIL_LOCAL_STORAGE_KEY,
  EMAIL_LOCAL_STORAGE_KEY,
} from '../../constants/localStorage';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { ILoginDataSchema, loginDataSchema } from './validationSchema';

interface LoginFormProps {
  onSubmit: (values: ILoginDataSchema) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({ onSubmit }) => {
  const [storedEmail, setStoredEmail] = useLocalStorage(
    EMAIL_LOCAL_STORAGE_KEY,
    ''
  );
  const [shouldSaveEmail, setShouldSaveEmail] = useLocalStorage(
    SAVE_EMAIL_LOCAL_STORAGE_KEY,
    false
  );

  return (
    <Formik
      initialValues={{
        email: storedEmail || '',
        password: '',
      }}
      onSubmit={onSubmit}
      validationSchema={loginDataSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        touched,
        values,
      }: FormikProps<ILoginDataSchema>) => {
        return (
          <Form>
            <Flex direction="column" gap={4}>
              <FormControl
                isInvalid={!!errors.email && touched.email}
                isRequired
              >
                <FormLabel htmlFor="email">Correo electrónico</FormLabel>
                <Input
                  name="email"
                  id="email"
                  placeholder="Ingresa tu dirección de correo electrónico"
                  value={values.email}
                  onChange={(event) => {
                    if (shouldSaveEmail) {
                      setStoredEmail(event.target.value);
                    }
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.email}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!errors.password && touched.password}
                isRequired
              >
                <FormLabel htmlFor="password">Contraseña</FormLabel>
                <Input
                  type="password"
                  id="password"
                  placeholder="Ingresa tu contrase&ntilde;a"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <FormErrorMessage>{errors.password}</FormErrorMessage>
              </FormControl>

              <Checkbox
                defaultChecked={shouldSaveEmail}
                onChange={(event) => {
                  const { checked } = event.target;
                  if (checked) {
                    setStoredEmail(values.email);
                  } else {
                    setStoredEmail('');
                  }
                  setShouldSaveEmail(event.target.checked);
                }}
              >
                Recordar email
              </Checkbox>

              <Box textAlign="center">
                <Button mt={4} w="223px" h={8} variant="primary" type="submit">
                  Ingresar
                </Button>
              </Box>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LoginForm;
