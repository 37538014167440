import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  FieldArray,
  FieldArrayRenderProps,
  Form,
  Formik,
  FormikProps,
} from 'formik';
import { Box, Flex, Heading, VStack, Text } from '@chakra-ui/layout';
import { Button, IconButton } from '@chakra-ui/button';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/form-control';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Input, InputGroup, InputRightElement } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import {
  TbCheck,
  TbCirclePlus,
  TbExclamationCircle,
  TbPlus,
  TbX,
} from 'react-icons/tb';

import { useAlert } from '../../../context/AlertContext';
import { Category } from '../../../types/product';
import { createImageResource } from '../../../utils/api';
import { PRODUCT_STATUS } from '../constants';
import { IProductDataSchema, productDataSchema } from './validationSchema';
import AttributeDrawer from './AttributeDrawer';
import VariantSection from './VariantSection';

type ProductFormProps = {
  categories: Category[];
  ctaLabel?: string;
  onSubmit: (values: any) => void;
  initialValues: IProductDataSchema;
};

const ProductForm: React.FC<ProductFormProps> = ({
  categories,
  ctaLabel = 'Guardar cambios',
  onSubmit,
  initialValues,
}) => {
  const [isVariantDrawerOpen, setIsVariantDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const { openAlert } = useAlert();

  const handleOpenVariantDrawer = () => {
    setIsVariantDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsVariantDrawerOpen(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={productDataSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        setFieldError,
        touched,
        values,
      }: FormikProps<IProductDataSchema>) => {
        return (
          <Form>
            <Flex direction="column" gap={4} pb={5}>
              <VStack
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
                gap={4}
                align="flex-start"
              >
                <Heading as="h3" size="md" fontWeight="semibold">
                  Informaci&oacute;n b&aacute;sica
                </Heading>
                <FormControl
                  isRequired
                  isInvalid={!!errors.name && touched.name}
                >
                  <FormLabel
                    fontWeight="semibold"
                    color={errors.name ? 'error' : 'ui.copy'}
                  >
                    Nombre
                  </FormLabel>
                  <InputGroup>
                    <Input
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Remera"
                    />
                    {touched.name && (
                      <InputRightElement width="4.5rem">
                        <Box color={errors.name ? 'error' : 'green.500'}>
                          {errors.name ? (
                            <Icon as={TbExclamationCircle} color="error" />
                          ) : (
                            <Icon as={TbCheck} />
                          )}
                        </Box>
                      </InputRightElement>
                    )}
                  </InputGroup>
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isRequired
                  isInvalid={!!errors.description && touched.description}
                >
                  <FormLabel>Descripci&oacute;n</FormLabel>
                  <Textarea
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Describir de que se trata el producto"
                  />
                  <FormErrorMessage>{errors.description}</FormErrorMessage>
                </FormControl>
              </VStack>
              <Box
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
              >
                <FormControl>
                  <Flex direction="column" gap={3}>
                    <Heading as="h3" size="md" fontWeight="semibold">
                      Fotos
                    </Heading>
                    <Flex gap={6} alignItems="center">
                      {values.images && values.images.length > 0 && (
                        <FieldArray name="images">
                          {({ remove }: FieldArrayRenderProps) => (
                            <Flex
                              gap={6}
                              alignItems="center"
                              overflowX="scroll"
                              wrap="wrap"
                            >
                              {values.images?.map((image, index) => (
                                <Box
                                  key={`image-${index}`}
                                  boxSize="40"
                                  position="relative"
                                >
                                  <Image
                                    boxSize="40"
                                    objectFit="cover"
                                    src={image}
                                    alt={`Imagen ${index}`}
                                  />
                                  <IconButton
                                    icon={<Icon as={TbX} boxSize={4} />}
                                    aria-label="Borrar imagen"
                                    position="absolute"
                                    top={1}
                                    right={1}
                                    colorScheme="ui.copy-dark"
                                    variant="icon"
                                    size="sm"
                                    onClick={() => {
                                      remove(index);
                                      if (values.images?.length === 1) {
                                        setFieldValue('images', undefined);
                                      }
                                    }}
                                  />
                                  <FormErrorMessage>
                                    {errors.images?.[index]}
                                  </FormErrorMessage>
                                </Box>
                              ))}
                            </Flex>
                          )}
                        </FieldArray>
                      )}
                      <Box flexGrow={1}>
                        <Input
                          type="file"
                          multiple
                          accept="image/*"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const files = Array.from(event.target.files || []);

                            const imageUrlsPromise = Promise.all(
                              files.map(async (file, idx) => {
                                if (file.size > 7 * 1024 * 1024) {
                                  setFieldError(
                                    `images.${idx}`,
                                    'El tamaño de la imagen no puede superar los 10Mb'
                                  );
                                  openAlert({
                                    status: 'error',
                                    variant: 'left-accent',
                                    description: `El tamaño de la imagen "${file.name}" no puede superar los 10Mb`,
                                  });
                                  return Promise.resolve(null);
                                }
                                try {
                                  const imageUrl = await createImageResource(
                                    'product',
                                    file
                                  );
                                  return imageUrl;
                                } catch (error) {
                                  console.error(
                                    'Error creating image resource:',
                                    error
                                  );
                                  openAlert({
                                    status: 'error',
                                    variant: 'left-accent',
                                    description: `Error al subir la imagen ${
                                      idx + 1
                                    }. Inténtalo de nuevo.`,
                                  });
                                }
                              })
                            );

                            imageUrlsPromise.then((imageUrls) => {
                              const filteredImageUrls =
                                imageUrls.filter(Boolean);
                              const currentImages = values.images || [];
                              setFieldValue('images', [
                                ...currentImages,
                                ...filteredImageUrls,
                              ]);
                            });
                          }}
                          // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
                          width="0.1px"
                          height="0.1px"
                          opacity={0}
                          overflow="hidden"
                          position="absolute"
                          zIndex={-1}
                        />

                        {!values.images || values.images.length === 0 ? (
                          <FormLabel cursor="pointer">
                            <Flex
                              direction="column"
                              gap={2}
                              py={4}
                              alignItems="center"
                              backgroundColor="#E4EAF2"
                              border="1px dashed"
                              borderColor="ui.medium-gray"
                              borderRadius="md"
                            >
                              <Flex
                                p={2}
                                backgroundColor="white"
                                border="1px solid"
                                borderColor="ui.link-active"
                                borderRadius="full"
                              >
                                <Icon
                                  as={TbPlus}
                                  color="ui.link-active"
                                  boxSize={6}
                                />
                              </Flex>
                              <Box fontSize="sm">
                                Arrastr&aacute; tu imagen aqui o{' '}
                                <Text color="ui.link" display="inline">
                                  subir desde tu dispositivo
                                </Text>
                              </Box>
                            </Flex>
                          </FormLabel>
                        ) : (
                          <FormLabel>
                            <Flex
                              p={2}
                              backgroundColor="white"
                              border="1px solid"
                              borderColor="ui.link-active"
                              borderRadius="full"
                              maxW="42px"
                            >
                              <Icon
                                as={TbPlus}
                                color="ui.link-active"
                                boxSize={6}
                              />
                            </Flex>
                          </FormLabel>
                        )}
                      </Box>
                    </Flex>
                    <FormErrorMessage>{errors.images}</FormErrorMessage>
                    <FormHelperText>
                      Tamaño máximo: 10Mb. Resolución mínima recomendada:
                      1024px. Formato: PNG, JPEG o GIF.
                    </FormHelperText>
                  </Flex>
                </FormControl>
              </Box>
              <Flex
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
                gap={4}
              >
                <FormControl
                  isRequired
                  isInvalid={!!errors.categoryId && touched.categoryId}
                >
                  <FormLabel fontSize="md">Categor&iacute;a</FormLabel>
                  <Select
                    name="categoryId"
                    value={values.categoryId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Seleccione una categoria"
                  >
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>{errors.categoryId}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!errors.brand && touched.brand}>
                  <FormLabel fontSize="md">Marca</FormLabel>
                  <Input
                    name="brand"
                    value={values.brand}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{errors.brand}</FormErrorMessage>
                </FormControl>
              </Flex>
              <Box
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
              >
                <FormControl
                  isInvalid={!!errors.weight && touched.weight}
                  isRequired
                >
                  <FormLabel fontSize="md">Peso aprox.</FormLabel>
                  <FormHelperText fontSize="sm" color="ui.copy" pb={2}>
                    Expresado en gramos
                  </FormHelperText>
                  <Input
                    name="weight"
                    type="number"
                    value={values.weight}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <FormErrorMessage>{errors.weight}</FormErrorMessage>
                </FormControl>
              </Box>
              {values.variants.length === 1 &&
                values.variants[0].attributes.length === 0 && (
                  <Flex
                    bg="white"
                    border="1px"
                    borderColor="ui.light-gray"
                    borderRadius="lg"
                    py={4}
                    px={5}
                    gap={4}
                  >
                    <FieldArray name="variants">
                      {() => (
                        <>
                          <FormControl
                            isRequired
                            isInvalid={
                              typeof errors.variants?.[0] === 'string'
                                ? !!errors.variants
                                : !!errors.variants?.[0]?.price &&
                                  touched.variants?.[0]?.price
                            }
                          >
                            <FormLabel fontSize="md">Precio</FormLabel>
                            <Input
                              name={`variants.${0}.price`}
                              type="number"
                              value={values.variants[0]?.price ?? ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              min={0}
                            />
                            <FormErrorMessage>
                              {typeof errors.variants?.[0] === 'string'
                                ? errors.variants[0]
                                : errors.variants?.[0]?.price}
                            </FormErrorMessage>
                          </FormControl>
                          <FormControl
                            isRequired
                            isInvalid={
                              typeof errors.variants?.[0] === 'string'
                                ? !!errors.variants
                                : !!errors.variants?.[0]?.stock &&
                                  touched.variants?.[0]?.stock
                            }
                          >
                            <FormLabel fontSize="md">Stock</FormLabel>
                            <Input
                              name={`variants.${0}.stock`}
                              type="number"
                              value={values.variants[0]?.stock ?? ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              min={0}
                            />
                            <FormErrorMessage>
                              {typeof errors.variants?.[0] === 'string'
                                ? errors.variants[0]
                                : errors.variants?.[0]?.stock}
                            </FormErrorMessage>
                          </FormControl>
                        </>
                      )}
                    </FieldArray>
                    <FormControl
                      isRequired
                      isInvalid={!!errors.status && touched.status}
                    >
                      <FormLabel fontSize="md">Estado</FormLabel>
                      <Select
                        name="status"
                        value={values.status ?? ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        {(
                          Object.keys(
                            PRODUCT_STATUS
                          ) as (keyof typeof PRODUCT_STATUS)[]
                        ).map((key) => (
                          <option key={key} value={key}>
                            {PRODUCT_STATUS[key]}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>{errors.status}</FormErrorMessage>
                    </FormControl>
                  </Flex>
                )}
              <VariantSection onOpenVariantDrawer={handleOpenVariantDrawer} />
            </Flex>
            <Flex
              justify="flex-end"
              borderTop="1px"
              borderColor="ui.light-gray"
              gap={3}
              py={4}
            >
              <Button
                variant="secondary"
                bg="ui.gray-100"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                leftIcon={<Icon as={TbCirclePlus} boxSize={6} />}
                type="submit"
              >
                {ctaLabel}
              </Button>
            </Flex>
            <AttributeDrawer
              isOpen={isVariantDrawerOpen}
              onClose={handleDrawerClose}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ProductForm;
