import * as yup from 'yup';

const productDataSchema = yup.object().shape({
  name: yup.string().trim().required('Debe ingresar un nombre'),
  description: yup.string().trim().required('Debe ingresar una descripción'),
  categoryId: yup.number().required('Debe seleccionar una categoria'),
  brand: yup.string().trim().optional(),
  weight: yup
    .number()
    .min(0, 'El peso debe ser mayor o igual a 0.')
    .required('Debe ingresar un peso'),
  images: yup.array().of(yup.string()).optional(),
  status: yup
    .string()
    .oneOf(['active', 'paused', 'discontinued'])
    .default('active')
    .required('Debe seleccionar un estado'),
  variants: yup
    .array()
    .of(
      yup.object().shape({
        price: yup
          .number()
          .min(0, 'El precio debe ser positivo')
          .required('Debe ingresar un precio'),
        stock: yup
          .number()
          .min(0, 'El stock debe ser positivo')
          .required('Debe ingresar un stock'),
        title: yup.string().required(),
        attributes: yup
          .array()
          .of(
            yup.object().shape({
              name: yup.string().required(),
              value: yup.string().required(),
            })
          )
          .required(),
      })
    )
    .required(),
  attributes: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required(),
        values: yup.array().of(yup.string().default('')).required(),
      })
    )
    .required(),
});

interface IProductDataSchema extends yup.InferType<typeof productDataSchema> {}

export { productDataSchema, type IProductDataSchema };
