import { Icon } from '@chakra-ui/icon';
import { Box, Text } from '@chakra-ui/layout';
import { TbUserFilled } from 'react-icons/tb';

interface ViewerCountProps {
  count: number;
}

const ViewerCount: React.FC<ViewerCountProps> = ({ count }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      bg="gray.800"
      p={1}
      borderRadius="md"
      color="white"
    >
      <Icon as={TbUserFilled} boxSize={3} />
      <Text ml={2} fontSize="small" fontWeight="bold">
        {count}
      </Text>
    </Box>
  );
};

export default ViewerCount;
