import React from 'react';
import { Badge, Box, VStack } from '@chakra-ui/react';
import { FaHeart, FaShare, FaShoppingBasket, FaStore } from 'react-icons/fa';

import Fab from './Fab';

interface RightNavProps {
  onBasketClick: () => void;
  onShowProductsClick: () => void;
}

const RightNav: React.FC<RightNavProps> = ({
  onBasketClick,
  onShowProductsClick,
}) => {
  return (
    <VStack alignItems="flex-end">
      <Box padding="4px">
        {/* TODO: We should get the channel start time to display here if the show haven't started yet */}
        <Badge
          bg="brand.red"
          color="white"
          borderRadius="full"
          paddingX="8px"
          paddingY="2px"
        >
          En vivo
        </Badge>
      </Box>
      <VStack id="fabs" spacing="12px">
        {/* <Box padding="4px">
        <Fab
          icon={<FaShare size={20} />}
          label="Compartir"
          onClick={() => console.log('Share')}
        />
      </Box> */}
        <Box padding="4px">
          <Fab
            icon={<FaHeart size={20} />}
            label="Me gusta"
            onClick={() => console.log('Like')}
          />
        </Box>
        <Box padding="4px">
          <Fab
            onClick={onShowProductsClick}
            icon={<FaStore />}
            label="Productos"
          />
        </Box>
        <Box padding="4px">
          <Fab
            onClick={onBasketClick}
            icon={<FaShoppingBasket />}
            label="Carrito"
          />
        </Box>
      </VStack>
    </VStack>
  );
};

export default RightNav;
