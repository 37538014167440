import { ICartItemProduct } from '../../../types/cart';
import {
  ICart,
  ICustomer,
  IOrderData,
  IPayment,
  IShippingAddress,
} from '../../../types/order';
import { findPaymentMethodIdForCardNumber } from './paymentMethodUtils';
import { ICheckoutDataSchema } from './validationSchema';

export const convertFormToOrderData = (
  showId: string,
  form: ICheckoutDataSchema,
  cartItemsProducts: ICartItemProduct[]
): IOrderData => {
  const {
    email,
    name,
    phone,
    street,
    street_number,
    depto,
    city,
    zip,
    state,
    dni,
    card_number,
    card_name,
    card_expiry,
    card_cvc,
  } = form;
  const cart: ICart = {
    items: cartItemsProducts.map((cartItem) => {
      return {
        product_id: String(cartItem.cartItem.productId),
        quantity: cartItem.cartItem.quantity,
        price: cartItem.productStock.price,
      };
    }, []),
  };
  const payment_method_id = findPaymentMethodIdForCardNumber(card_number);
  const payment: IPayment = {
    payment_method: 'credit_card',
    installments: 1,
    credit_card: {
      number: card_number,
      expiration_month: card_expiry.split('/')[0],
      expiration_year: `20${card_expiry.split('/')[1]}`,
      cvv: card_cvc,
      payment_method_id: payment_method_id,
      cardholder: {
        name: card_name,
        identification: {
          type: 'DNI',
          value: dni,
        },
      },
    },
  };
  const customer: ICustomer = {
    first_name: name.split(' ')[0],
    last_name: name.split(' ')[1],
    email,
  };
  const shipping_address: IShippingAddress = {
    address: `${street} ${street_number} ${depto}`,
    city,
    state,
    zip,
  };
  const orderData = {
    show_id: showId,
    cart,
    payment,
    customer,
    shipping_address,
  };
  return orderData;
};
