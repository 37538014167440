import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { Box, Flex, Heading, Text, Grid, GridItem } from '@chakra-ui/layout';
import { Button, IconButton } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  FormHelperText,
} from '@chakra-ui/form-control';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import { Input } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import { TbDeviceFloppy, TbPlus, TbX } from 'react-icons/tb';

import { ICompany } from '../../../types/company';
import { createImageResource } from '../../../utils/api';
import { formatCuit } from '../../../utils/transformations';
import { PROVINCES } from '../constants';
import { companyDataSchema } from './validationSchema';

type CompanyFormProps = {
  onSubmit: (values: any) => void;
  initialValues: ICompany;
};

const CompanyForm: React.FC<CompanyFormProps> = ({
  onSubmit,
  initialValues,
}) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={companyDataSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        touched,
        values,
      }) => {
        return (
          <Form>
            <Grid
              templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }}
              gap={6}
              bg="white"
              py={4}
              px={5}
              border="1px"
              borderColor="ui.light-gray"
              borderRadius="lg"
            >
              <GridItem>
                <Flex direction="column" gap={4} width="100%">
                  <Heading as="h3" size="md" fontWeight="semibold">
                    Informaci&oacute;n General
                  </Heading>
                  <FormControl
                    isInvalid={!!errors.name && !!touched.name}
                    isRequired
                  >
                    <FormLabel htmlFor="name">Nombre</FormLabel>
                    <Input
                      id="name"
                      type="text"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.name}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.description && !!touched.description}
                    isRequired
                  >
                    <FormLabel htmlFor="description">
                      Descripci&oacute;n
                    </FormLabel>
                    <Textarea
                      id="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.businessName && !!touched.businessName}
                  >
                    <FormLabel htmlFor="businessName">
                      Raz&oacute;n social
                    </FormLabel>
                    <Input
                      id="businessName"
                      type="text"
                      value={values.businessName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.businessName}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.cuit && !!touched.cuit}
                    isRequired
                  >
                    <FormLabel htmlFor="cuit">CUIT</FormLabel>
                    <Input
                      id="cuit"
                      type="text"
                      value={values.cuit}
                      onChange={(e) => {
                        const formattedValue = formatCuit(e.target.value);
                        setFieldValue('cuit', formattedValue);
                      }}
                      onBlur={handleBlur}
                    />
                    <FormErrorMessage>{errors.cuit}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.vatCondition && !!touched.vatCondition}
                    isRequired
                  >
                    <FormLabel htmlFor="vatCondition">
                      Condici&oacute;n de IVA
                    </FormLabel>
                    <Select
                      id="vatCondition"
                      value={values.vatCondition}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Seleccionar condición de IVA..."
                    >
                      <option value="responsable inscripto">
                        Responsable Inscripto
                      </option>
                      <option value="autonomo">Autonomo</option>
                      <option value="monotributista">Monotributista</option>
                      <option value="exento">Exento</option>
                      <option value="consumidor final">Consumidor final</option>
                    </Select>
                    <FormErrorMessage>{errors.vatCondition}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </GridItem>
              <GridItem>
                <Flex direction="column" gap={5} w="100%">
                  <Heading as="h3" size="md" fontWeight="semibold">
                    Imágenes
                  </Heading>
                  <FormControl
                    isRequired
                    isInvalid={!!errors.logo && touched.logo}
                  >
                    <FormLabel>Logo</FormLabel>
                    {values.logo ? (
                      <Box position="relative" my={3}>
                        <Image
                          boxSize={48}
                          objectFit="cover"
                          src={values.logo}
                          alt="Logo"
                          borderRadius="full"
                          boxShadow="0 0 8px rgba(0, 0, 0, 0.3)"
                        />
                        <IconButton
                          icon={<Icon as={TbX} boxSize={4} />}
                          aria-label="Borrar imagen"
                          position="absolute"
                          top={2}
                          left="150px"
                          colorScheme="ui.copy-dark"
                          variant="icon"
                          size="sm"
                          onClick={() => {
                            setFieldValue('logo', undefined);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box pt={3}>
                        <Input
                          type="file"
                          accept="image/*"
                          name="logo"
                          onChange={async (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file = event.target.files?.[0];
                            if (file) {
                              const imageUrl = await createImageResource(
                                'company',
                                file
                              );
                              setFieldValue('logo', imageUrl);
                            }
                          }}
                          // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
                          width="0.1px"
                          height="0.1px"
                          opacity={0}
                          overflow="hidden"
                          position="absolute"
                          zIndex={-1}
                        />
                        <FormLabel cursor="pointer">
                          <Flex
                            direction="column"
                            gap={2}
                            py={4}
                            alignItems="center"
                            backgroundColor="#E4EAF2"
                            border="1px dashed"
                            borderColor="ui.medium-gray"
                            borderRadius="md"
                          >
                            <Flex
                              p={2}
                              backgroundColor="white"
                              border="1px solid"
                              borderColor="ui.link-active"
                              borderRadius="full"
                            >
                              <Icon
                                as={TbPlus}
                                color="ui.link-active"
                                boxSize={6}
                              />
                            </Flex>
                            <Box fontSize="sm">
                              Arrastr&aacute; tu imagen aqui o{' '}
                              <Text color="ui.link" display="inline">
                                subir desde tu dispositivo
                              </Text>
                            </Box>
                          </Flex>
                        </FormLabel>
                        <FormHelperText>
                          Tamaño máximo: 10Mb. Resolución mínima recomendada:
                          1024px. Formato: PNG, JPEG o GIF.
                        </FormHelperText>
                      </Box>
                    )}
                    <FormErrorMessage>{errors.logo}</FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isRequired
                    isInvalid={!!errors.image && touched.image}
                  >
                    <FormLabel>Portada</FormLabel>
                    {values.image ? (
                      <Box position="relative" my={3}>
                        <Image
                          aspectRatio={16 / 9}
                          width="50%"
                          objectFit="cover"
                          src={values.image}
                          alt="Imagen de portada"
                          borderRadius="lg"
                          boxShadow="0 0 8px rgba(0, 0, 0, 0.3)"
                        />
                        <IconButton
                          icon={<Icon as={TbX} boxSize={4} />}
                          aria-label="Borrar imagen"
                          position="absolute"
                          top={1}
                          left="220px"
                          colorScheme="ui.copy-dark"
                          variant="icon"
                          size="sm"
                          onClick={() => {
                            setFieldValue('image', undefined);
                          }}
                        />
                      </Box>
                    ) : (
                      <Box pt={3}>
                        <Input
                          type="file"
                          accept="image/*"
                          name="image"
                          onChange={async (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file = event.target.files?.[0];
                            if (file) {
                              const imageUrl = await createImageResource(
                                'company',
                                file
                              );
                              setFieldValue('image', imageUrl);
                            }
                          }}
                          // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
                          width="0.1px"
                          height="0.1px"
                          opacity={0}
                          overflow="hidden"
                          position="absolute"
                          zIndex={-1}
                        />
                        <FormLabel cursor="pointer">
                          <Flex
                            direction="column"
                            gap={2}
                            py={4}
                            alignItems="center"
                            backgroundColor="#E4EAF2"
                            border="1px dashed"
                            borderColor="ui.medium-gray"
                            borderRadius="md"
                          >
                            <Flex
                              p={2}
                              backgroundColor="white"
                              border="1px solid"
                              borderColor="ui.link-active"
                              borderRadius="full"
                            >
                              <Icon
                                as={TbPlus}
                                color="ui.link-active"
                                boxSize={6}
                              />
                            </Flex>
                            <Box fontSize="sm">
                              Arrastr&aacute; tu imagen aqui o{' '}
                              <Text color="ui.link" display="inline">
                                subir desde tu dispositivo
                              </Text>
                            </Box>
                          </Flex>
                        </FormLabel>
                        <FormHelperText>
                          Tamaño máximo: 10Mb. Resolución mínima recomendada:
                          1024px. Formato: PNG, JPEG o GIF.
                        </FormHelperText>
                        <FormErrorMessage>{errors.image}</FormErrorMessage>
                      </Box>
                    )}
                  </FormControl>
                </Flex>
              </GridItem>
              <GridItem colSpan={{ base: 1, md: 2 }}>
                <Flex direction="column" gap={3} flexGrow={2}>
                  <Heading as="h3" size="md" fontWeight="semibold">
                    Domicilio
                  </Heading>
                  <Flex gap={3}>
                    <FormControl
                      isInvalid={
                        !!errors.address?.street && !!touched.address?.street
                      }
                      isRequired
                    >
                      <FormLabel>Calle</FormLabel>
                      <Input
                        id="address.street"
                        value={values.address?.street}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.address?.street}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      maxW="200px"
                      isInvalid={
                        !!errors.address?.number && !!touched.address?.number
                      }
                      isRequired
                    >
                      <FormLabel>Número</FormLabel>
                      <Input
                        id="address.number"
                        value={values.address?.number}
                        type="number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.address?.number}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <FormControl
                    isInvalid={
                      !!errors.address?.country && !!touched.address?.country
                    }
                    isRequired
                  >
                    <FormLabel>País</FormLabel>
                    <Select
                      id="address.country"
                      placeholder="Seleccionar país..."
                      value={values.address?.country}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="Argentina">Argentina</option>
                    </Select>
                    <FormErrorMessage>
                      {errors.address?.country}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!errors.address?.province && !!touched.address?.province
                    }
                    isRequired
                  >
                    <FormLabel>Provincia</FormLabel>
                    <Select
                      id="address.province"
                      placeholder="Seleccionar provincia..."
                      value={values.address?.province}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      {PROVINCES.map((province) => (
                        <option key={province} value={province}>
                          {province}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>
                      {errors.address?.province}
                    </FormErrorMessage>
                  </FormControl>
                  <Flex gap={3}>
                    <FormControl
                      isInvalid={
                        !!errors.address?.city && !!touched.address?.city
                      }
                      isRequired
                    >
                      <FormLabel>Ciudad</FormLabel>
                      <Input
                        id="address.city"
                        value={values.address?.city}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.address?.city}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      maxW="200px"
                      isInvalid={
                        !!errors.address?.zipCode && !!touched.address?.zipCode
                      }
                    >
                      <FormLabel>Código postal</FormLabel>
                      <Input
                        id="address.zipCode"
                        value={values.address?.zipCode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>
                        {errors.address?.zipCode}
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  <FormControl
                    isInvalid={
                      !!errors.address?.isTaxAddress &&
                      !!touched.address?.isTaxAddress
                    }
                  >
                    <Checkbox
                      id="address.isTaxAddress"
                      type="checkbox"
                      isChecked={values.address?.isTaxAddress}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      Es dirección fiscal
                    </Checkbox>
                    <FormErrorMessage>
                      {errors.address?.isTaxAddress}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    isInvalid={
                      !!errors.allowsPickups && !!touched.allowsPickups
                    }
                  >
                    <Checkbox
                      id="allowsPickups"
                      defaultChecked={values.allowsPickups}
                      isChecked={values.allowsPickups}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      Habilitar retiros en tienda
                    </Checkbox>
                    <FormHelperText>
                      Si habilitás esta opción, los clientes podrán retirar sus
                      compras en tu local.
                    </FormHelperText>
                    <FormErrorMessage>{errors.allowsPickups}</FormErrorMessage>
                  </FormControl>
                </Flex>
              </GridItem>
            </Grid>

            <Flex
              justify="flex-end"
              borderTop="1px"
              borderColor="ui.light-gray"
              gap={3}
              py={4}
            >
              <Button
                variant="secondary"
                bg="ui.gray-100"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
              <Button
                variant="primary"
                leftIcon={<Icon as={TbDeviceFloppy} boxSize={6} />}
                type="submit"
              >
                Guardar cambios
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CompanyForm;
