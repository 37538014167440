import { useState } from 'react';
import { ConnectionState, LocalVideoTrack, UID } from 'agora-rtc-react';
import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { AspectRatio, Badge, Box, Center, Flex, Text } from '@chakra-ui/layout';

import { useAgoraContext } from '../../../components/sections/AgoraManager/AgoraManager';
import CenteredSpinner from '../../../components/ui/CenteredSpinner';
import { useAlert } from '../../../context/AlertContext';
import { useModal } from '../../../context/ModalContext';
import { useStartShow, useStopShow } from '../../../queries/shows';
import { IShow } from '../../../types/show';

import Controls from './Controls';
import StopLiveModal from './StopLiveModal';
import StartLiveModal from './StartLiveModal';
import ViewerCount from '../../../components/ui/Agora/ViewerCount';

type PlayMode = 'test' | 'live' | 'stopped';

interface VideoCallProps {
  connectionState: ConnectionState;
  isAudioMuted: boolean;
  isVideoMuted: boolean;
  onJoinChannel: () => Promise<UID>;
  onLeaveChannel: () => Promise<void>;
  onToggleAudio: () => void;
  onTogglePlayback: () => void;
  remoteUserCount: number;
  show: IShow;
}

const VideoCall: React.FC<VideoCallProps> = ({
  connectionState,
  isAudioMuted,
  isVideoMuted,
  onJoinChannel,
  onLeaveChannel,
  onToggleAudio,
  onTogglePlayback,
  remoteUserCount,
  show,
}) => {
  const { localCameraTrack } = useAgoraContext();
  const { id: showId, status } = show;
  const { openModal, closeModal } = useModal();
  const { openAlert } = useAlert();
  const { mutate: startShow } = useStartShow(show);
  const { mutate: stopShow } = useStopShow(show);
  const [mode, setMode] = useState<PlayMode>(
    status === 'started' ? 'live' : 'test'
  );

  const handlePlaybackClick = async () => {
    onTogglePlayback();
  };

  const handleModalTestClick = async () => {
    try {
      Promise.all([onJoinChannel()]);
      setMode('test');
    } catch (error) {
      console.error('Failed to start test:', error);
    } finally {
      closeModal();
    }
  };

  const handleModalStartClick = async () => {
    try {
      await Promise.all([startShow(showId), onJoinChannel()]);
      setMode('live');
    } catch (error) {
      console.error('Failed to start show:', error);
    } finally {
      closeModal();
    }
  };

  const handleStopClick = async () => {
    openModal(
      <StopLiveModal onCancel={closeModal} onStopClick={handleModalStopClick} />
    );
  };

  const handleModalStopClick = async () => {
    try {
      if (mode === 'test') {
        onLeaveChannel();
      } else if (mode === 'live') {
        Promise.all([stopShow(showId), onLeaveChannel()]);
      }
      setMode('stopped');
    } catch (error) {
      openAlert({
        title: 'Error al detener el vivo',
        description: 'No se pudo detener el vivo, intenta más tarde.',
        status: 'error',
      });
      console.error('Failed to stop show:', error);
    } finally {
      closeModal();
    }
  };

  return (
    <Box>
      <Box borderRadius="lg" overflow="hidden" position="relative">
        <AspectRatio ratio={9 / 16}>
          <LocalVideoTrack track={localCameraTrack} play={!isVideoMuted} />
        </AspectRatio>
        {isVideoMuted && (
          <Box position="absolute" top={0} left={0} w="full">
            <AspectRatio ratio={9 / 16}>
              <Image
                src={show.image}
                alt={show.name}
                position="absolute"
                top={0}
                left={0}
              />
            </AspectRatio>
          </Box>
        )}
        {connectionState === 'DISCONNECTED' && status === 'started' && (
          <Center
            position="absolute"
            w="full"
            h="full"
            top={0}
            zIndex={1}
            _before={{
              background: 'ui.light-gray',
              opacity: 0.3,
              content: '""',
              position: 'absolute',
              width: '100%',
              height: '100%',
            }}
          >
            <Button
              onClick={onJoinChannel}
              boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
            >
              Ingresar a la sala del show
            </Button>
          </Center>
        )}
        {(connectionState === 'DISCONNECTED' ||
          connectionState === 'DISCONNECTING') &&
          status === 'ended' && (
            <Center
              position="absolute"
              w="full"
              h="full"
              top={0}
              zIndex={1}
              _before={{
                background: 'ui.light-gray',
                opacity: 0.3,
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '100%',
              }}
            >
              <Box
                p={3}
                borderRadius="full"
                boxShadow="0px 4px 10px rgba(0, 0, 0, 0.25)"
                bg="ui.light-gray"
                opacity={0.8}
              >
                <Text>Transmisión finalizada</Text>
              </Box>
            </Center>
          )}
        {connectionState === 'DISCONNECTED' && status === 'created' && (
          <Flex
            position="absolute"
            w="full"
            justify="center"
            bottom={6}
            zIndex={1}
          >
            <Button
              onClick={() =>
                openModal(
                  <StartLiveModal
                    onClose={handleModalTestClick}
                    onStartClick={handleModalStartClick}
                  />
                )
              }
            >
              Iniciar transmisión
            </Button>
          </Flex>
        )}
        {connectionState === 'DISCONNECTED' && (
          <Box position="absolute" top={0} left={0} w="full">
            <AspectRatio ratio={9 / 16}>
              <Image
                src={show.image}
                alt={show.name}
                position="absolute"
                top={0}
                left={0}
              />
            </AspectRatio>
          </Box>
        )}
        {connectionState === 'CONNECTING' && <CenteredSpinner />}
        {connectionState === 'CONNECTED' && status === 'started' && (
          <>
            <Box position="absolute" top={3} right={3}>
              <Badge
                bg="brand.red"
                color="white"
                borderRadius="full"
                paddingX="8px"
                paddingY="2px"
              >
                En vivo
              </Badge>
            </Box>
            <Box position="absolute" top={3} left={3}>
              <ViewerCount count={remoteUserCount} />
            </Box>
          </>
        )}
      </Box>
      <Controls
        isAudioMuted={isAudioMuted}
        isVideoMuted={isVideoMuted}
        onMuteClick={onToggleAudio}
        onPlaybackClick={handlePlaybackClick}
        onStopClick={handleStopClick}
        isDisabled={
          status === 'ended' ||
          connectionState === 'DISCONNECTED' ||
          connectionState === 'DISCONNECTING'
        }
      />
    </Box>
  );
};

export default VideoCall;
