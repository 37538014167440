import { useNavigate, useParams } from 'react-router-dom';
import { Divider, Flex, Heading } from '@chakra-ui/layout';

import { useAlert } from '../../context/AlertContext';
import { useAuth } from '../../context/AuthContext';
import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useShowById, useUpdateShow } from '../../queries/shows';
import ShowForm from './components/ShowForm';
import { useCategories, useProducts } from '../../queries/products';
import { IShow } from '../../types/show';

const EditShow = () => {
  const { showId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { openAlert } = useAlert();
  const { data: show, isLoading } = useShowById(
    user!.companyId,
    Number(showId)
  );
  const { data: categories } = useCategories();
  const { data: products } = useProducts(user!.companyId);

  const { mutate: updateShow } = useUpdateShow(user!.companyId);

  const handleSubmit = async (values: IShow) => {
    try {
      await updateShow(values);
      navigate('/dashboard/shows?status=upcoming');
    } catch (error) {
      console.error(error);
      openAlert({
        status: 'error',
        variant: 'left-accent',
        description: 'Error al editar el show. Inténtalo de nuevo.',
      });
    }
  };

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    show &&
    products &&
    categories && (
      <Flex
        direction="column"
        gap={{ base: 1, md: 3 }}
        w={{ base: '100%', md: '80%', xl: '60%' }}
      >
        <Heading as="h2" fontSize="3xl">
          Editar show
        </Heading>
        <Divider />
        <ShowForm
          initialValues={show}
          onSubmit={handleSubmit}
          products={products}
          categories={categories}
        />
      </Flex>
    )
  );
};

export default EditShow;
