import React from 'react';
import { Avatar } from '@chakra-ui/avatar';
import { Box, Flex, Text } from '@chakra-ui/layout';

interface HostMessageProps {
  alignRight: boolean;
  author: string;
  avatarUrl: string;
  message: string;
}

const HostMessage: React.FC<HostMessageProps> = ({
  author,
  avatarUrl,
  message,
  alignRight = false,
}) => {
  return (
    <Flex
      alignItems="baseline"
      border="2px"
      borderColor="ui.link-active"
      borderRadius="10px"
      bg="ui.bg"
      padding={2}
      alignSelf={alignRight ? 'flex-end' : 'flex-start'}
      boxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.16)"
    >
      <Avatar name={author} src={avatarUrl} size="xs" />
      <Box pl={2} pr={5}>
        <Text color="ui.link-active" fontWeight="semibold">
          {author}
        </Text>
        <Text color="ui.copy">{message}</Text>
      </Box>
    </Flex>
  );
};

export default HostMessage;
