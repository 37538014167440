import {
  Box,
  Flex,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  Heading,
  Divider,
  Button,
  useToast,
} from '@chakra-ui/react';
import { OrderSummary } from './OrderSummary';
import { PaymentInformation } from './PaymentInformation';
import { ShippingInformation } from './ShippingInformation';
import { Formik, Form } from 'formik';
import { checkoutDataSchema } from './validationSchema';
import { IShow } from '../../../types/show';
import { IProductStock } from '../../../types/product';
import { useConsolidatedCartItemsState } from '../../../hooks/useConsolidatedCartItemsState';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { convertFormToOrderData } from './convertFormToOrderData';
import { useCreateShowOrderAPI } from '../../../hooks/useShowOrderAPI';
import { IOrder, EOrderStatus, PaymentStatus } from '../../../types/order';
interface ICheckoutProps {
  show: IShow;
  productStockList: IProductStock[];
  onCheckoutSuccess: () => void;
}

export const Checkout = ({
  show,
  productStockList,
  onCheckoutSuccess,
}: ICheckoutProps) => {
  const toast = useToast();
  const { getCartItems, removeShowCart } = useShoppingCart();
  const { createOrder, isLoading } = useCreateShowOrderAPI();
  const cartItems = getCartItems(show.id);
  const { cartItemsProducts, totalPrice: cartTotalPrice } =
    useConsolidatedCartItemsState({
      cartItems,
      productStockList,
    });
  const shippingPrice = show.shippingPrice || 0;
  const orderTotalPrice = cartTotalPrice + shippingPrice;

  const handleCheckoutSuccess = (order: IOrder) => {
    console.log(order);
    if (order.order_status === EOrderStatus.SUCCESS) {
      onCheckoutSuccess();
      removeShowCart(show.id);
      return;
    }
    if (order.payment_status === PaymentStatus.FAILURE) {
      toast({
        title: 'Error',
        description: 'Hubo un error al procesar el pago',
        status: 'error',
        position: 'top',
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleCheckoutError = (error: any) => {
    console.log(error);
    let errorMessage = 'Hubo un error al procesar la orden';

    if (error.detail?.includes('Insufficient stock')) {
      errorMessage = 'No hay stock suficiente para alguno de los productos';
    }

    toast({
      title: 'Error',
      description: errorMessage,
      status: 'error',
      position: 'top',
      duration: 6000,
      isClosable: true,
    });
  };

  const onSubmit = (values: any, { setSubmitting }: any) => {
    const orderData = convertFormToOrderData(
      String(show.id),
      checkoutDataSchema.cast(values),
      cartItemsProducts
    );
    createOrder(orderData, handleCheckoutSuccess, handleCheckoutError);
    setSubmitting(false);
  };

  const initialValues = {
    email: '',
    name: '',
    phone: '',
    street: '',
    street_number: '',
    depto: '',
    city: '',
    zip: '',
    state: '',
    dni: '',
    card_number: '',
    card_name: '',
    card_expiry: '',
    card_cvc: '',
  };

  // const initialValues: ICheckoutDataSchema = {
  //   email: 'john.doe@example.com',
  //   name: 'John Doe',
  //   phone: '1234567890',
  //   street: '123 Main Street',
  //   street_number: '5A',
  //   depto: 'Apt 42',
  //   city: 'New York',
  //   zip: '10001',
  //   state: 'NY',
  //   dni: '12345678',
  //   card_number: '4111 1111 1111 1111',
  //   card_name: 'John Doe',
  //   card_expiry: '12/23',
  //   card_cvc: '123',
  // };

  const breakPointValue = useBreakpointValue({
    base: useColorModeValue('white', 'gray.800'),
    md: 'inherit',
  });

  const colorModeValue = useColorModeValue('white', 'gray.800');

  return (
    <Box
      bgGradient={useColorModeValue(
        'linear(to-l, gray.50 50%, white 50%)',
        'linear(to-l, gray.700 50%, gray.800 50%)'
      )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={checkoutDataSchema}
        validateOnMount={true}
      >
        {(props) => (
          <Form>
            <Flex
              maxW="8xl"
              mx="auto"
              direction={{ base: 'column', md: 'row' }}
            >
              <Box
                flex="1"
                bg={colorModeValue}
                px={{ base: '4', md: '8', lg: '12', xl: '20' }}
                py={{ base: '6', md: '8', lg: '12', xl: '20' }}
              >
                <Stack spacing={{ base: '8', lg: '10' }}>
                  <Heading size="md">Datos del Contacto</Heading>
                  <ShippingInformation {...props} />
                  <PaymentInformation {...props} />
                </Stack>
              </Box>
              <Divider />

              <Box
                flex="1"
                maxW={{ lg: 'md', xl: '40rem' }}
                bg={breakPointValue}
                px={{ base: '4', md: '8', lg: '12', xl: '20' }}
                py={{ base: '6', md: '8', lg: '12', xl: '20' }}
              >
                <OrderSummary
                  shippingPrice={shippingPrice}
                  cartTotalPrice={cartTotalPrice}
                  orderTotalPrice={orderTotalPrice}
                />
              </Box>
              <Box
                flex="1"
                maxW={{ lg: 'md', xl: '40rem' }}
                bg={breakPointValue}
                px={{ base: '4', md: '8', lg: '12', xl: '20' }}
                py={{ base: '6', md: '8', lg: '12', xl: '20' }}
              >
                <Stack spacing={{ base: '6', md: '10' }}>
                  <Button
                    type="submit"
                    isLoading={props.isSubmitting || isLoading}
                    isDisabled={!props.isValid}
                    colorScheme="blue"
                    size="lg"
                    py="7"
                  >
                    Confirmar Compra
                  </Button>
                </Stack>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
