import React, { useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import AgoraRTM from 'agora-rtm-sdk';

import {
  useFetchShowAudienceTokenRemote,
  useFetchShowProductStockAPI,
} from '../../hooks/useFetchShowAPI';
import { ShoppingCartProvider } from '../../context/ShoppingCartContext';
import { useAuth } from '../../context/AuthContext';
import { IProductStock } from '../../types/product';
import { useShows } from '../../queries/shows';
import { OrderConfirmation } from '../../components/sections/OrderConfiramtion/OrderConfirmation';
import AgoraChat from '../../components/sections/AgoraChat/AgoraChat';
import { Checkout } from '../../components/sections/Checkout/Checkout';
import { ProductList } from '../../components/sections/ProductList/ProductList';
import { ProductDetail } from '../../components/sections/ProductDetail/ProductDetail';
import { ShoppingCart } from '../../components/sections/ShoppingCart/ShoppingCart';
import AudienceVideo from './components/AudienceVideo/AudienceVideo';
import RightNav from './components/RightNav';
import SellerInfo from './components/SellerInfo';
import { ShopDrawer } from './components/ShopDrawer';
import {
  DRAWER_CHECKOUT,
  DRAWER_CLOSE,
  DRAWER_ORDER_CONFIRMATION,
  DRAWER_PRODUCT_DETAIL,
  DRAWER_PRODUCT_LIST,
  DRAWER_SHOPPING_CART,
} from './constants';

const agoraClient = AgoraRTM.createInstance(
  process.env.REACT_APP_AGORA_APP_ID as string
);

export const LiveShowScreen = () => {
  const { user } = useAuth();
  const params = useParams();
  const showSlugName = params.showSlugName as string;
  const [searchParams] = useSearchParams();
  let tokenOnParam = searchParams.get('token');
  if (tokenOnParam) {
    tokenOnParam = tokenOnParam.replace(/ /g, '+');
  }
  const {
    data: liveShows,
    error: showError,
    isLoading,
  } = useShows(user!.companyId, {
    queryParams: { slugName: showSlugName },
  });

  // this is returning the show wrapped in an array. We want to change this in the future
  const liveShow = liveShows?.find((show) => {
    return show.slugName === showSlugName;
  });
  const {
    data: audienceToken,
    error: tokenError,
    isLoading: isTokenLoading,
  } = useFetchShowAudienceTokenRemote(liveShow?.id || '');
  const { data: productsStock } = useFetchShowProductStockAPI(
    liveShow?.id || ''
  );

  const productStockList: IProductStock[] =
    (productsStock?.results.map(
      (productStock: any) => productStock
    ) as IProductStock[]) || ([] as IProductStock[]);
  const loading = isLoading || isTokenLoading;
  const [drawer, setDrawer] = useState(DRAWER_CLOSE);

  const [selectedProductStock, setSelectedProductStock] =
    useState<IProductStock | null>(null);

  const agoraAppId = process.env.REACT_APP_AGORA_APP_ID as string;
  console.log(
    'data',
    liveShow,
    'error',
    showError,
    'loading',
    loading,
    'agoraAppId',
    agoraAppId
  );

  const handleProductCartDrawer = () => {
    setDrawer(DRAWER_SHOPPING_CART);
  };
  const handleShowProductsListDrawer = () => {
    setDrawer(DRAWER_PRODUCT_LIST);
  };

  const handleCloseDrawer = () => {
    setDrawer(DRAWER_CLOSE);
  };

  const handleProductSelected = (productStock: IProductStock) => {
    setSelectedProductStock(productStock);
    setDrawer(DRAWER_PRODUCT_DETAIL);
  };
  const handleAddedToCart = (productStockId: Number, quantity: number) => {
    setDrawer(DRAWER_SHOPPING_CART);
  };

  const handleKeepShopping = () => {
    setDrawer(DRAWER_PRODUCT_LIST);
  };

  const handleInitCheckout = () => {
    setDrawer(DRAWER_CHECKOUT);
  };

  const handleCheckoutSuccess = () => {
    setDrawer(DRAWER_ORDER_CONFIRMATION);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <ShoppingCartProvider>
      {liveShow && audienceToken && (
        <div>
          <Box
            id="overlay"
            position="absolute"
            zIndex="1"
            width="100%"
            padding="16px"
            paddingTop="32px"
          >
            <Flex alignItems="flex-start" justify="space-between">
              <SellerInfo
                seller={{
                  showName: liveShow.name,
                  name: liveShow.company.name,
                  logo: liveShow.company.logo,
                }}
              />

              <RightNav
                onBasketClick={handleProductCartDrawer}
                onShowProductsClick={handleShowProductsListDrawer}
              />
            </Flex>
          </Box>
          {audienceToken ? (
            <Box position="absolute" bottom={0} zIndex={1} width="full">
              <AgoraChat
                client={agoraClient}
                channelName={liveShow.channel}
                token={tokenOnParam || audienceToken.rtm_token}
                userId={audienceToken.user_id}
                variant="viewer"
                showStatus={liveShow.status}
              />
            </Box>
          ) : null}
          {liveShow ? (
            <AudienceVideo
              appId={agoraAppId}
              token={tokenOnParam || audienceToken.rtc_token}
              channelName={liveShow.channel}
              userId={audienceToken.user_id}
            />
          ) : null}
        </div>
      )}
      {liveShow && (
        <>
          <ShopDrawer
            isOpen={drawer === DRAWER_PRODUCT_LIST}
            onClose={handleCloseDrawer}
          >
            <ProductList
              show={liveShow}
              productStockList={productStockList}
              onProductSelected={handleProductSelected}
            />
          </ShopDrawer>
          <ShopDrawer
            isOpen={drawer === DRAWER_PRODUCT_DETAIL}
            onClose={handleCloseDrawer}
          >
            {selectedProductStock && (
              <ProductDetail
                show={liveShow}
                productStock={selectedProductStock}
                onAddedToCart={handleAddedToCart}
              />
            )}
          </ShopDrawer>
          <ShopDrawer
            isOpen={drawer === DRAWER_SHOPPING_CART}
            onClose={handleCloseDrawer}
          >
            <ShoppingCart
              show={liveShow}
              productStockList={productStockList}
              onKeepShopping={handleKeepShopping}
              onInitCheckout={handleInitCheckout}
            />
          </ShopDrawer>
          <ShopDrawer
            isOpen={drawer === DRAWER_CHECKOUT}
            onClose={handleCloseDrawer}
          >
            <Checkout
              show={liveShow}
              productStockList={productStockList}
              onCheckoutSuccess={handleCheckoutSuccess}
            />
          </ShopDrawer>
          <ShopDrawer
            isOpen={drawer === DRAWER_ORDER_CONFIRMATION}
            onClose={handleCloseDrawer}
          >
            <OrderConfirmation />
          </ShopDrawer>
        </>
      )}
    </ShoppingCartProvider>
  );
};
