import * as yup from 'yup';
import { findPaymentMethodForCardNumber } from '../../components/sections/Checkout/paymentMethodUtils';

const checkoutMobileSchema = yup.object().shape({
  cardNumber: yup
    .string()
    .transform((value) => value.replace(/\D/g, ''))
    .test('credit-card', 'Número de tarjeta inválido', function (value) {
      if (!value) return false;
      // Luhn algorithm for credit card number validation
      let sum = 0;
      let shouldDouble = false;
      for (let i = value.length - 1; i >= 0; i--) {
        let digit = parseInt(value.charAt(i), 10);
        if (shouldDouble) {
          if ((digit *= 2) > 9) digit -= 9;
        }
        sum += digit;
        shouldDouble = !shouldDouble;
      }
      return sum % 10 === 0;
    })
    .test(
      'payment-method',
      '¡Ups! Parece que no podemos procesar este tipo de tarjeta',
      function (value) {
        const paymentMethod = findPaymentMethodForCardNumber(value || '');
        return !!paymentMethod;
      }
    )
    .required('El número de tarjeta es obligatorio'),
  cardholderName: yup
    .string()
    .required('El titular de la tarjeta es obligatorio'),
  securityCode: yup
    .string()
    .matches(/^\d{3,4}$/, 'El código CVC debe tener 3 o 4 números')
    .required('El código de seguridad es obligatorio'),
  cardExpiration: yup
    .string()
    .matches(
      /^(0[1-9]|1[0-2])\/\d{2}$/,
      'Fecha de vencimiento inválida (MM/YY)'
    )
    .required('Este campo es obligatorio')
    .test('expiration-date', 'La tarjeta está vencida', function (value) {
      if (!value) return false;
      const [month, year] = value.split('/');
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;
      if (parseInt(year) < currentYear) return false;
      if (parseInt(year) === currentYear && parseInt(month) < currentMonth)
        return false;
      return true;
    }),
});

interface ICheckoutMobileSchema
  extends yup.InferType<typeof checkoutMobileSchema> { }

export { checkoutMobileSchema, type ICheckoutMobileSchema };
