import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useColorModeValue,
  FormErrorMessage,
} from '@chakra-ui/react';
import * as React from 'react';
import { FormikProps } from 'formik';

interface PaymentInformationFormValues {
  card_number: string;
  card_name: string;
  card_expiry: string;
  card_cvc: string;
}

const MonthYearInput = (props: any) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const inputValue = event.target.value;
    const formattedValue = inputValue
      .replace(/\D/g, '') // Remove all non-digit characters
      .replace(/(\d{2})(\d{1,2})/, '$1/$2'); // Add a slash separator between the 2nd and 3rd characters
    event.target.value = formattedValue;
    props.onChange(event);
  };

  return (
    <Input {...props} type="text" placeholder="MM/YY" onChange={handleChange} />
  );
};

export const PaymentInformation = (
  props: FormikProps<PaymentInformationFormValues> & any
) => {
  const { touched, errors } = props;
  return (
    <Stack spacing={{ base: '6', md: '10' }}>
      <Heading size="md">Medio de pago</Heading>
      <Stack spacing="6">
        <FormControl
          id="card_number"
          isRequired
          isInvalid={!!errors.card_number && touched.card_number}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Número tarjeta
          </FormLabel>
          <Input
            name="card_number"
            variant="flushed"
            placeholder=""
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.card_number}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.card_number}</FormErrorMessage>
        </FormControl>
        <FormControl
          id="card_name"
          isRequired
          isInvalid={!!errors.card_name && touched.card_name}
        >
          <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
            Nombre titular tarjeta
          </FormLabel>
          <Input
            name="card_name"
            variant="flushed"
            placeholder=""
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.card_name}
            focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
          />
          <FormErrorMessage>{errors.card_name}</FormErrorMessage>
        </FormControl>
        <HStack spacing="6">
          <FormControl
            id="card_expiry"
            width="full"
            isRequired
            isInvalid={!!errors.card_expiry && touched.card_expiry}
          >
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              Válida hasta
            </FormLabel>
            <MonthYearInput
              name="card_expiry"
              variant="flushed"
              placeholder="MM/DD"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.card_expiry}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.card_expiry}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="card_cvc"
            isRequired
            isInvalid={!!errors.card_cvc && touched.card_cvc}
          >
            <FormLabel color={useColorModeValue('gray.700', 'gray.200')}>
              CCV
            </FormLabel>
            <Input
              name="card_cvc"
              variant="flushed"
              placeholder=""
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              value={props.values.card_cvc}
              focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
            />
            <FormErrorMessage>{errors.card_cvc}</FormErrorMessage>
          </FormControl>
        </HStack>
      </Stack>
    </Stack>
  );
};
