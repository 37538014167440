const PRODUCT_STATUS = {
  active: 'Activo',
  paused: 'Pausado',
  discontinued: 'Discontinuado',
} as const;

const DEFAULT_COLORS = ['Blanco', 'Negro', 'Azul', 'Verde', 'Rojo', 'Amarillo'];

const DEFAULT_SIZES = ['XS', 'S', 'M', 'L', 'XL'];

const ATTRIBUTES_DISPLAY: Record<string, any> = {
  color: {
    label: 'Color',
    title: 'Agregar color',
    message: 'Si no encuentras el color que buscas',
    ctaLabel: 'Agregar color personalizado',
  },
  talle: {
    label: 'Talle',
    title: 'Agregar talle',
    message: 'Si no encuentras el talle que buscas',
    ctaLabel: 'Agregar talle personalizado',
  },
};

export { ATTRIBUTES_DISPLAY, DEFAULT_COLORS, DEFAULT_SIZES, PRODUCT_STATUS };
