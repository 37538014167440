import { client, createFormData } from './api-client';

const IMAGES_URL = 'images/';

async function createImageResource(
  entityType: 'product' | 'show' | 'company' | 'user',
  image: File
) {
  const formData = createFormData({ image, resource: entityType });

  const response = await client(IMAGES_URL, {
    data: formData,
    method: 'POST',
  });

  return response.url;
}

export { createImageResource };
