import { IPaymentMethod } from "../../../types/paymentMethods";

// Este array contiene los payment methods que soporta mercadopago
export const paymentMethods: IPaymentMethod[] = [
  {
    id: 'argencard',
    name: 'Argencard',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d7e55980-f3be-11eb-8e0d-6f4af49bf82e-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d7e55980-f3be-11eb-8e0d-6f4af49bf82e-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern: '^(501105)',
          installments_pattern: '^(501105)',
          exclusion_pattern: '^((589562)|(527571)|(527572))',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 1,
    max_allowed_amount: 1500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'debmaster',
    name: 'Mastercard Débito',
    payment_type_id: 'debit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/0daa1670-5c81-11ec-ae75-df2bef173be2-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/0daa1670-5c81-11ec-ae75-df2bef173be2-xl@2x.png',
    deferred_capture: 'unsupported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern:
            '^(546367|557648|511849|517562|528745|549180|528733|551792|553771|553777|553839|511309|514285|514256|526461|514586|514365|559926|559109|559100|557917|551200|541409|539110|536671|536670|536560|533888|533871|533860|533423|531179|531141|530779|522128|518787|515845|505865|505864|505863|232004|557069|555902|536196|531441|501107|501104|230867|230688|555889|551314|526497|524313|511673|542878|535456|222980|527558|547321|544069|535584|535585|250058|547526|514758|511080|514908|525559|542405|553474|553525|554763|557575|558418|558495|559442|544768|546308|552999|511913|67903180|54752600)',
          installments_pattern: '',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
      'issuer_id',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 1440,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'debvisa',
    name: 'Visa Débito',
    payment_type_id: 'debit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d589be70-eb86-11e9-b9a8-097ac027487d-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d589be70-eb86-11e9-b9a8-097ac027487d-xl@2x.png',
    deferred_capture: 'unsupported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern:
            '^(400276|405069|400448|405755|400615|405896|402789|405897|402914|406290|404625|406291|405515|406998|405516|406999|405517|410082|406375|410083|406652|439818|408515|444060|410121|450412|410122|451377|410123|463465|410853|473711|411849|473725|417309|477051|421738|483020|423623|489412|428062|499859|428063|428064|434795|437996|442371|442548|444493|446343|446344|446345|446346|446347|451701|451751|451756|451757|451758|451761|451763|451764|451765|451766|451767|451768|451769|451770|451772|451773|457596|457665|462815|468508|473227|473710|473713|473714|473715|473716|473717|473718|473719|473720|473721|473722|476520|477053|481397|481501|481502|481550|483002|483188|492528|450799|443264|434543|416679|411197|434531|423001|434533|434535|489634|423018|434538|434542|434536|434537|488241|423465|411199|434541|434586|434532|423077|434534|427157|427156|434539|434540|448712|453770|406165|406196|413180|423613|452133|457664|487221|400930|406191|406192|408134|417856|417857|421518|429751|431071|437999|438844|444267|452132|455890|464855|469874|480460|486665|486587|492598|405511|406190|406194|406193|406195|412944|423090|429752|431070|434550|434549|438051|444047|457308|459300|472041|473365|478601|480459|480860|486547|486621|492596|491681|420884|454970|490889|406663|483049|480857|480852|480461|478018|487053|486662|472090|466904|458918|452997|452996|451696|451253|410016|404854|400440|480869|492115|493764|456936|406651|442372|402164|459654|405531|450912|45171700|45478902|47461153|47461149|47461145|45171712|45479315|49190213|45479316|47452318|45171717|45478900|49190211|45171657|45171709|45171649|45171698|46740079|45171342|46366051|47837429|41527795|45171688|45171706|45171707|47452320|45171702|45510383|45479319|45478901|45171713|45479305|45171701|45478121|47452322|47461146|45171648|46366259|47452319|45171343|45171843|45171705|45171998|45171715|46374042|45171502|45171379|45171714|45171718|45199600|45199700)',
          installments_pattern: '',
          exclusion_pattern: '^(491580)',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'master',
    name: 'Mastercard',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/0daa1670-5c81-11ec-ae75-df2bef173be2-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/0daa1670-5c81-11ec-ae75-df2bef173be2-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern:
            '^(5|(2(221|222|223|224|225|226|227|228|229|23|24|25|26|27|28|29|3|4|5|6|70|71|720)))',
          installments_pattern:
            '^(?!(554730|525855|547883|553461|540573|539522|539500|525562|539520|539508|539481|539479|515771|521219|521246|223143|223046|223226|223236|223269|234051|511657|519168|520812|522513|523793|523863|524728|526773|528104|528433|530815|530877|531929|533305|533324|533331|534090|536523|537012|540615|541097|542744|544512|544683|551743|555264|555755|555840|555848|558777|559137|230570|230709|230724|230895|230933|230937|511658|512258|512834|516656|519020|519879|522428|522713|525337|530516|531984|537067|538172|542734|542755|547320|549807|550480|552999|554630|559219|501092|528824))',
          exclusion_pattern:
            '^(555889|504639|504570|542878|532383|515070|515073|560718|551314|526497|524313|559926|559109|559100|557917|551200|541409|539110|536671|536670|536560|533888|533871|533860|533423|531179|531141|530779|522128|518787|515845|505865|505864|505863|232004|557069|555902|536196|532309|531441|530815|522684|501108|501107|501104|230867|230688|593628|592501|593626|514256|514586|526461|511309|514285|501059|557909|589633|553839|553777|553771|551792|528733|549180|528745|517562|511849|557648|546367|501070|601782|508143|501085|501074|501073|501071|501068|501066|589671|588729|501089|501083|501082|501081|501080|501075|501067|501062|501061|501060|501058|501057|501056|501055|501054|501053|501051|501049|501047|501045|501043|501041|501040|501039|501038|501029|501028|501027|501026|501025|501024|501023|501021|501020|501018|501016|501015|589657|589562|501105|557039|550073|562397|566694|566783|568382|569322|504363|504338|504777|511673|514365|534935|222980|504520|544069|527558|511657|535456|535584|535585|250058|547526|514758|511080|514908|525559|542405|553474|553525|554763|557575|558418|558495|559442|527571|544768|504656|501063|504780|527341|511913|588800)',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
      'issuer_id',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'naranja',
    name: 'Naranja',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/770edaa0-5dc7-11ec-a13d-73e40a9e9500-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/770edaa0-5dc7-11ec-a13d-73e40a9e9500-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(589562|527571)',
          installments_pattern: '^(589562|527571)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'tarshop',
    name: 'Tarjeta Shopping',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/33ea00e0-571a-11e8-8364-bff51f08d440-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/33ea00e0-571a-11e8-8364-bff51f08d440-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'none', length: 13 },
        bin: {
          pattern: '^(27995)',
          installments_pattern: '^(27995)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 0,
          card_location: 'back',
          mode: 'optional',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'diners',
    name: 'Diners',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/751ea930-571a-11e8-9a2d-4b2bd7b1bf77-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/751ea930-571a-11e8-9a2d-4b2bd7b1bf77-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 14 },
        bin: {
          pattern: '^((30)|(36)|(38))',
          installments_pattern: '^((360935)|(360936))',
          exclusion_pattern: '^((3646)|(3648))',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'maestro',
    name: 'Maestro',
    payment_type_id: 'debit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/ce454480-445f-11eb-bf78-3b1ee7bf744c-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/ce454480-445f-11eb-bf78-3b1ee7bf744c-xl@2x.png',
    deferred_capture: 'unsupported',
    settings: [
      {
        card_number: { validation: 'none', length: 18 },
        bin: {
          pattern:
            '^(501047|501026|501068|501051|501059|557909|501066|588729|501075|501062|501060|501057|501056|501055|501053|501043|501041|501038|501028|501023|501021|501020|501018|501016|357200|504656|501063|35720001)',
          installments_pattern: '',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
      {
        card_number: { validation: 'none', length: 19 },
        bin: {
          pattern: '^(501068|601782|508143|501081|501080)',
          installments_pattern: '',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 1440,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'cmr',
    name: 'CMR',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/26fbb110-571c-11e8-95d8-631c1a9a92a9-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/26fbb110-571c-11e8-95d8-631c1a9a92a9-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern: '^(557039)',
          installments_pattern: '^(557039)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'amex',
    name: 'American Express',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/b08cf800-4c1a-11e9-9888-a566cbf302df-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/b08cf800-4c1a-11e9-9888-a566cbf302df-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 15 },
        bin: {
          pattern: '^((34)|(37))',
          installments_pattern: '^((34)|(37))',
          exclusion_pattern: null,
        },
        security_code: {
          length: 4,
          card_location: 'front',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'cabal',
    name: 'Cabal',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/c9f71470-6f07-11ec-9b23-071a218bbe35-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/c9f71470-6f07-11ec-9b23-071a218bbe35-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern:
            '^((627170)|(650272)|(589657)|(603522)|(604((20[1-9])|(2[1-9][0-9])|(3[0-9]{2})|(400)))|(36[0-9][0-9][0-9][0-9][0-9][0-9])|(60110[0-9][0-9][0-9])|(6011[2-4][0-9][0-9][0-9])|(601174[0-9][0-9])|(60117[7-9][0-9][0-9])|(6011[8-9][6-9][0-9][0-9])|(6[4-5][4-9][0-9][0-9][0-9][0-9][0-9]))',
          installments_pattern:
            '^(?!(604209|604218|604222|604228|604244|604355|604356|604358|604359|604362|604363|604365|604367|604368|604369|604370|604371|604372|604373|604374|604375|604376|604379|604380|604381|604382|604385|604386|604388|604389|604391))',
          exclusion_pattern: '^(604201|604225|604246|604357|604260|604377)',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'optional',
        },
      },
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(^(677001)|67700110|67700101)',
          installments_pattern: '^(67700110|67700101)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(^(677001)|67700110|67700101)',
          installments_pattern: '^(67700110|67700101)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(677001|67700110|67700101)',
          installments_pattern: '^(67700110|67700101)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(67700110|67700101)',
          installments_pattern: '^(67700110|67700101)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(67700110|67700101)',
          installments_pattern: '^(67700110|67700101)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'cencosud',
    name: 'Cencosud',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/e8ffdc40-5dc7-11ec-ae75-df2bef173be2-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/e8ffdc40-5dc7-11ec-ae75-df2bef173be2-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern: '^(603493)',
          installments_pattern: '^(603493)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'debcabal',
    name: 'Cabal Débito',
    payment_type_id: 'debit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/c9f71470-6f07-11ec-9b23-071a218bbe35-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/c9f71470-6f07-11ec-9b23-071a218bbe35-xl@2x.png',
    deferred_capture: 'unsupported',
    settings: [
      {
        card_number: { validation: 'none', length: 16 },
        bin: {
          pattern: '^(604201|650087|65008700)',
          installments_pattern: '^(604201)',
          exclusion_pattern: null,
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 1440,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
  {
    id: 'visa',
    name: 'Visa',
    payment_type_id: 'credit_card',
    status: 'active',
    secure_thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d589be70-eb86-11e9-b9a8-097ac027487d-xl@2x.png',
    thumbnail:
      'https://http2.mlstatic.com/storage/logos-api-admin/d589be70-eb86-11e9-b9a8-097ac027487d-xl@2x.png',
    deferred_capture: 'supported',
    settings: [
      {
        card_number: { validation: 'standard', length: 16 },
        bin: {
          pattern:
            '^(4|45462200|49603900|48508900|45175900|40806500|44611600|45630700|47801300|49259700|41908000|43306000|48941800|45178600)',
          installments_pattern:
            '^(?!(427836|457309|404031|499877|454621|450913|446116|451759|496039|480724|468549|450811|424969|438050|469283|478527|477169|492499|434948|441046|474531|485947|468574|424968|426618|409230|410352|421541|478017|444268|432250|49603900|48508900|45175900|40806500|44611600|45462200|45630700|47801300|49259700|41908000))',
          exclusion_pattern:
            '^(423270|456936|493764|492115|406663|480460|480459|478601|487221|486665|486547|469874|457664|457308|455890|452133|452132|450799|437999|400930|483049|480860|480857|480852|480461|478018|492598|492596|487053|486662|486587|472090|466904|458918|452997|452996|451696|451253|417857|417856|410016|404854|400440|480869|490889|454970|420884|476520|473713|473227|444493|410122|405517|402789|448712|453770|434541|411199|423465|434540|434542|434538|423018|488241|489634|434537|434539|434536|427156|427157|434535|434534|434533|423077|434532|434586|423001|434531|411197|443264|400276|400615|402914|404625|405069|434543|416679|405515|405516|405755|405896|405897|406290|406291|406375|406652|406998|406999|408515|410082|410083|410121|410123|410853|411849|417309|421738|423623|428062|428063|428064|434795|437996|439818|442371|442548|444060|446343|446344|446347|450412|451377|451701|451751|451756|451757|451758|451761|451763|451764|451765|451766|451767|451768|451769|451770|451772|451773|457596|457665|462815|463465|468508|473710|473711|473712|473714|473715|473716|473717|473718|473719|473720|473721|473722|473725|477051|477053|481397|481501|481502|481550|483002|483020|483188|489412|492528|499859|446345|446346|400448|406651|442372|476940|491681|486621|473365|464855|459300|444267|444047|438844|434550|434549|431071|431070|429752|429751|423613|423090|421528|421518|413180|412944|408134|406196|406195|406194|406193|406192|472042|411763|411764|411765|486568|416861|472041|459654|438051|406191|406190|406165|405511|402164|405531|450912|49190213|49190211|47837429|47461153|47461149|47461146|47461145|47452322|47452320|47452319|47452318|46740079|46374042|46366259|46366051|45510383|45479319|45479316|45479315|45479305|45478902|45478901|45478900|45478121|45171998|45171843|45171718|45171717|45171715|45171714|45171713|45171712|45171709|45171707|45171706|45171705|45171702|45171701|45171700|45171698|45171688|45171657|45171649|45171648|45171502|45171379|45171343|45171342|41527795|45199600|45199700)',
        },
        security_code: {
          length: 3,
          card_location: 'back',
          mode: 'mandatory',
        },
      },
    ],
    additional_info_needed: [
      'cardholder_name',
      'cardholder_identification_type',
      'cardholder_identification_number',
    ],
    min_allowed_amount: 3,
    max_allowed_amount: 2500000,
    accreditation_time: 2880,
    financial_institutions: [],
    processing_modes: ['aggregator'],
  },
];
