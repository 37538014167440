import { useNavigate } from 'react-router-dom';
import { Divider, Flex, Heading } from '@chakra-ui/layout';

import { useAuth } from '../../context/AuthContext';
import { useAlert } from '../../context/AlertContext';
import { useCategories, useProducts } from '../../queries/products';
import { useCreateShow } from '../../queries/shows';
import { getCurrentDateTime } from '../../utils/dates';
import { IShowDataSchema } from './components/validationSchema';
import ShowForm from './components/ShowForm';

const AddShow = () => {
  const { user } = useAuth();
  const { data: categories } = useCategories();
  const { data: products } = useProducts(user!.companyId);
  const { mutate: createShow } = useCreateShow(user!.companyId);

  const navigate = useNavigate();
  const { openAlert } = useAlert();

  const handleSubmit = async (values: IShowDataSchema) => {
    try {
      await createShow(values);
      navigate('/dashboard/shows?status=upcoming');
    } catch (error) {
      console.error(error);
      openAlert({
        status: 'error',
        variant: 'left-accent',
        description: 'Error al crear el show. Inténtalo de nuevo.',
      });
    }
  };

  const hosts = [
    { id: 1, name: 'Martin Valles' },
    { id: 2, name: 'Walter Buccolo' },
  ];

  const initialValues: IShowDataSchema = {
    name: '',
    description: '',
    startDateTime: new Date(getCurrentDateTime()).toLocaleString(),
    category: '',
    duration: 60,
    host: `${user!.firstName} ${user!.lastName}`,
    shippingPrice: 0,
    products: [],
    image: '',
  };

  return (
    categories &&
    products && (
      <Flex
        direction="column"
        gap={{ base: 1, md: 3 }}
        w={{ base: '100%', md: '80%', xl: '60%' }}
      >
        <Heading as="h2" fontSize="3xl">
          Programar un show
        </Heading>
        <Divider />
        <Flex direction="column">
          <ShowForm
            categories={categories}
            ctaLabel="Publicar"
            products={products}
            hosts={hosts}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        </Flex>
      </Flex>
    )
  );
};

export default AddShow;
