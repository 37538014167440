import { Outlet, useNavigation } from 'react-router-dom';
import { Box } from '@chakra-ui/layout';

import CenteredSpinner from '../../components/ui/CenteredSpinner';

const DashboardShows = () => {
  const { state } = useNavigation();

  if (state === 'loading') {
    return <CenteredSpinner />;
  }

  return (
    <Box h="full">
      <Outlet />
    </Box>
  );
};

export default DashboardShows;
