import { Flex, Link } from '@chakra-ui/react';
import { PriceTag } from '../../ui/ProductMeta/PriceTag';
import { ProductMeta } from '../../ui/ProductMeta/ProductMeta';
import { ICartItem } from '../../../types/cart';
import { IProductStock } from '../../../types/product';
import { QuantityStepper } from '../../ui/QuantityStepper/QuantityStepper';
import { useAvailableProductStock } from '../../../hooks/useAvailbleProductStock';

type CartItemProps = {
  cartItem: ICartItem;
  productStock: IProductStock;
  onChangeQuantity?: (quantity: number) => void;
  onClickDelete?: () => void;
};

export const CartItem = (props: CartItemProps) => {
  const {
    cartItem: { quantity },
    productStock: {
      product: { name, description, images },
      price,
      stock: productAvailableStock,
    },
    onChangeQuantity,
    onClickDelete,
  } = props;

  const totalPrice = price * quantity;
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
      align="center"
    >
      <ProductMeta
        name={name}
        image={images[0]}
        description={description}
        price={price}
      />

      {/* Mobile */}
      <Flex mt="4" align="center" width="full" justify="space-between">
        <Link fontSize="sm" textDecor="underline" onClick={onClickDelete}>
          Delete
        </Link>
        <QuantityStepper
          quantity={quantity}
          max={productAvailableStock}
          onChange={
            onChangeQuantity
              ? (quantity) => onChangeQuantity(quantity)
              : undefined
          }
        />
        <PriceTag price={totalPrice} currency="ARS" />
      </Flex>
    </Flex>
  );
};
