import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
} from '@chakra-ui/alert';
import { Box } from '@chakra-ui/layout';
import { CloseButton } from '@chakra-ui/close-button';

interface ToastProps {
  onClose?: () => void;
  status: 'success' | 'error' | 'warning' | 'info';
  description: string;
  isClosable?: boolean;
  title?: string;
}

const Toast: React.FC<ToastProps> = ({
  description,
  onClose,
  isClosable,
  status = 'info',
  title,
}) => {
  
  return (
    <Alert
      status={status}
      borderRadius="lg"
    >
      <AlertIcon />
      <Box>
        {title && <AlertTitle>{title}</AlertTitle>}
        <AlertDescription>{description}</AlertDescription>
      </Box>
      {isClosable && (
        <CloseButton
          alignSelf="flex-start"
          position="relative"
          right={-1}
          top={-1}
          onClick={onClose}
        />
      )}
    </Alert>
  );
};

export default Toast;
export type { ToastProps };
