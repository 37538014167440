import AgoraRTC, {
  AgoraRTCProvider,
  useRTCClient,
  ClientConfig,
} from 'agora-rtc-react';

const agoraClientConfig: ClientConfig = {
  mode: 'rtc',
  codec: 'vp8',
  role: 'host',
};

const AgoraClientProvider = ({ children }: { children: React.ReactNode }) => {
  const agoraEngine = useRTCClient(AgoraRTC.createClient(agoraClientConfig));
  return <AgoraRTCProvider client={agoraEngine}>{children}</AgoraRTCProvider>;
};


export default AgoraClientProvider;
