import { Flex } from '@chakra-ui/layout';
import { TbVideo } from 'react-icons/tb';

import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal';
import EmptyState from '../../../components/ui/EmptyState/EmptyState';
import { useAlertDialog } from '../../../context/AlertDialogContext';
import { useDeleteShow, useShows } from '../../../queries/shows';
import { getBadgeMessage } from '../../../utils/shows';
import CardSkeleton from './CardSkeleton';
import UpcomingShowCard from './UpcomingShowCard';

interface UpcomingShowsSectionProps {
  companyId: number;
}

const UpcomingShowsSection: React.FC<UpcomingShowsSectionProps> = ({
  companyId,
}) => {
  const { openAlertDialog, closeAlertDialog, cancelRef } = useAlertDialog();
  const {
    data: shows,
    isLoading,
    isFetching,
  } = useShows(companyId, {
    queryParams: { status: 'started,created' },
  });
  const { mutate: deleteShow } = useDeleteShow(companyId);

  const handleDeleteClick = async (showId: number) => {
    await deleteShow(showId);
    closeAlertDialog();
  };

  if (isLoading || isFetching) {
    return (
      <Flex direction="column" gap={4}>
        <CardSkeleton />
        <CardSkeleton />
        <CardSkeleton />
      </Flex>
    );
  }

  if (shows?.length === 0) {
    return (
      <EmptyState
        title="No tienes ningun show proximamente"
        message="Crea un show para empezar a vender e interactuar con tus clientes."
        icon={TbVideo}
      />
    );
  }

  return (
    <Flex direction="column" gap={4}>
      {shows?.map((show) => {
        return (
          <UpcomingShowCard
            key={show.id}
            badge={getBadgeMessage(show.startDateTime)}
            onDelete={() =>
              openAlertDialog(
                <DeleteConfirmationModal
                  title="Eliminar show"
                  onClose={() => closeAlertDialog()}
                  onDelete={() => handleDeleteClick(show.id)}
                  cancelRef={cancelRef}
                />
              )
            }
            show={show}
          />
        );
      })}
    </Flex>
  );
};

export default UpcomingShowsSection;
