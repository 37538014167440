import React from 'react';
import { IconType } from 'react-icons/lib';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';

type EmptyStateProps = {
  ctaLabel?: React.ReactNode;
  icon: IconType;
  message?: string;
  title: string;
};

const EmptyState: React.FC<EmptyStateProps> = ({
  ctaLabel,
  icon,
  message,
  title,
}) => {
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap={4}
      h="full"
      bg="white"
      borderRadius="lg"
      p={{ base: 0, md: 5 }}
    >
      <Box borderRadius="full" bg="ui.bg" mb={6}>
        <Icon
          as={icon}
          boxSize="9.25rem"
          strokeWidth="1px"
          color="ui.copy"
          padding={3}
        />
      </Box>
      <Heading as="h3" fontSize="lg" fontWeight="bold">
        {title}
      </Heading>
      <Text color="ui.copy" align="center" maxW="60ch">
        {message}
      </Text>
      {ctaLabel}
    </Flex>
  );
};

export default EmptyState;
