import React, { useMemo, useState } from 'react';
import { RtmClient } from 'agora-rtm-sdk';

import useAgoraRtm from '../../../hooks/useAgoraRtm';
import { ShowStatus } from '../../../types/show';
import HostChat from './HostChat';
import ViewerChat from './ViewerChat';

interface IAgoraChat {
  channelName: string;
  client: RtmClient;
  displayName?: string;
  token: string;
  variant: 'host' | 'viewer';
  userId: number;
  showStatus: ShowStatus;
}

const AgoraChat = ({
  channelName,
  client,
  displayName = '',
  token,
  userId,
  showStatus,
  variant,
}: IAgoraChat) => {
  // El random user name es el nombre que se usa en la primera
  // instancia del chat, cuando el usuario no ha ingresado su nombre
  // solo para que el chat se conecte correctamente, luego se reemplaza
  // por el nombre que ingresa el usuario. El userName en agora no puede ser vacío.
  const randomUserName = useMemo(
    () => `random-${Math.floor(Math.random() * 10000)}`,
    []
  );
  const username = variant === 'host' ? displayName : randomUserName;
  const { messages, sendChannelMessage } = useAgoraRtm({
    userName: username,
    channelName,
    client,
    token,
    userId,
  });
  const [message, setMessage] = useState('');
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newMessage = event.target.value;
    setMessage(newMessage);
  };

  const handleOnSubmit = (event?: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    if (!message.trim().length) {
      return;
    }
    sendChannelMessage(message);
    setMessage('');
  };

  return (
    <>
      {variant === 'host' ? (
        <HostChat
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          message={message}
          messages={messages}
          username={username}
          isDisabled={showStatus === 'ended'}
        />
      ) : (
        <ViewerChat
          onChange={handleOnChange}
          onSubmit={handleOnSubmit}
          message={message}
          messages={messages}
          username="viewer name"
        />
      )}
    </>
  );
};

export default AgoraChat;
