import { useEffect, useState } from 'react';
import { ICartItem, ICartItemProduct } from '../types/cart';
import { IProductStock } from '../types/product';

interface IAvailableProductStockState {
  cartItems: ICartItem[];
  productStock: IProductStock;
}


export const useAvailableProductStock = ({
  cartItems,
  productStock,
}: IAvailableProductStockState) => {
  const [productAvailableStock, setProductAvailableStock] = useState<number>(1);

  useEffect(() => {
    const stock = productStock.stock;
    const cartItem = cartItems.find(
      (cartItem: ICartItem) => cartItem.productId === productStock.product.id
    );

    const calculatedProductAvailableStock = Math.max(0, stock - (cartItem?.quantity || 0));

    setProductAvailableStock(calculatedProductAvailableStock);
  }, [cartItems, productStock]);

  return { productAvailableStock };
};
