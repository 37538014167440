import { Icon } from '@chakra-ui/icon';
import { VStack } from '@chakra-ui/layout';
import {
  TbHome,
  TbMessageCircle2,
  TbPackage,
  TbSettings,
  TbStack2,
  TbUsers,
  TbVideo,
} from 'react-icons/tb';
import NavLink from './NavLink';

const NavLinks: React.FC = () => {
  return (
    <VStack
      as="nav"
      id="navbar"
      gap={4}
      align="start"
      color="ui.copy-dark"
      py={{ base: 0, md: 5 }}
      pr={{ base: 0, md: 8 }}
    >
      <NavLink icon={<Icon as={TbHome} />} label="Panel" url="/dashboard" />

      <NavLink
        icon={<Icon as={TbVideo} />}
        label="Shows"
        url="shows?status=upcoming"
      />
      <NavLink icon={<Icon as={TbUsers} />} label="Audiencia" url="audience" />
      <NavLink
        icon={<Icon as={TbMessageCircle2} />}
        label="Mensajes"
        url="messages"
      />
      <NavLink icon={<Icon as={TbPackage} />} label="Órdenes" url="orders" />
      <NavLink icon={<Icon as={TbStack2} />} label="Productos" url="products" />
      <NavLink
        icon={<Icon as={TbSettings} />}
        label="Configuracion"
        url="settings"
      />
    </VStack>
  );
};

export default NavLinks;
