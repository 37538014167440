import React from 'react';
import { IconButton } from '@chakra-ui/react';

interface FabProps {
  icon: JSX.Element;
  label: string;
  isDisabled?: boolean;
  onClick: () => any;
}

const Fab: React.FC<FabProps> = ({
  icon,
  label,
  isDisabled = false,
  onClick,
}) => {
  return (
    <IconButton
      aria-label={label}
      bg="rgba(53,57,64, 0.55)"
      border="none"
      color="white"
      isDisabled={isDisabled}
      isRound={true}
      icon={icon}
      onClick={onClick}
      variant="outline"
      width="44px"
      height="44px"
      _hover={{
        bg: 'rgba(53,57,64, 0.75)',
      }}
    />
  );
};

export default Fab;
