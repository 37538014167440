import React, { useEffect, useRef, useState } from 'react';
import { useFormikContext } from 'formik';
import { TbPlus } from 'react-icons/tb';
import { Box, Flex, Heading, Text } from '@chakra-ui/layout';
import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';

import { capitalizeString } from '../../../utils/transformations';
import {
  generateVariants,
  removeAttributeValue,
} from '../../../utils/products';
import { PropertyChange } from '../../../types/product';
import VariantTag from './VariantTag';
import VariantsTable from './VariantsTable';
import { IProductDataSchema } from './validationSchema';

interface VariantSectionProps {
  onOpenVariantDrawer: () => void;
}

const VariantSection: React.FC<VariantSectionProps> = ({
  onOpenVariantDrawer,
}) => {
  const {
    values: { attributes, variants },
    setFieldValue,
  } = useFormikContext<IProductDataSchema>();
  const [defaultPrice, setDefaultPrice] = useState(variants[0]?.price);
  const [defaultStock, setDefaultStock] = useState(variants[0]?.stock);

  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      setFieldValue(
        'variants',
        generateVariants(attributes, { defaultPrice, defaultStock })
      );
    }
  }, [attributes, setFieldValue]);

  useEffect(() => {
    setDefaultPrice(variants[0]?.price);
  }, [variants]);

  useEffect(() => {
    setDefaultStock(variants[0]?.stock);
  }, [variants]);

  const handleSubmit = ({ index, property, value }: PropertyChange) => {
    const updatedVariants = [...variants];
    updatedVariants[index] = {
      ...updatedVariants[index],
      [property]: value,
    };
    setFieldValue('variants', updatedVariants);
  };

  return (
    <Box
      flex={1}
      bg="white"
      border="1px"
      borderColor="ui.light-gray"
      borderRadius="lg"
      py={4}
      px={5}
      gap={4}
    >
      <Heading as="h3" size="md" fontWeight="semibold">
        Variantes
      </Heading>
      <Flex direction="column" gap={4} py={2}>
        {attributes.map((attribute) => (
          <Box key={attribute.name}>
            <Heading size="sm" pb={2}>
              {capitalizeString(attribute.name)}
            </Heading>
            <Flex gap={2}>
              {attribute.values.map((value) => (
                <VariantTag
                  key={value}
                  label={value ?? ''}
                  onClose={() => {
                    setFieldValue(
                      'attributes',
                      removeAttributeValue(attributes, {
                        attributeName: attribute.name,
                        attributeValue: value,
                      })
                    );
                  }}
                />
              ))}
            </Flex>
          </Box>
        ))}
        {attributes.length === 0 && (
          <Text fontSize="sm" color="ui.copy">
            Combiná diferentes propiedades de tu producto. Ejemplo: color +
            tamaño.
          </Text>
        )}
      </Flex>
      <Box py={4}>
        <Button
          variant="tertiary"
          border="none"
          px={0}
          onClick={onOpenVariantDrawer}
        >
          <Icon as={TbPlus} size="XL" />
          Agregar variante
        </Button>
      </Box>
      {attributes.length > 0 && (
        <VariantsTable variants={variants} onSubmit={handleSubmit} />
      )}
    </Box>
  );
};

export default VariantSection;
