import { client } from './api-client';
import { snakeKeysToCamel } from './transformations';

type UserCredential = {
  email: string;
  password: string;
};

const AUTHENTICATION_URL = 'auth/convert-token/';

function getTokenClient(accessToken: string) {
  return client(AUTHENTICATION_URL, {
    data: {
      token: accessToken,
      grant_type: 'convert_token',
      client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
      client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
      backend: 'google-oauth2',
    },
    method: 'POST',
  });
}

const USERS_ME_URL = 'users/seller/me/';

function getUserData(tokenData: { accessToken: string; refreshToken: string }) {
  return client(USERS_ME_URL, {
    token: tokenData.accessToken,
  })
    .then((userData) => {
      const user = snakeKeysToCamel({ ...userData, token: { ...tokenData } });
      return user;
    })
    .catch((error) => {
      console.error('Error getting user data', error);
    });
}

function getUserToken(credentials: UserCredential) {
  return client('auth/token/', {
    method: 'POST',
    data: credentials,
  });
}

function logoutUser(userToken: string) {
  return client('auth/revoke-token/', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
    data: {
      token: userToken,
      client_id: 'client_id',
      client_secret: 'client_secret',
    },
  });
}

export { getTokenClient, getUserData, getUserToken, logoutUser };
