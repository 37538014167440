import { Icon } from '@chakra-ui/icon';
import {
  Box,
  Center,
  Flex,
  Text,
  Stack,
  StackDivider,
} from '@chakra-ui/layout';
import { Stat, StatLabel, StatNumber } from '@chakra-ui/stat';

import { Metric } from '../../../types/metric';

type CompanyMetricsProps = {
  metrics: Metric[];
  isDisabled?: boolean;
};

const CompanyMetrics: React.FC<CompanyMetricsProps> = ({
  metrics,
  isDisabled = false,
}) => {
  return (
    <Box position="relative">
      <Stack
        direction={['column', 'row']}
        spacing={3}
        divider={<StackDivider />}
        m={5}
        wrap="wrap"
        gap={3}
      >
        {metrics.map(({ label, value, icon }) => {
          return (
            <Stat key={label}>
              <StatLabel>
                <Flex align="flex-start" gap={3}>
                  <Flex borderRadius="lg" bg="brand.primary.500" p={1}>
                    <Icon as={icon} boxSize={4} color="white" />
                  </Flex>
                  <Text fontWeight="semibold">{label}</Text>
                </Flex>
              </StatLabel>
              <StatNumber fontWeight="semibold" fontSize="3xl">
                {value}
              </StatNumber>
            </Stat>
          );
        })}
      </Stack>
      {isDisabled && (
        <Center
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="rgba(255, 255, 255, 0.7)"
          backdropFilter="blur(3px)"
        >
          <Text fontSize="2xl" fontWeight="bold" color="ui.copy">
            Proximamente
          </Text>
        </Center>
      )}
    </Box>
  );
};

export default CompanyMetrics;
