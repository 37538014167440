import * as yup from 'yup';

const showDataSchema = yup.object().shape({
  name: yup.string().trim().required('Debe ingresar un nombre'),
  description: yup.string().trim().required(),
  startDateTime: yup
    .string()
    .required('Debe ingresar una fecha de inicio')
    .test(
      'is-future-date',
      'El show no puede iniciar en el pasado',
      (value) => {
        const startDate = new Date(value);
        const minDate = new Date();
        return startDate.getTime() >= minDate.getTime();
      }
    ),
  category: yup.string().required('Debe seleccionar una categoría'),
  duration: yup.number().min(0).max(60).required('Debe ingresar una duración'),
  host: yup.string().optional(),
  image: yup.string().required(),
  products: yup
    .array()
    .of(
      yup.object().shape({
        productId: yup.number().required('Product ID is required'),
        visible: yup.boolean().required('Visibility is required'),
      })
    )
    .required('Products are required'),
  shippingPrice: yup.number().optional(),
});

interface IShowDataSchema extends yup.InferType<typeof showDataSchema> {}

export { showDataSchema, type IShowDataSchema };
