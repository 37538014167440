import { useState, useEffect } from 'react';
import {
  IAgoraRTCRemoteUser,
  IAgoraRTCClient,
  ConnectionState,
  ConnectionDisconnectedReason,
} from 'agora-rtc-react';

interface IAgoraVideoUserStatusParams {
  appId: string;
  token: string;
  channelName: string;
  client: IAgoraRTCClient;
  uid: number;
}

interface IAgoraVideoUserStatus {
  user: IAgoraRTCRemoteUser | undefined;
  isReady: boolean;
  connectionState: ConnectionState;
}

const useAgoraVideoUserStatus = ({
  appId,
  token,
  channelName,
  client,
  uid,
}: IAgoraVideoUserStatusParams): IAgoraVideoUserStatus => {
  const [user, setUser] = useState<IAgoraRTCRemoteUser | undefined>(undefined);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [connectionState, setConnectionState] =
    useState<ConnectionState>('DISCONNECTED');

  useEffect(() => {
    console.log('useAgoraVideoUserStatus', appId, token, channelName, client);
    // function to initialise the SDK
    const init = async (name: string) => {
      client.on(
        'user-published',
        async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
          await client.subscribe(user, mediaType);
          console.log('subscribe success');
          if (mediaType === 'video') {
            setUser(user);
          }
          if (mediaType === 'audio') {
            user.audioTrack?.play();
          }
        }
      );

      client.on(
        'user-unpublished',
        (user: IAgoraRTCRemoteUser, mediaType: string) => {
          console.log('unpublished', user, mediaType);
          if (mediaType === 'audio') {
            user.audioTrack?.stop();
          }
          if (mediaType === 'video') {
            setUser(undefined);
          }
        }
      );

      client.on('user-left', (user: IAgoraRTCRemoteUser) => {
        console.log('leaving', user);
        setUser(user);
      });

      client.on(
        'connection-state-changed',
        (
          curState: ConnectionState,
          revState: ConnectionState,
          reason?: ConnectionDisconnectedReason
        ) => {
          console.log('connection-state-changed', curState, revState, reason);
          setConnectionState(curState);
        }
      );
      // check if the agora client is connected
      try {
        await client.join(appId, name, token, uid);
        setIsReady(true);
      } catch (error) {
        console.error('error', error);
      }
    };
    const leave = async () => {
      await client.leave();
      setIsReady(false);
    };
    init(channelName);
    return () => {
      // function to unmount the SDK
      leave();
    };
  }, [appId, token, channelName, client, uid]);

  return { user, isReady, connectionState };
};

export default useAgoraVideoUserStatus;
