import { TbClipboardList } from 'react-icons/tb';

import CenteredSpinner from '../../../components/ui/CenteredSpinner';
import EmptyState from '../../../components/ui/EmptyState/EmptyState';
import { useShowOrders } from '../../../queries/orders';
import ShowOrdersTable from './ShowOrdersTable';

interface ShowOrdersSectionProps {
  showId: number;
}

const ShowOrdersSection: React.FC<ShowOrdersSectionProps> = ({ showId }) => {
  const { data: orders, isLoading } = useShowOrders(showId);

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (orders.length === 0) {
    return (
      <EmptyState
        title="Todavía no tenés ninguna orden"
        icon={TbClipboardList}
      />
    );
  }

  return <ShowOrdersTable orders={orders} />;
};

export default ShowOrdersSection;
