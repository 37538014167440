import {
  RouterProvider,
  createBrowserRouter,
  Navigate,
  useLocation,
} from 'react-router-dom';

import DashboardLayout from './components/layouts/DashboardLayout';
import DashboardErrorState from './components/ui/ErrorState/DashboardErrorState';
import { RootErrorBoundary } from './components/ui/ErrorState/RootErrorBoundary';
import RootLayout from './pages/RootLayout';
import StaticPages from './pages/BuilderIo/StaticPages';
import Login from './pages/Login/Login';
import AudiencePage from './pages/Audience/AudiencePage';
import MessagesPage from './pages/Messages/MessagesPage';
import DashboardProducts from './pages/Products/DashboardProducts';
import ProductsView from './pages/Products/ProductsView';
import AddProduct from './pages/Products/AddProduct';
import EditProduct from './pages/Products/EditProduct';
import DashboardShows from './pages/Shows/DashboardShows';
import ShowsView from './pages/Shows/ShowsView';
import AddShow from './pages/Shows/AddShow';
import EditShow from './pages/Shows/EditShow';
import DashboardOrders from './pages/Orders/DashboardOrders';
import OrdersView from './pages/Orders/OrdersView';
import OrderPage from './pages/Orders/OrderPage';
import Profile from './pages/Profile/Profile';
import EditProfile from './pages/Profile/EditProfile';
import DashboardSettings from './pages/Settings/DashboardSettings';
import LiveShowView from './pages/Shows/LiveShowView';
import { LiveShowScreen } from './pages/LiveShow';
import { CheckoutMobileScreen } from './pages/CheckoutMobile/CheckoutMobile';
import ContactScreen from './pages/Contact/Contact';
import { useAuth } from './context/AuthContext';
import HomePage from './pages/Homepage/Homepage';
import RedirectStore from './pages/LandingMobile/RedirectStore';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <RootErrorBoundary />,
    children: [
      {
        index: true,
        element: <StaticPages />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'checkout-mobile',
        element: <CheckoutMobileScreen />,
      },
      {
        path: 'contact',
        element: <ContactScreen />,
      },
      {
        path: 'app/home/:deeplink/:id',
        element: <RedirectStore />,
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: 'audience',
            element: <AudiencePage />,
            errorElement: <DashboardErrorState />,
          },
          {
            path: 'messages',
            element: <MessagesPage />,
          },
          {
            path: 'orders',
            element: <DashboardOrders />,
            errorElement: <DashboardErrorState />,
            children: [
              { index: true, element: <OrdersView /> },
              {
                path: ':orderId',
                element: <OrderPage />,
              },
            ],
          },
          {
            path: 'profile',
            children: [
              {
                index: true,
                element: <Profile />,
              },
              {
                path: 'edit',
                element: <EditProfile />,
              },
            ],
          },
          {
            path: 'products',
            element: <DashboardProducts />,
            errorElement: <DashboardErrorState />,
            children: [
              {
                index: true,
                element: <ProductsView />,
              },
              {
                path: 'create',
                element: <AddProduct />,
              },
              {
                path: ':productId/edit',
                element: <EditProduct />,
              },
            ],
          },
          {
            path: 'settings',
            element: <DashboardSettings />,
            errorElement: <DashboardErrorState />,
          },
          {
            path: 'shows',
            element: <DashboardShows />,
            errorElement: <DashboardErrorState />,
            children: [
              {
                index: true,
                element: <ShowsView />,
              },
              {
                path: 'create',
                element: <AddShow />,
              },
              {
                path: ':showSlugName',
                element: <LiveShowView />,
              },
              {
                path: ':showId/edit',
                element: <EditShow />,
              },
            ],
          },
        ],
      },
      {
        path: 'audience/show/:showSlugName',
        element: <LiveShowScreen />,
      },
      {
        path: '*',
        element: <StaticPages />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
