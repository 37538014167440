import { ICompany } from './company';
import { IProduct } from './product';

export interface ICardholderIdentification {
  type: string;
  value: string;
}

export interface ICardholder {
  name: string;
  identification: ICardholderIdentification;
}

export interface ICreditCard {
  number: string;
  expiration_month: string;
  expiration_year: string;
  cvv: string;
  payment_method_id: string;
  cardholder: ICardholder;
}

export interface IPayment {
  payment_method: string;
  installments: number;
  credit_card: ICreditCard;
}

export interface ICustomer {
  first_name: string;
  last_name: string;
  email: string;
}

export interface IShippingAddress {
  address: string;
  city: string;
  state: string;
  zip: string;
}

export interface ICartItem {
  product_id: string;
  quantity: number;
  price: number;
}

export interface ICart {
  items: ICartItem[];
}

export interface IOrderData {
  show_id: string;
  cart: ICart;
  payment: IPayment;
  customer: ICustomer;
  shipping_address: IShippingAddress;
}

// Create enum for order status with success and failure
export enum EOrderStatus {
  SUCCESS = 'completed',
  FAILURE = 'failed',
}

// Create enum for order status with success and failure
export enum PaymentStatus {
  SUCCESS = 'completed',
  FAILURE = 'failed',
}

export interface IOrder {
  order_id: string;
  show_id: string;
  order_total: number;
  order_status: EOrderStatus;
  payment_status: PaymentStatus;
  cart: ICart;
  payment: IPayment;
  customer: ICustomer;
  shipping_address: IShippingAddress;
}

type ProductItem = {
  id: number;
  variantId: number;
  quantity: number;
  price: string;
  product: IProduct;
};

type Payment = {
  installments: number;
  paymentStatus: string;
  paymentMethod: string;
};

type Customer = {
  firstName: string;
  lastName: string;
  email: string;
  phone: {
    number: string;
    areaCode: string;
  };
  identification: {
    type: string;
    number: string;
  };
};

type Address = {
  id: number;
  recipientFirstName: string;
  recipientLastName: string;
  recipientPhoneNumber: string;
  optionalData: string;
  street: string;
  number: string;
  zipCode: string;
  state: string;
  city: string;
  default: boolean;
};

type Shipping = {
  serviceType: string;
  address: Address;
  order: {
    packageLabelUrl: string;
    status: 'created' | 'completed' | 'failed' | 'declined' | 'requested';
    externalId: number;
  };
};

type OrderStatus =
  | 'paid'
  | 'pending_payment'
  | 'pending_shipping'
  | 'pending_pickup'
  | 'shipping_failed'
  | 'payment_failed'
  | 'shipped'
  | 'completed_pickup'
  | 'delivered'
  | 'canceled';

type Order = {
  id: number;
  showId: number;
  company: ICompany;
  customer: Customer;
  datetime: string;
  items: ProductItem[];
  payment: Payment;
  shipping: Shipping;
  subtotal: string;
  status: OrderStatus;
  subRow?: ProductItem[];
  total: string;
};

export type {
  Address,
  Order,
  OrderStatus,
  ProductItem,
  Payment,
  Customer,
  Shipping,
};
