import { AbsoluteCenter, Box } from '@chakra-ui/layout';
import { Spinner } from '@chakra-ui/spinner';

const CenteredSpinner = () => {
  return (
    <Box position="relative" h={'100vh'}>
      <AbsoluteCenter axis="both">
        <Spinner size="xl" />
      </AbsoluteCenter>
    </Box>
  );
};

export default CenteredSpinner;
