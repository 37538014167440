import {
  Box,
  Image,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { formatPrice } from './PriceTag';

export type CartProductMetaProps = {
  name: string;
  description?: string;
  price?: number;
  image: string;
};

export const ProductMeta = ({
  image,
  name,
  description,
  price,
}: CartProductMetaProps) => {
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        rounded="lg"
        boxSize="100px"
        minWidth="100px"
        minHeight="100px"
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        objectFit="contain"
      />
      <Box>
        <Stack spacing="0.5">
          <Text fontWeight="medium">{name}</Text>
          {description && (
            <Text color={mode('gray.600', 'gray.400')} noOfLines={1}>
              {description}
            </Text>
          )}
          {price && (
            <Text color="brand.red" fontWeight="medium" fontSize="sm">
              {formatPrice(price)}
            </Text>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};
