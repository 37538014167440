import { Avatar } from '@chakra-ui/avatar';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
} from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';

import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../queries/company';
import { Link as RouterLink } from 'react-router-dom';
import { TbPencil } from 'react-icons/tb';
import { Card, CardBody, CardHeader } from '@chakra-ui/react';
import CenteredSpinner from '../../components/ui/CenteredSpinner';

const ProfilePage = () => {
  const { user } = useAuth();
  const {
    data: company,
    error,
    isLoading,
    isError,
  } = useCompany(user!.companyId);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Box bg="white" borderRadius="lg" px="5" py="3" height="full">
      <Flex justifyContent="space-between" py="4">
        <Heading as="h2" color="brand.green" fontWeight="semibold">
          Perfil
        </Heading>
      </Flex>
      {company && (
        <Grid templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }} gap={4}>
          <GridItem>
            <Card h="full" backgroundColor="ui.light-gray">
              <CardHeader>
                <Flex direction="column" align="center" gap={3}>
                  <Avatar name={company.name} src={company.logo} size="xl" />
                  <Text fontSize="xl" fontWeight="bold">
                    {company.name}
                  </Text>
                  <Link as={RouterLink} to="edit">
                    <Icon as={TbPencil} />
                    Editar
                  </Link>
                </Flex>
              </CardHeader>
              <CardBody>
                <Text>{company.description}</Text>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }}>
            <Card>
              <CardHeader>
                <Text fontWeight="bold" fontSize="2xl">
                  Detalles
                </Text>
              </CardHeader>
              <CardBody>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem colSpan={2}>
                    <Flex direction="column" gap={2}>
                      <Text fontWeight="semibold">Razón social:</Text>
                      <Text>{company.businessName}</Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <Flex direction="column" gap={2}>
                      <Text fontWeight="semibold">CUIT:</Text>
                      <Text>{company.cuit}</Text>
                    </Flex>
                  </GridItem>
                  <GridItem>
                    <Flex direction="column" gap={2}>
                      <Text fontWeight="semibold">
                        Condición frente al IVA:
                      </Text>
                      <Text>{company.vatCondition}</Text>
                    </Flex>
                  </GridItem>
                </Grid>
              </CardBody>
            </Card>
          </GridItem>
          {company.address && (
            <GridItem colSpan={{ base: 1, md: 3 }}>
              <Card>
                <CardHeader>
                  <Text fontWeight="bold" fontSize="2xl">
                    Domicilio
                  </Text>
                </CardHeader>
                <CardBody>
                  <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                    <GridItem colSpan={2}>
                      <Flex direction="column" gap={2}>
                        <Text fontWeight="semibold">Calle y número:</Text>
                        <Text>{`${company.address.street} ${company.address.number}, ${company.address.city}`}</Text>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction="column" gap={2}>
                        <Text fontWeight="semibold">Código postal:</Text>
                        <Text>{company.address.zipCode}</Text>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction="column" gap={2}>
                        <Text fontWeight="semibold">Provincia:</Text>
                        <Text>{company.address.province}</Text>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction="column" gap={2}>
                        <Text fontWeight="semibold">País:</Text>
                        <Text>{company.address.country}</Text>
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex direction="column" gap={2}>
                        <Text fontWeight="semibold">Es domicilio fiscal:</Text>
                        <Text>
                          {company.address.isTaxAddress ? 'Sí' : 'No'}
                        </Text>
                      </Flex>
                    </GridItem>
                  </Grid>
                </CardBody>
              </Card>
            </GridItem>
          )}
        </Grid>
      )}
    </Box>
  );
};

export default ProfilePage;
