import React from 'react';
import { Flex } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { TbAlertTriangle } from 'react-icons/tb';

import ErrorState from './ErrorState';

const DashboardErrorState: React.FC = () => {
  return (
    <ErrorState
      title="Algo salió mal"
      image={
        <Flex
          bg="yellow.100"
          borderRadius="full"
          w="180px"
          height="180px"
          alignItems="center"
          justifyContent="center"
        >
          <Icon as={TbAlertTriangle} boxSize="92px" color="orange.200" />
        </Flex>
      }
      message="La página que buscas no existe o no está disponible ahora. Te sugerimos hacer click abajo para intentarlo de nuevo."
    />
  );
};

export default DashboardErrorState;
