import { OrderStatus } from '../types/order';

/**
 * Returns the label corresponding to the given status.
 * @param status - The status string.
 * @returns The label for the status.
 */
function getStatusLabel(status: string): string {
  switch (status) {
    case 'paid':
      return 'Pagado';
    case 'pending_payment':
      return 'Pago pendiente';
    case 'pending_shipping':
      return 'Envío pendiente';
    case 'pending_pickup':
      return 'Retiro pendiente';
    case 'payment_failed':
      return 'Pago fallido';
    case 'shipping_failed':
      return 'Envío fallido';
    case 'shipped':
      return 'Enviado';
    case 'delivered':
      return 'Entregado';
    case 'canceled':
      return 'Cancelado';
    default:
      return 'Desconocido';
  }
}

/**
 * Returns the tag color corresponding to the given status.
 * @param status - The status string.
 * @returns The color for the tag.
 */
function getStatusTagColor(status: OrderStatus): string {
  switch (status) {
    case 'paid':
    case 'shipped':
    case 'delivered':
      return 'green';
    case 'payment_failed':
    case 'shipping_failed':
    case 'canceled':
      return 'red';
    case 'pending_payment':
    case 'pending_shipping':
    case 'pending_pickup':
      return 'orange';
    default:
      return 'gray';
  }
}

export { getStatusLabel, getStatusTagColor };
