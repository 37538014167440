import { createContext, ReactNode, useContext } from 'react';
// import { ShoppingCart } from "../components/ShoppingCart"
import { useLocalStorage } from '../hooks/useLocalStorage';

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type ICartItem = {
  productId: number;
  quantity: number;
};

type IShowCart = {
  showId: number;
  isOpen: boolean;
  cartItems: ICartItem[];
};

type IShoppingCartContext = {
  openCart: (showId: number) => void;
  closeCart: (showId: number) => void;
  getItemQuantity: (showId: number, productId: number) => number;
  updateCartQuantity: (
    showId: number,
    productId: number,
    quantity: number
  ) => void;
  increaseCartQuantity: (
    showId: number,
    productId: number,
    quantity: number
  ) => void;
  removeFromCart: (showId: number, productId: number) => void;
  getCartQuantityTotal: (showId: number) => number;
  getCartItems: (showId: number) => ICartItem[];
  setShowCarts: (showCarts: IShowCart[]) => void;
  removeShowCart: (showId: number) => void;
};

const ShoppingCartContext = createContext({} as IShoppingCartContext);
ShoppingCartContext.displayName = 'ShoppingCartContext';

export function useShoppingCart() {
  const context = useContext(ShoppingCartContext);
  if (context === undefined) {
    throw new Error(
      `useShoppingCart must be used within a ShoppingCartContext`
    );
  }
  return context;
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [showCarts, setShowCarts] = useLocalStorage<IShowCart[]>(
    'shopping-cart',
    []
  );
  const getShowCart = (showId: number) => {
    let showCart = showCarts.find((cart: IShowCart) => cart.showId === showId);
    if (showCart == null) {
      showCart = { showId, isOpen: true, cartItems: [] };
      setShowCarts((currCarts: IShowCart[]) => [...currCarts, showCart!]);
    }
    return showCart;
  };

  const openCart = (showId: number) => {
    setShowCarts((currCarts: IShowCart[]) => {
      return currCarts.map((cart) => {
        if (cart.showId === showId) {
          return { ...cart, isOpen: true };
        } else {
          return cart;
        }
      });
    });
  };

  const closeCart = (showId: number) => {
    setShowCarts((currCarts: IShowCart[]) => {
      return currCarts.map((cart) => {
        if (cart.showId === showId) {
          return { ...cart, isOpen: false };
        } else {
          return cart;
        }
      });
    });
  };

  const getItemQuantity = (showId: number, productId: number) => {
    const showCart = getShowCart(showId);
    return (
      showCart.cartItems.find((item: ICartItem) => item.productId === productId)
        ?.quantity || 0
    );
  };

  const updateCartQuantity = (
    showId: number,
    productId: number,
    quantity: number
  ) => {
    setShowCarts((currCarts: IShowCart[]) => {
      return currCarts.map((cart) => {
        if (cart.showId === showId) {
          const cartItems = cart.cartItems || [];
          const cartItem = cartItems.find(
            (item: ICartItem) => item.productId === productId
          );
          if (cartItem == null) {
            return {
              ...cart,
              cartItems: [
                ...cartItems,
                { productId, quantity: Math.max(quantity, 0) },
              ],
            };
          } else {
            return {
              ...cart,
              cartItems: cartItems.map((item: ICartItem) => {
                if (item.productId === productId) {
                  return { ...item, quantity: Math.max(quantity, 0) };
                } else {
                  return item;
                }
              }),
            };
          }
        } else {
          return cart;
        }
      });
    });
  };

  const increaseCartQuantity = (
    showId: number,
    productId: number,
    quantity: number
  ) => {
    const showCart = getShowCart(showId);
    const currentQuantity =
      showCart.cartItems.find((item: ICartItem) => item.productId === productId)
        ?.quantity || 0;
    updateCartQuantity(showId, productId, currentQuantity + quantity);
  };

  const removeFromCart = (showId: number, productId: number) => {
    setShowCarts((currCarts: IShowCart[]) => {
      return currCarts.map((cart) => {
        if (cart.showId === showId) {
          return {
            ...cart,
            cartItems: cart.cartItems.filter(
              (item: ICartItem) => item.productId !== productId
            ),
          };
        } else {
          return cart;
        }
      });
    });
  };

  const getCartItems = (showId: number): ICartItem[] => {
    const showCart = getShowCart(showId);
    return showCart.cartItems;
  };

  const getCartQuantityTotal = (showId: number): number => {
    const showCart = getShowCart(showId);
    const cartItems = showCart.cartItems || [];
    const cartQuantity = cartItems.reduce(
      (quantity: number, item: ICartItem) => item.quantity + quantity,
      0
    );
    return cartQuantity;
  };

  const removeShowCart = (showId: number) => {
    setShowCarts((currCarts: IShowCart[]) => {
      return currCarts.filter((cart) => cart.showId !== showId);
    });
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        getItemQuantity,
        updateCartQuantity,
        increaseCartQuantity,
        removeFromCart,
        openCart,
        closeCart,
        getCartItems,
        getCartQuantityTotal,
        removeShowCart,
        setShowCarts, // TODO: remove this, only for testing
      }}
    >
      {children}
      {/* <ShoppingCart isOpen={isOpen} /> */}
    </ShoppingCartContext.Provider>
  );
}
