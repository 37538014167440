import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/tag';
import { Icon } from '@chakra-ui/icon';
import { TbCircleFilled } from 'react-icons/tb';

interface OrderStatusTagProps {
  status: string;
  variant: string;
}

const OrderStatusTag: React.FC<OrderStatusTagProps> = ({ status, variant }) => (
  <Tag borderRadius="full" colorScheme={variant}>
    <TagLeftIcon boxSize="12px">
      <Icon as={TbCircleFilled} size="full" />
    </TagLeftIcon>
    <TagLabel fontWeight="bold">{status}</TagLabel>
  </Tag>
);

export default OrderStatusTag;
