import {
  extendTheme,
  theme as base,
  withDefaultProps,
  keyframes,
} from '@chakra-ui/react';

const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const theme = extendTheme(
  {
    fonts: {
      body: `Inter, ${base.fonts?.body}`,
    },
    colors: {
      primary: {
        100: '#E5FCF1',
        200: '#27EF96',
        300: '#10DE82',
        400: '#0EBE6F',
        500: '#0CA25F',
        600: '#0A864F',
        700: '#086F42',
        800: '#075C37',
        900: '#064C2E',
      },
      brand: {
        primary: {
          100: '#C510D6',
          200: '#D21CE6',
          300: '#E029F6',
          400: '#F236FF',
          500: '#EC0EE3',
          600: '#A100B2',
          700: '#8F00A2',
          800: '#7C0091',
          900: '#690080',
          1000: '#55006F',
        },
        tertiary: '#780000',
        red: '#C1121F',
        green: '#003049',
        white: '#FBFBFF',
      },
      ui: {
        bg: '#F0F0F0',
        'copy-dark': '#353940',
        copy: '#626973',
        'medium-gray': '#8F99A6',
        'light-gray': '#C7CFD9',
        link: '#488CB7',
        'link-active': '#1F6FD6',
        success: '#84B51C',
        alert: '#EB7100',
        danger: '#C1121F',
        error: '#FF453A',
      },
    },
    components: {
      Button: {
        baseStyle: {
          borderRadius: '27px',
          border: '2px solid transparent',
          fontSize: 'sm',
        },
        variants: {
          primary: {
            bg: 'brand.primary.500',
            color: 'brand.white',
            _hover: {
              bg: 'brand.primary.700',
            },
            _focus: {
              borderColor: 'brand.primary.600',
            },
          },
          secondary: {
            bg: 'ui.gray.100',
            color: 'ui.copy-dark',
            borderColor: 'ui.medium-gray',
            _hover: {
              bg: 'ui.light-gray',
            },
            _focus: {
              borderColor: 'ui.copy-dark',
            },
          },
          disabled: {
            bg: 'ui.gray.100',
            color: 'ui.medium-gray',
          },
          tertiary: {
            color: 'ui.link',
            bg: 'white',
            borderColor: 'ui.light-gray',
            maxHeight: '30px',
            _hover: {
              bg: 'ui.gray.100',
              borderColor: 'ui.light-gray',
            },
            _focus: {
              borderColor: 'ui.copy-dark',
            },
          },
          icon: {
            color: 'white',
            bg: '#35394080',
          },
        },
        defaultProps: {
          variant: 'primary',
        },
      },
      Heading: {
        baseStyle: {
          fontWeight: 'semibold',
        },
      },
      FormLabel: {
        baseStyle: {
          color: 'ui.copy',
          fontSize: 'sm',
          fontWeight: 'semibold',
        },
      },
      Link: {
        baseStyle: {
          color: 'ui.link',
        },
        variants: {
          button: {
            height: 10,
            minWidth: 10,
            padding: '0 1rem',
            borderRadius: '27px',
            border: '2px solid transparent',
            fontWeight: 'semibold',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            bg: 'brand.primary.500',
            color: 'brand.white',
            _hover: {
              bg: 'brand.primary.700',
              textDecoration: 'none',
            },
          },
        },
      },
    },
    keyframes: {
      slideDown,
      slideUp,
    },
    styles: {
      global: {
        '#navbar .active': {
          bg: 'black',
          borderRadius: 'full',
          color: 'white',
          width: 'full',
        },
        '#navbar .navbar-link': {
          width: 'full',
        },
        '#toast-root': {
          position: 'fixed',
          width: '100%',
          zIndex: 1,
          top: 8,
        },
      },
    },
  },
  withDefaultProps({
    defaultProps: {
      errorBorderColor: 'green',
    },
    components: ['Input', 'Textarea', 'Select'],
  })
);

export default theme;
