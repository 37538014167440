import React from 'react';
import { Box, Flex, Icon } from '@chakra-ui/react';
import {
  MdPause,
  MdPlayArrow,
  MdStop,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md';

import Fab from '../../LiveShow/components/Fab';

interface ControlsProps {
  isAudioMuted: boolean;
  isDisabled: boolean;
  isVideoMuted: boolean;
  onMuteClick: () => void;
  onPlaybackClick: () => void;
  onStopClick: () => void;
}

const Controls: React.FC<ControlsProps> = ({
  isAudioMuted,
  isDisabled,
  isVideoMuted,
  onMuteClick,
  onPlaybackClick,
  onStopClick,
}) => {
  const volumeIcon = isAudioMuted ? (
    <Icon as={MdVolumeOff} />
  ) : (
    <Icon as={MdVolumeUp} />
  );
  const playbackIcon = isVideoMuted ? (
    <Icon as={MdPlayArrow} />
  ) : (
    <Icon as={MdPause} />
  );

  return (
    <Flex align="center" justify="center" pt={2}>
      <Box px="2">
        <Fab
          icon={volumeIcon}
          label="Volume control"
          onClick={onMuteClick}
          isDisabled={isDisabled}
        />
      </Box>
      <Box px="2">
        <Fab
          icon={<Icon as={MdStop} />}
          label="Stop"
          onClick={onStopClick}
          isDisabled={isDisabled}
        />
      </Box>
      <Box px="2">
        <Fab
          icon={playbackIcon}
          label="Play/pause"
          onClick={onPlaybackClick}
          isDisabled={isDisabled}
        />
      </Box>
    </Flex>
  );
};

export default Controls;
