import { useEffect, useState, useRef } from 'react';
import { RtmClient, RtmMessage } from 'agora-rtm-sdk';
import { useMemo } from 'react';

// create a function that returns a random color
const randomColor = require('randomcolor');

interface AttributesMap {
  [key: string]: string;
}
export interface IAgoraMessage {
  user: AttributesMap;
  message: string;
}
interface IAgoraRTMParams {
  userName: string;
  channelName: string;
  token: string;
  client: RtmClient;
  userId: number;
}
const useAgoraRtm = ({
  userName,
  channelName,
  client,
  token,
  userId,
}: IAgoraRTMParams) => {
  const [messages, setMessages] = useState<IAgoraMessage[]>([]);
  const [currentMessage, setCurrentMessage] = useState<IAgoraMessage>();

  const channel = useMemo(
    () => client.createChannel(channelName),
    [channelName, client]
  );
  const color = useRef(randomColor({ luminosity: 'dark' })).current;

  useEffect(() => {
    const initRtm = async () => {
      console.log('initRtm', userName, channelName, userId, token);
      await client.login({
        uid: userId.toString(),
        token: token,
      });
      await channel.join();
      await client.setLocalUserAttributes({
        name: userName,
        color,
      });
    };
    const cleanup = async () => {
      console.log('cleanup');
      await channel.leave();
      await client.logout();
    };
    initRtm();
    return () => {
      cleanup();
    };
  }, [channel, channelName, client, color, token, userId, userName]);

  useEffect(() => {
    const handleMessageReceived = async (data: RtmMessage, uid: string) => {
      const user = await client.getUserAttributes(uid);
      console.log('Message received', data);
      if (data.messageType === 'TEXT') {
        const newMessageData = { user, message: data.text };
        setCurrentMessage(newMessageData);
      }
    };

    channel.on('ChannelMessage', (data, uid) => {
      handleMessageReceived(data, uid);
    });
  }, [channel, channelName, client]);

  const sendChannelMessage = async (text: string) => {
    channel
      .sendMessage({ text })
      .then(() => {
        setCurrentMessage({
          user: { name: userName, color },
          message: text,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (currentMessage) {
      setMessages([...messages, currentMessage]);
      setCurrentMessage(undefined);
    }
  }, [currentMessage, messages]);

  return { sendChannelMessage, messages };
};
export default useAgoraRtm;
