import { Link, Navigate } from 'react-router-dom';
import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Image } from '@chakra-ui/image';
import {
  Box,
  Center,
  Container,
  Flex,
  Link as ChakraLink,
  Text,
} from '@chakra-ui/layout';
import { TbBrandGoogle } from 'react-icons/tb';

import LoginForm from './LoginForm';
import { ILoginDataSchema } from './validationSchema';
import { useAuth } from '../../context/AuthContext';
import { useAlert } from '../../context/AlertContext';

const Login = () => {
  const { loginWithCredentials, loginWithGoogle, user } = useAuth();
  const { openAlert } = useAlert();

  if (user) {
    return <Navigate to="/dashboard" replace={true} />;
  }

  const handleSubmit = (values: ILoginDataSchema) => {
    loginWithCredentials(values.email, values.password).catch((error) => {
      if (error.message === 'User or password is incorrect') {
        openAlert({
          title: 'Error al iniciar sesión',
          status: 'error',
          description:
            'La dirección de correo electrónico o la contraseña que ingresaste no son válidas. Vuelve a intentarlo.',
          autoDismiss: true,
        });
      } else if (error.message === 'User does not exist') {
        openAlert({
          title: 'Error al iniciar sesión',
          status: 'error',
          description:
            'El correo electrónico ingresado no está registrado. Por favor, verifica que sea correcto.',
          autoDismiss: true,
        });
      } else {
        openAlert({
          title: 'Error al iniciar sesión',
          status: 'error',
          description:
            'Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.',
          autoDismiss: true,
        });
      }
    });
  };
  return (
    <Center h="100vh">
      <Container centerContent>
        <Flex direction="column" gap={6}>
          <Box overflow="hidden" textAlign="center" alignSelf="center">
            <Image
              src={
                'https://res.cloudinary.com/hdwylajhd/image/upload/v1707360605/assets/brand-logo2x.png'
              }
              alt="Shot Shop"
              objectFit="contain"
              width="200px"
            />
            <Text fontSize="xl">Ingresa de marcas</Text>
          </Box>
          <LoginForm onSubmit={handleSubmit} />
          <Box position="relative">
            <hr />
            <Text
              position="absolute"
              left="50%"
              top="-13px"
              backgroundColor="white"
              px={2}
              fontSize="large"
            >
              ó
            </Text>
          </Box>
          <Box alignSelf="center">
            <Button
              bg="white"
              color="#3c4043"
              border="1px solid #dadce0"
              fontSize="14px"
              fontWeight={500}
              height={8}
              _hover={{
                background: 'rgba(66,133,244,.04);',
                boxShadow: 'none',
                borderColor: '#d2e3fc',
                outline: 'none',
              }}
              onClick={() => loginWithGoogle()}
            >
              <Icon as={TbBrandGoogle} mr={2} boxSize="4" />
              Iniciar sesi&oacute;n con Google
            </Button>
          </Box>
          <Box textAlign="center">
            <Text>¿A&uacute;n no tienes cuenta?</Text>
            <Text fontWeight="light">
              Si quieres comenzar a transmitir y vender en vivo, has{' '}
              <ChakraLink as={Link} to="/join" color="ui.link">
                click aqu&iacute;
              </ChakraLink>
            </Text>
          </Box>
        </Flex>
      </Container>
    </Center>
  );
};

export default Login;
