import { useState } from 'react';
import { IOrderData } from '../types/order';
import { IFetchResult, pushData } from './fetcher';

const CREATE_ORDER_PATH = '/api/orders/';
interface ICreateOrderAPI {
  createOrder: (
    orderData: IOrderData,
    onSuccess: (result: any) => void,
    onError: (errors: any) => void
  ) => void;
}

export const useCreateShowOrderAPI = (): IFetchResult<any> &
  ICreateOrderAPI => {
  const [ignore, setIgnore] = useState(false);
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  const createOrder = async (
    orderData: IOrderData,
    onSuccess: (result: any) => void,
    onError: (errors: any) => void
  ) => {
    setIsLoading(true);
    const result = await pushData(
      CREATE_ORDER_PATH,
      orderData,
      setData,
      setError,
      setIsLoading,
      ignore,
      onSuccess,
      onError
    );
    setIsLoading(false); // TODO: same here
  };
  return {
    data,
    error,
    isLoading,
    createOrder,
  };
};
