import { useParams, useSearchParams } from 'react-router-dom';
import AgoraRTM from 'agora-rtm-sdk';
import {
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  TabIndicator,
} from '@chakra-ui/tabs';
import { Box, Flex, Heading, Grid, GridItem, Text } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { TbCopy } from 'react-icons/tb';

import { useAuth } from '../../context/AuthContext';
import { useToast } from '../../context/ToastContext';
import AgoraChat from '../../components/sections/AgoraChat/AgoraChat';
import AgoraManager from '../../components/sections/AgoraManager/AgoraManager';
import config from '../../components/sections/AgoraManager/config';
import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useFetchShowHostTokenAPI } from '../../hooks/useFetchShowAPI';
import { useShowBySlugname } from '../../queries/shows';

import ShowMetrics from './components/ShowMetrics';
import ShowOrdersSection from './components/ShowOrdersSection';
import ShowProductsSection from './components/ShowProductsSection';

const agoraAppId = process.env.REACT_APP_AGORA_APP_ID as string;
const agoraClient = AgoraRTM.createInstance(agoraAppId);

const LiveShowView: React.FC = () => {
  const { user } = useAuth();
  const params = useParams();
  const showSlugName = params.showSlugName as string;
  const [searchParams] = useSearchParams();
  const { open: openToast } = useToast();

  let tokenOnParam = searchParams.get('token');
  if (tokenOnParam) {
    tokenOnParam = tokenOnParam.replace(/ /g, '+');
  }

  const {
    data: show,
    error: showError,
    isError: isShowError,
    isLoading: isShowLoading,
  } = useShowBySlugname(user!.companyId, showSlugName);

  const { data: hostToken, isLoading: isHostTokenLoading } =
    useFetchShowHostTokenAPI(show?.id || '');

  if (isShowLoading || isHostTokenLoading) {
    return <CenteredSpinner />;
  }

  if (isShowError) {
    return (
      <div role="alert">
        There was an error:{' '}
        <pre style={{ whiteSpace: 'normal' }}>
          {(showError as Error).message}
        </pre>
      </div>
    );
  }

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(
        `https://www.shotshop.live/app/home/shows/${show?.id}`
      );
      openToast({
        description: 'Enlace copiado al portapapeles',
        status: 'info',
        isClosable: true,
      });
    } catch (err) {
      openToast({
        title: 'Error al copiar el enlace',
        description: (err as Error).message,
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    show &&
    hostToken && (
      <>
        <Grid
          templateRows={{ base: 'auto', md: 'auto auto 1fr 1fr' }}
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={{ base: 3, md: 5 }}
        >
          <GridItem colSpan={{ base: 1, md: 2 }} alignSelf="flex-end">
            <Heading>{show.name}</Heading>
          </GridItem>
          <GridItem>
            <Text>Comparte este link para se unan al show:</Text>
            <Flex
              align="center"
              gap={2}
              bg="ui.light-gray"
              p={3}
              borderRadius="md"
              mt={1}
              cursor="pointer"
              onClick={handleCopyLink}
            >
              <Text fontWeight="semibold">
                https://www.shotshop.live/app/home/shows/{show.id}
              </Text>
              <Icon as={TbCopy} boxSize="24px" />
            </Flex>
          </GridItem>
          <GridItem
            colSpan={{ base: 1, md: 2 }}
            rowSpan={1}
            display={{ base: 'none', md: 'block' }}
          >
            <ShowMetrics
              data={{
                currentAudience: 52,
                totalAudience: 107,
                messages: 65,
                likes: 42,
                totalOrders: 21,
                totalSales: '$124.300',
              }}
            />
          </GridItem>
          <GridItem colSpan={1} rowSpan={3}>
            <Box
              borderRadius="lg"
              bg="white"
              p={4}
              position="relative"
              h="full"
            >
              <AgoraManager
                config={{
                  ...config,
                  appId: agoraAppId,
                  rtcToken: hostToken.rtc_token,
                  uid: hostToken.user_id,
                  channelName: hostToken.channel,
                  token: hostToken.rtm_token,
                }}
                show={show}
              />
            </Box>
          </GridItem>
          <GridItem colSpan={{ base: 1, md: 2 }} rowSpan={2}>
            <Box
              bg="white"
              p={5}
              borderRadius="lg"
              position="relative"
              h="full"
            >
              <Tabs position="relative" variant="unstyled" height="100%">
                <TabList borderBottom="2px" borderColor="#F8F8F8">
                  <Tab>Chat</Tab>
                  <Tab>Productos</Tab>
                  <Tab>Órdenes</Tab>
                </TabList>
                <TabIndicator
                  height="2px"
                  bg="brand.green"
                  mt="-1.5px"
                  width="10%"
                />
                <TabPanels>
                  <TabPanel tabIndex={1}>
                    <AgoraChat
                      channelName={show.channel}
                      client={agoraClient}
                      token={tokenOnParam || hostToken.rtm_token}
                      variant="host"
                      userId={hostToken.user_id}
                      displayName={show.company.name}
                      showStatus={show.status}
                    />
                  </TabPanel>
                  <TabPanel tabIndex={2}>
                    {show && (
                      <ShowProductsSection
                        show={show}
                        showProducts={show.products}
                      />
                    )}
                  </TabPanel>
                  <TabPanel tabIndex={3}>
                    {show && <ShowOrdersSection showId={show.id} />}
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </GridItem>
        </Grid>
      </>
    )
  );
};

export default LiveShowView;
