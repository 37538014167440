import { TbPackage } from 'react-icons/tb';
import { Box, Flex, Heading } from '@chakra-ui/layout';

import CenteredSpinner from '../../components/ui/CenteredSpinner';
import EmptyState from '../../components/ui/EmptyState/EmptyState';
import OrdersTable from './components/OrdersTable';
import { useAuth } from '../../context/AuthContext';
import { useOrders } from '../../queries/orders';

const OrdersView = () => {
  const { user } = useAuth();
  const {
    data: orders,
    error,
    isError,
    isLoading,
  } = useOrders(user!.companyId);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (orders?.length === 0) {
    return (
      <EmptyState
        message="Aqu&iacute; ver&aacute;s las ordenes que has recibido y podr&aacute;s prepara su env&iacute;o."
        title="Aún no has recibido &oacute;rdenes"
        icon={TbPackage}
      />
    );
  }

  return (
    orders && (
      <Box bg="white" borderRadius="lg" px="5" py="3" height="full">
        <Flex justifyContent="space-between" py={{ base: 0, md: 4 }}>
          <Heading as="h2" color="brand.green" fontWeight="semibold">
            Ordenes
          </Heading>
        </Flex>
        <OrdersTable orders={orders} />
      </Box>
    )
  );
};

export default OrdersView;
