import * as yup from 'yup';

const loginDataSchema = yup.object().shape({
  email: yup
    .string()
    .required('Debe ingresar un correo electrónico')
    .email('Debe ingresar un correo electrónico válido'),
  password: yup
    .string()
    .min(8, 'La contraseña debe contener al menos 8 caracteres')
    .required('Debe ingresar una contraseña'),
});

interface ILoginDataSchema extends yup.InferType<typeof loginDataSchema> {}

export { loginDataSchema, type ILoginDataSchema };
