import React from 'react';
import { Box } from '@chakra-ui/react';

import useAgoraVideoUserStatus from '../../../../hooks/useAgoraVideoUserState';
import { useRTCClient } from 'agora-rtc-react';
import { VideoPlayer } from '../../../../components/ui/Agora';

interface IAudienceVideo {
  appId: string;
  token: string;
  channelName: string;
  userId: number;
}

const AudienceVideo = ({
  appId,
  token,
  channelName,
  userId,
}: IAudienceVideo) => {
  const client = useRTCClient();
  const { isReady, user } = useAgoraVideoUserStatus({
    appId,
    token,
    channelName,
    client,
    uid: userId,
  });

  return (
    <Box id="player-box" height="100vh">
      {isReady && user && <VideoPlayer user={user} />}
    </Box>
  );
};

export default AudienceVideo;
