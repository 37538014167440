import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Text,
  Link,
} from '@chakra-ui/layout';

import { Order } from '../../../types/order';
import { formatDateTime } from '../../../utils/dates';
import {
  getStatusLabel,
  getStatusTagColor,
} from '../../../utils/orders';
import OrderStatusTag from './OrderStatusTag';
import ProductOrderTable from './ProductOrderTable';

interface OrderViewProps {
  order: Order;
}

const OrderView: React.FC<OrderViewProps> = ({ order }) => {
  const {
    datetime,
    id,
    customer: { firstName, lastName, email, identification },
    payment: { paymentMethod },
    items: products,
    shipping: {
      serviceType,
      order: { externalId, packageLabelUrl },
      address,
    },
    status,
  } = order;

  return (
    <Flex direction="column" gap={4}>
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          Orden #{id}
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Fecha y hora
              </Text>
              <Text fontSize="small">{formatDateTime(datetime)}</Text>
            </Flex>
          </GridItem>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Estado de la orden
              </Text>
              <Box>
                <OrderStatusTag
                  status={getStatusLabel(status)}
                  variant={getStatusTagColor(status)}
                />
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          Datos de contacto
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Nombre y apellido
              </Text>
              <Text fontSize="small">{`${firstName}  ${lastName}`}</Text>
            </Flex>
          </GridItem>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Email
              </Text>
              <Text fontSize="small">{email}</Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          Productos
        </Heading>
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={4}
          mt={4}
          overflowX="scroll"
        >
          <GridItem py={4}>
            <ProductOrderTable products={products} />
          </GridItem>
        </Grid>
      </Box>
      {serviceType === 'delivery' && (
        <Box backgroundColor="white" py={4} px={6} borderRadius="md">
          <Heading fontSize="large" fontWeight="bold">
            Domicilio
          </Heading>
          <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  Calle y n&uacute;mero
                </Text>
                <Text fontSize="small">{`${address.street}  ${address.number}`}</Text>
              </Flex>
            </GridItem>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  Departamento
                </Text>
                <Text fontSize="small">{address.optionalData}</Text>
              </Flex>
            </GridItem>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  Ciudad
                </Text>
                <Text fontSize="small">{address.city}</Text>
              </Flex>
            </GridItem>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  C&oacute;digo postal
                </Text>
                <Text fontSize="small">{address.zipCode}</Text>
              </Flex>
            </GridItem>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  Provincia
                </Text>
                <Text fontSize="small">{address.state}</Text>
              </Flex>
            </GridItem>
            <GridItem py={4}>
              <Flex direction="column" gap={1}>
                <Text fontWeight="bold" fontSize="small">
                  Tel&eacute;fono
                </Text>
                <Text fontSize="small">{address.recipientPhoneNumber}</Text>
              </Flex>
            </GridItem>
          </Grid>
        </Box>
      )}
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          Datos de facturaci&oacute;n
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                DNI &oacute; CUIL
              </Text>
              <Text fontSize="small">
                {identification.type}: {identification.number}
              </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          M&eacute;todo de pago
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small"></Text>
              <Text fontSize="small">{paymentMethod} </Text>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
      <Box backgroundColor="white" py={4} px={6} borderRadius="md">
        <Heading fontSize="large" fontWeight="bold">
          Env&iacute;o
        </Heading>
        <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Tipo de env&iacute;o
              </Text>
              <Text fontSize="small">{serviceType}</Text>
            </Flex>
          </GridItem>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                C&oacute;digo de seguimiento
              </Text>
              <Text fontSize="small">{externalId}</Text>
            </Flex>
          </GridItem>
          <GridItem py={4}>
            <Flex direction="column" gap={1}>
              <Text fontWeight="bold" fontSize="small">
                Etiqueta de env&iacute;o
              </Text>
              <Link href={packageLabelUrl} download={`envio-${id}`} isExternal>
                Descargar
              </Link>
            </Flex>
          </GridItem>
        </Grid>
      </Box>
    </Flex>
  );
};

export default OrderView;
