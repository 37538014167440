import { Outlet } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/provider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AlertDialogProvider } from '../context/AlertDialogContext';
import { AlertProvider } from '../context/AlertContext';
import { AuthProvider } from '../context/AuthContext';
import { ModalProvider } from '../context/ModalContext';
import { ToastProvider } from '../context/ToastContext';
import customTheme from '../utils/theme';
import AgoraClientProvider from '../context/AgoraClientProvider';

const queryClient = new QueryClient();
const googleClientId = process.env.REACT_APP_GOOGLE_CLIENT_ID as string;

const RootLayout = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <AuthProvider>
            <AgoraClientProvider>
                <ModalProvider>
                  <AlertDialogProvider>
                    <AlertProvider>
                      <ToastProvider>
                        <Outlet />
                      </ToastProvider>
                    </AlertProvider>
                  </AlertDialogProvider>
                </ModalProvider>
            </AgoraClientProvider>
          </AuthProvider>
        </GoogleOAuthProvider>
      </QueryClientProvider>
    </ChakraProvider>
  );
};

export default RootLayout;
