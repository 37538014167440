import { useEffect, useRef } from 'react';
import { Button } from '@chakra-ui/button';
import { Box, Flex } from '@chakra-ui/layout';
import { Input } from '@chakra-ui/input';
import { TbMessages } from 'react-icons/tb';

import { IAgoraMessage } from '../../../hooks/useAgoraRtm';
import EmptyState from '../../ui/EmptyState/EmptyState';
import HostMessage from './HostMessage';

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<HTMLHeadingElement>(null);
  useEffect(() => elementRef?.current?.scrollIntoView());
  return elementRef !== undefined && <div ref={elementRef} />;
};

type HostChatProps = {
  isDisabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: () => void;
  message: string;
  messages: IAgoraMessage[];
  username: string;
};

const HostChat: React.FC<HostChatProps> = ({
  isDisabled,
  onChange,
  onSubmit,
  message,
  messages,
  username,
}) => {
  return (
    <Flex h="50vh" flexDirection="column" justifyContent="space-between">
      {messages.length === 0 ? (
        <EmptyState
          title="Interactu&aacute; con los participantes"
          message="Respondiendo sus dudas en el vivo o usando el chat para ver sus reacciones."
          icon={TbMessages}
        />
      ) : (
        <Flex
          direction="column"
          justifyContent="flex-start"
          gap={3}
          overflowY="scroll"
          flex="1"
          pr={6}
        >
          {messages.map(({ message, user }: IAgoraMessage, index: any) => (
            <HostMessage
              key={index}
              alignRight={user.name === username}
              // TODO: replace with user avatar
              avatarUrl=""
              author={user.name}
              message={message}
            />
          ))}
          <Box display={{ base: 'none', md: 'block'}}>
            <AlwaysScrollToBottom />
          </Box>
        </Flex>
      )}
      <form onSubmit={onSubmit}>
        <Flex align="center" gap={2} pt={5}>
          <Input
            bg="#FCFCFC"
            borderRadius="10px"
            borderColor="#B9B9B9"
            color="ui.copy-dark"
            onChange={onChange}
            placeholder="Agrega un comentario"
            _placeholder={{ opacity: 1, color: 'white' }}
            value={message}
          />
          <Button
            size="md"
            bg="brand.green"
            type="submit"
            isDisabled={isDisabled}
          >
            Enviar
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default HostChat;
