import { Link } from 'react-router-dom';
import { TbPackage } from 'react-icons/tb';
import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Box, Flex, Heading } from '@chakra-ui/layout';
import { TbCirclePlus } from 'react-icons/tb';

import CenteredSpinner from '../../components/ui/CenteredSpinner';
import EmptyState from '../../components/ui/EmptyState/EmptyState';
import ProductsTable from './components/ProductsTable';
import { useAuth } from '../../context/AuthContext';
import { useProducts } from '../../queries/products';

const ProductsView = () => {
  const { user } = useAuth();
  const {
    data: products,
    error,
    isError,
    isLoading,
  } = useProducts(user!.companyId);

  if (isError) {
    throw error;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  if (products?.length === 0) {
    return (
      <EmptyState
        ctaLabel={
          <Button as={Link} to="create" variant="primary">
            Agregá tu primer producto
          </Button>
        }
        message="¡Agregar productos es fundamental para empezar a vender! Una vez cargados, vas a poder administrarlos desde acá."
        title="Agrega tus productos y empez&aacute; a vender"
        icon={TbPackage}
      />
    );
  }

  return (
    products && (
      <Box bg="white" borderRadius="lg" px="5" py="3" height="full">
        <Flex justifyContent="space-between" py={{ base: 0, md: 4 }}>
          <Heading as="h2" color="brand.green" fontWeight="semibold">
            Productos
          </Heading>
          <Flex gap="3">
            <Link to="create">
              <Button variant="primary">
                <Icon mr="2" as={TbCirclePlus} />
                Agregar producto
              </Button>
            </Link>
          </Flex>
        </Flex>
        <ProductsTable products={products} />
      </Box>
    )
  );
};

export default ProductsView;
