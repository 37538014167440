import { createContext, useContext, useEffect, useState } from 'react';
import {
  useLocalCameraTrack,
  useLocalMicrophoneTrack,
  usePublish,
  useRTCClient,
  useClientEvent,
  useRemoteUsers,
  IMicrophoneAudioTrack,
  ICameraVideoTrack,
} from 'agora-rtc-react';

import CenteredSpinner from '../../../components/ui/CenteredSpinner';
import VideoCall from '../../../pages/Shows/components/VideoCall';
import { configType } from './config';
import { IShow } from '../../../types/show';

// Define the shape of the Agora context
interface AgoraContextType {
  localCameraTrack: ICameraVideoTrack | null;
  localMicrophoneTrack: IMicrophoneAudioTrack | null;
}

// Create the Agora context
const AgoraContext = createContext<AgoraContextType | null>(null);

// AgoraProvider component to provide the Agora context to its children
export const AgoraProvider: React.FC<AgoraContextType> = ({
  localCameraTrack,
  localMicrophoneTrack,
}) => (
  <AgoraContext.Provider
    value={{ localCameraTrack, localMicrophoneTrack }}
  ></AgoraContext.Provider>
);

// Custom hook to access the Agora context
export const useAgoraContext = () => {
  const context = useContext(AgoraContext);
  if (!context)
    throw new Error('useAgoraContext must be used within an AgoraProvider');
  return context;
};

interface AgoraManagerProps {
  config: configType;
  show: IShow;
}

const AgoraManager: React.FC<AgoraManagerProps> = ({ config, show }) => {
  const agoraEngine = useRTCClient();
  const remoteUsers = useRemoteUsers();
  const { isLoading: isLoadingCam, localCameraTrack } = useLocalCameraTrack();
  const { isLoading: isLoadingMic, localMicrophoneTrack } =
    useLocalMicrophoneTrack();
  const [isVideoMuted, setVideoMuted] = useState(false);
  const [isAudioMuted, setAudioMuted] = useState(false);

  // Publish local tracks
  usePublish([localMicrophoneTrack, localCameraTrack]);

  //   // Join the Agora channel with the specified configuration
  //   useJoin({
  //     appid: config.appId,
  //     channel: config.channelName,
  //     token: config.rtcToken,
  //     uid: config.uid,
  //   });

  useClientEvent(agoraEngine, 'user-joined', (user) => {
    console.log('The user', user.uid, ' has joined the channel');
  });

  useClientEvent(agoraEngine, 'user-left', (user) => {
    console.log('The user', user.uid, ' has left the channel');
  });

  useClientEvent(agoraEngine, 'user-published', (user, mediaType) => {
    console.log('The user', user.uid, ' has published media in the channel');
  });

  useEffect(() => {
    return () => {
      localCameraTrack?.close();
      localMicrophoneTrack?.close();
      agoraEngine.leave();
    };
  }, []);

  const joinChannel = async () => {
    return agoraEngine.join(
      config.appId,
      config.channelName,
      config.rtcToken,
      config.uid
    );
  };

  const leaveChannel = async () => {
    return agoraEngine.leave().then(() => {
      setVideoMuted(true);
      setAudioMuted(true);
    });
  };

  const toggleAudio = () => {
    localMicrophoneTrack
      ?.setEnabled(isAudioMuted)
      .then(() => setAudioMuted((prev) => !prev))
      .catch((error) => console.error('Error on toggle mute audio: ', error));
  };

  const togglePlayback = async () => {
    Promise.all([
      localCameraTrack?.setEnabled(isVideoMuted),
      localMicrophoneTrack?.setEnabled(isVideoMuted),
    ])
      .then(() => {
        setAudioMuted((prev) => !prev);
        setVideoMuted((prev) => !prev);
      })
      .catch((error) => console.error('Error on toggle playback: ', error));
  };

  // Check if devices are still loading
  const deviceLoading = isLoadingMic || isLoadingCam;
  if (deviceLoading) return <CenteredSpinner />;

  return (
    <AgoraContext.Provider value={{ localCameraTrack, localMicrophoneTrack }}>
      <VideoCall
        connectionState={agoraEngine.connectionState}
        isAudioMuted={isAudioMuted}
        isVideoMuted={isVideoMuted}
        onJoinChannel={joinChannel}
        onLeaveChannel={leaveChannel}
        onToggleAudio={toggleAudio}
        onTogglePlayback={togglePlayback}
        remoteUserCount={remoteUsers.length}
        show={show}
      />
      {/* Render remote users' video and audio tracks */}
      {/* {remoteUsers.map((remoteUser) => (
                  <div
                    className="vid"
                    style={{ height: 300, width: 600 }}
                    key={remoteUser.uid}
                  >
                    <RemoteUser
                      user={remoteUser}
                      playVideo={true}
                      playAudio={true}
                    />
                  </div>
                ))} */}
    </AgoraContext.Provider>
  );
};

export default AgoraManager;
