import { useParams, Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link } from '@chakra-ui/layout';
import { Icon } from '@chakra-ui/icon';
import { TbChevronLeft } from 'react-icons/tb';

import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useAuth } from '../../context/AuthContext';
import { useOrder } from '../../queries/orders';
import OrderView from './components/OrderView';

const OrderPage: React.FC = () => {
  const { user } = useAuth();
  const { orderId } = useParams();
  const {
    data: order,
    isLoading,
    isError,
    error,
  } = useOrder(user?.companyId!, Number(orderId));

  if (isError) {
    console.error('OrderPage error: ', error);
    throw error;
  }

  if (isLoading) {
    return <CenteredSpinner />;
  }

  return (
    <Flex direction="column" gap={3} w={{ base: '80%', xl: '60%' }}>
      <Box py={3}>
        <Link as={RouterLink} to="..">
          <Flex alignItems="center">
            <Icon as={TbChevronLeft} />
            Volver
          </Flex>
        </Link>
      </Box>
      {order && <OrderView order={order} />}
    </Flex>
  );
};

export default OrderPage;
