import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
} from '@chakra-ui/modal';

interface ModalProps {
  content: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, content }) => {
  return (
    <ChakraModal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>{content}</ModalContent>
    </ChakraModal>
  );
};

export default Modal;
