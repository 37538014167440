import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/tag';

interface VariantTagProps {
  label: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
}

const VariantTag: React.FC<VariantTagProps> = ({ label, onClose }) => {
  return (
    <Tag
      bg="#E4EAF2"
      textColor="ui.copy-dark"
      borderRadius="full"
      variant="solid"
      minW={10}
      justifyContent="center"
    >
      <TagLabel>{label}</TagLabel>
      {onClose && <TagCloseButton onClick={onClose} />}
    </Tag>
  );
};

export default VariantTag;
