import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TbCirclePlus, TbUserCheck, TbUserExclamation } from 'react-icons/tb';
import { Button } from '@chakra-ui/button';
import { Icon } from '@chakra-ui/icon';
import { Flex } from '@chakra-ui/layout';
import { v4 as uuidv4 } from 'uuid';

import EmptyState from '../../components/ui/EmptyState/EmptyState';
import CenteredSpinner from '../../components/ui/CenteredSpinner';
import { useAuth } from '../../context/AuthContext';
import { client } from '../../utils/api-client';
import {
  COMPANY_PAYMENT_METHODS_URL,
  linkMercadoPagoAccount,
  unlinkMercadoPagoAccount,
} from '../../utils/payment-methods';

const state = uuidv4();
const client_id = process.env.REACT_APP_MERCADOPAGO_CLIENT_ID as string;
const redirect_uri = process.env.REACT_APP_MERCADOPAGO_REDIRECT_URI as string;
const mercadoPagoUrl = `https://auth.mercadopago.com/authorization?client_id=${client_id}&response_type=code&platform_id=mp&state=${state}&redirect_uri=${redirect_uri}`;

const DashboardSettings = () => {
  const [isLogged, setIsLogged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const { user } = useAuth();

  const handleUnlinkAccountClick = async () => {
    setIsLoading(true);
    try {
      const response = await unlinkMercadoPagoAccount(user!.companyId);
      if (response === null) {
        setIsLogged(false);
      }
    } catch (error) {
      throw error;
    }
    setIsLoading(false);
  };

  const checkMercadoPagoAccessToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client(
        COMPANY_PAYMENT_METHODS_URL.replace(
          ':companyId',
          String(user!.companyId)
        )
      );
      if (response.access_token) {
        setIsLogged(true);
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, [user]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    setIsLoading(false);
    if (code) {
      linkMercadoPagoAccount(code, user!.companyId)
        .then((response) => {
          setIsLogged(true);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      checkMercadoPagoAccessToken();
    }
  }, [checkMercadoPagoAccessToken, location.search, user]);

  if (isLoading) return <CenteredSpinner />;

  return (
    <Flex as="section" h="full" bg="white" justify="center">
      {isLogged ? (
        <EmptyState
          ctaLabel={
            <Button onClick={handleUnlinkAccountClick} variant="primary">
              <Icon mr="2" as={TbCirclePlus} />
              Desvincular cuenta de MercadoPago
            </Button>
          }
          message="Tu cuenta de MercadoPago ya est&aacute; configurada. ¡Ahora pod&eacute;s empezar a vender!"
          title="¡Ya pod&eacute;s recibir pagos!"
          icon={TbUserCheck}
        />
      ) : (
        <EmptyState
          ctaLabel={
            <Button
              as={Link}
              to={mercadoPagoUrl}
              variant="primary"
              target="_blank"
            >
              <Icon mr="2" as={TbCirclePlus} />
              Configura tu cuenta de MercadoPago
            </Button>
          }
          message="Recib&iacute; tus pagos a trav&eacute;s de MercadoPago de forma segura y confiable."
          title="Asoci&aacute; tu cuenta de MercadoPago"
          icon={TbUserExclamation}
        />
      )}
    </Flex>
  );
};
export default DashboardSettings;
