import { Flex } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import { TbCalendarCheck } from 'react-icons/tb';

import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal';
import EmptyState from '../../../components/ui/EmptyState/EmptyState';
import { useAlertDialog } from '../../../context/AlertDialogContext';
import { useDeleteShow, useShows } from '../../../queries/shows';
import UpcomingShowCard from './UpcomingShowCard';

interface PastShowsSectionProps {
  companyId: number;
}

const PastShowsSection: React.FC<PastShowsSectionProps> = ({ companyId }) => {
  const { openAlertDialog, closeAlertDialog, cancelRef } = useAlertDialog();
  const {
    data: shows,
    isLoading,
    isFetching,
  } = useShows(companyId, {
    queryParams: { status: 'ended' },
  });
  const { mutate: deleteShow } = useDeleteShow(companyId);

  const handleDeleteClick = async (showId: number) => {
    await deleteShow(showId);
    closeAlertDialog();
  };

  if (isLoading || isFetching) {
    return (
      <Flex direction="column" gap={4}>
        {/* TODO: Extract this into its own component */}
        <Skeleton
          height="160px"
          maxW={{ base: '100%', sm: '480px' }}
          borderRadius="md"
        />
        <Skeleton
          height="160px"
          maxW={{ base: '100%', sm: '480px' }}
          borderRadius="md"
        />
        <Skeleton
          height="160px"
          maxW={{ base: '100%', sm: '480px' }}
          borderRadius="md"
        />
      </Flex>
    );
  }

  if (!shows) {
    return (
      <EmptyState
        title="Transmisiones finalizadas"
        message="Aquí aparecerán los shows que ya concluyeron. Todavía puedes ver los detalles de productos y ordenes recibidas."
        icon={TbCalendarCheck}
      />
    );
  }

  return (
    <Flex direction="column" gap={4}>
      {shows?.map((show) => {
        return (
          <UpcomingShowCard
            key={show.id}
            onDelete={() =>
              openAlertDialog(
                <DeleteConfirmationModal
                  title="Eliminar show"
                  onClose={() => closeAlertDialog()}
                  onDelete={() => handleDeleteClick(show.id)}
                  cancelRef={cancelRef}
                />
              )
            }
            show={show}
          />
        );
      })}
    </Flex>
  );
};

export default PastShowsSection;
