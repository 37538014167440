import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Button, IconButton } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Text,
} from '@chakra-ui/layout';
import { Switch } from '@chakra-ui/switch';
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/form-control';
import { Icon } from '@chakra-ui/icon';
import { Input } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import { Textarea } from '@chakra-ui/textarea';
import { TbCirclePlus, TbPackage, TbPlus, TbX } from 'react-icons/tb';

import { Category, IProduct } from '../../../types/product';
import EmptyState from '../../../components/ui/EmptyState/EmptyState';
import { toLocalISOTimeString } from '../../../utils/dates';
import { createImageResource } from '../../../utils/api';
import { IShowDataSchema, showDataSchema } from './validationSchema';
import ShowProductsTable from './ShowProductsTable';

// TODO: Create Host type with all the properties once they are properly defined
type Host = {
  id: number;
  name: string;
};

type ShowFormProps = {
  categories: Category[];
  ctaLabel?: string;
  hosts?: Host[];
  products: IProduct[];
  initialValues: IShowDataSchema;
  onSubmit: (values: any) => void;
};

const ShowForm: React.FC<ShowFormProps> = ({
  categories,
  ctaLabel = 'Guardar cambios',
  hosts,
  products,
  initialValues,
  onSubmit,
}) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate('/dashboard/shows?status=upcoming');
  };

  const showProductIds = initialValues.products.map(
    (product) => product.productId
  );
  const initialProductSelection: Record<string, boolean> = {};
  products.forEach((product, index) => {
    initialProductSelection[index] = showProductIds.includes(product.id);
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const utcStartDateTime = new Date(values.startDateTime).toISOString();
        onSubmit({ ...values, startDateTime: utcStartDateTime });
      }}
      validationSchema={showDataSchema}
    >
      {({
        errors,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
      }: FormikProps<IShowDataSchema>) => {
        return (
          <Form>
            <Flex direction="column" gap={4} pb={5}>
              <Box
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
                gap={4}
              >
                <Heading as="h3" size="md" fontWeight="semibold">
                  Informaci&oacute;n General
                </Heading>
                <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                  <GridItem colSpan={2}>
                    <FormControl
                      isRequired={true}
                      isInvalid={!!errors.name && touched.name}
                    >
                      <FormLabel>Nombre</FormLabel>
                      <Input
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl
                      isInvalid={!!errors.description && touched.description}
                      isRequired
                    >
                      <FormLabel>Descripci&oacute;n</FormLabel>
                      <Textarea
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormErrorMessage>{errors.name}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isInvalid={!!errors.host && touched.host}>
                      <FormLabel>Presentador</FormLabel>
                      <Input
                        type="text"
                        name="host"
                        value={values.host}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Nombre del presentador"
                      />
                      {/* <Select
                      name="host"
                      value={values.host}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Selecciona uno"
                    >
                      {hosts.map(({ id, name }) => (
                        <option value={id} key={id}>
                          {name}
                        </option>
                      ))}
                    </Select> */}
                      <FormErrorMessage>{errors.host}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  {/* <GridItem>+Agregar otro presentador</GridItem> */}
                  <GridItem colSpan={2}>
                    <FormControl
                      isInvalid={!!errors.category && touched.category}
                      isRequired
                    >
                      <FormLabel>Categor&iacute;a</FormLabel>
                      <Select
                        name="category"
                        value={values.category}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Selecciona una"
                      >
                        {categories.map((category) => (
                          <option value={category.id} key={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl
                      isInvalid={
                        !!errors.startDateTime && touched.startDateTime
                      }
                      isRequired
                    >
                      <FormLabel>Fecha y hora</FormLabel>
                      <Input
                        name="startDateTime"
                        value={toLocalISOTimeString(values.startDateTime)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        type="datetime-local"
                        min={toLocalISOTimeString(new Date().toLocaleString())}
                      />
                      <FormErrorMessage>
                        {errors.startDateTime}
                      </FormErrorMessage>
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <FormControl isRequired>
                      <FormLabel>Duraci&oacute;n</FormLabel>
                      <Select
                        placeholder="Seleccionar duracion"
                        name="duration"
                        value={values.duration}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value={30}>00:30</option>
                        <option value={60}>01:00</option>
                      </Select>
                      <FormErrorMessage>{errors.duration}</FormErrorMessage>
                    </FormControl>
                  </GridItem>
                </Grid>
              </Box>
              <Box
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
                gap={4}
              >
                <Heading as="h3" size="md" fontWeight="semibold">
                  Productos disponibles m&aacute;s recientes
                </Heading>
                <Text>
                  Agrega los productos que vas a promocionar en el vivo.
                </Text>
                <Box py={3}>
                  {products.length > 0 ? (
                    <ShowProductsTable
                      products={products}
                      initialProductSelection={initialProductSelection}
                    />
                  ) : (
                    <EmptyState
                      title="No tienes productos cargados"
                      message="Necesitas agregar productos para promocionarlos en tus shows."
                      icon={TbPackage}
                      ctaLabel={
                        <Link
                          as={RouterLink}
                          to="/dashboard/products/create"
                          isExternal
                        >
                          Agregar productos
                        </Link>
                      }
                    />
                  )}
                </Box>
              </Box>
              <Box
                bg="white"
                border="1px"
                borderColor="ui.light-gray"
                borderRadius="lg"
                py={4}
                px={5}
              >
                <FormControl
                  isRequired
                  isInvalid={!!errors.image && touched.image}
                >
                  <Flex direction="column" gap={3}>
                    <Heading as="h3" size="md" fontWeight="semibold">
                      Cortinas
                    </Heading>
                    {values.image ? (
                      <Box position="relative">
                        <Image
                          aspectRatio={9 / 16}
                          width={64}
                          objectFit="cover"
                          src={values.image as string}
                          alt="Imagen de portada"
                          borderWidth="1px"
                          borderRadius="lg"
                        />
                        <IconButton
                          icon={<Icon as={TbX} boxSize={4} />}
                          aria-label="Borrar imagen"
                          position="absolute"
                          top={1}
                          left="220px"
                          colorScheme="ui.copy-dark"
                          variant="icon"
                          size="sm"
                          onClick={() => {
                            setFieldValue('image', undefined);
                          }}
                        />
                      </Box>
                    ) : (
                      <>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={async (
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const file = event.target.files?.[0];
                            if (file) {
                              const imageUrl = await createImageResource(
                                'show',
                                file
                              );
                              setFieldValue('image', imageUrl);
                            }
                          }}
                          // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/
                          width="0.1px"
                          height="0.1px"
                          opacity={0}
                          overflow="hidden"
                          position="absolute"
                          zIndex={-1}
                        />
                        <FormLabel cursor="pointer">
                          <Flex
                            direction="column"
                            gap={2}
                            py={4}
                            alignItems="center"
                            backgroundColor="#E4EAF2"
                            border="1px dashed"
                            borderColor="ui.medium-gray"
                            borderRadius="md"
                          >
                            <Flex
                              p={2}
                              backgroundColor="white"
                              border="1px solid"
                              borderColor="ui.link-active"
                              borderRadius="full"
                            >
                              <Icon
                                as={TbPlus}
                                color="ui.link-active"
                                boxSize={6}
                              />
                            </Flex>
                            <Box fontSize="sm">
                              Arrastr&aacute; tu imagen aqui o{' '}
                              <Text color="ui.link" display="inline">
                                subir desde tu dispositivo
                              </Text>
                            </Box>
                          </Flex>
                        </FormLabel>
                      </>
                    )}
                    <FormHelperText>
                      Tamaño máximo: 10Mb. Resolución mínima recomendada:
                      1024px. Formato: PNG, JPEG o GIF.
                    </FormHelperText>
                    <FormErrorMessage>{errors.image}</FormErrorMessage>
                  </Flex>
                </FormControl>
              </Box>
              {/* <Flex
              direction="column"
              gap={4}
              bg="white"
              border="1px"
              borderColor="ui.light-gray"
              borderRadius="lg"
              py={4}
              px={5}
            >
              <Heading as="h3" size="md" fontWeight="semibold">
                Configuraci&oacute;n del show
              </Heading>
              <Flex direction="column" gap={4}>
                <Heading as="h3" fontSize="lg" color="ui.green">
                  General
                </Heading>
                <FormControl display="flex" alignItems="center" gap={4}>
                  <Switch id="allowBroadcast" name="allowBroadcast" />
                  <Box>
                    <FormLabel htmlFor="allowBroadcast" mb={1}>
                      Permitir repetici&oacute;n
                    </FormLabel>
                    <FormHelperText mt={0}>
                      Los usuarios pueden volver a ver el video una vez
                      finalizado el show
                    </FormHelperText>
                  </Box>
                </FormControl>
              </Flex>

              <Flex direction="column" gap={3}>
                <Heading as="h3" fontSize="lg" color="ui.green">
                  Chat
                </Heading>
                <FormControl display="flex" alignItems="center" gap={4}>
                  <Switch id="showChat" />
                  <FormLabel htmlFor="showChat" mb={1}>
                    Mostrar el chat en vivo
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" alignItems="center" gap={4}>
                  <Switch id="approveMessages" />
                  <FormLabel htmlFor="approveMessages" mb={1}>
                    Aprobar manualmente cada comentario
                  </FormLabel>
                </FormControl>
              </Flex>
              <Flex direction="column" gap={3}>
                <Heading as="h3" fontSize="lg" color="ui.green">
                  Audiencia
                </Heading>
                <FormControl display="flex" alignItems="center" gap={4}>
                  <Switch id="showViewerCount" />
                  <FormLabel htmlFor="showViewerCount" mb={1}>
                    Mostrar contador de espectadores conectados
                  </FormLabel>
                </FormControl>
                <FormControl display="flex" alignItems="center" gap={4}>
                  <Switch id="limitViewers" />
                  <FormLabel htmlFor="limitViewers" mb={1}>
                    Limitar la cantidad de espectadores
                  </FormLabel>
                </FormControl>
              </Flex>
            </Flex> */}
            </Flex>
            <Flex
              justify="flex-end"
              borderTop="1px"
              borderColor="ui.light-gray"
              gap={3}
              py={4}
            >
              <Button variant="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
              <Button
                variant="primary"
                leftIcon={<Icon as={TbCirclePlus} boxSize={6} />}
                type="submit"
              >
                {ctaLabel}
              </Button>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ShowForm;
