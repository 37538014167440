import { TbMessageCircle2 } from 'react-icons/tb';
import { Flex, Heading } from '@chakra-ui/layout';

import EmptyState from '../../components/ui/EmptyState/EmptyState';

const MessagesPage = () => {
  return (
    <Flex
      bg="white"
      borderRadius="lg"
      px={{ base: 3, md: 5 }}
      py="3"
      h="full"
      direction="column"
    >
      <Flex justifyContent="space-between" py="4">
        <Heading as="h2" color="brand.green" fontWeight="semibold">
          Mensajes
        </Heading>
      </Flex>
      <Flex justify="center" align="center" flexGrow={1}>
        <EmptyState
          message="Muy pronto podrás ver aquí los mensajes de tus clientes y responderles para mantenerte siempre en contacto."
          title="Mensajes"
          icon={TbMessageCircle2}
        />
      </Flex>
    </Flex>
  );
};

export default MessagesPage;
